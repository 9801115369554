import log from "loglevel";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import Loading from "../../../components/Loading";
import { useAuth } from "../../../ecwid/context/auth_context";
import { Input, InputGroup, Icon, Button, Alert } from "rsuite";
import SingleOrder from "./SingleOrder";
import { FiRefreshCcw } from "react-icons/fi";
import OrderModal from "./OrderModal";
import GoBackBtn from "../../../components/GoBackBtn";
import AdminNav from "../../../components/AdminNav";

const baseName = process.env.REACT_APP_BASENAME;

const SelectedStoreOrders = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    orderData: null,
    progressState: null,
    setProgressState: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [currStoreOrders, setCurrStoreOrders] = useState([]);
  const [searchByID, setSearchByID] = useState("");

  let { store, foodordrink } = useParams();
  let history = useHistory();

  log.debug("store:", store);
  log.debug("foodordrink:", foodordrink);

  //listening for new orders placed
  const { currentUser } = useAuth();

  const database = currentUser?.database;

  useEffect(() => {
    const ref = database.ref(`ecwid_callback/orders`);

    ref
      .orderByChild("extraFields/select_store")
      .equalTo(store)
      .limitToLast(1)
      .on("child_added", onChildAdded);

    return () => {
      ref.off();
    };
    // eslint-disable-next-line
  }, []);

  const onChildAdded = async (snapshot) => {
    const data = snapshot.val();

    log.debug(data);
    // log.debug(currStoreOrders.includes(data));
    if (currStoreOrders?.includes(data)) {
      Alert.error("Already in array");
      return;
    } else {
      await fetchStoreOrders();
      // window.navigator?.vibrate(2000);
      Alert.info(`New order placed ${data.id}`);
    }

    // window.location.reload();
  };

  const fetchStoreOrders = async () => {
    log.debug("inside onStoreChangeFunction");
    const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
    const resp = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/get_store_orders`,
      {
        token: authToken,
        store: store,
      }
    );

    log.debug("data from calling get store orders:", resp.data);
    const orders = Object.values(resp.data);
    // log.debug(orders.reverse());

    log.debug(orders);
    setCurrStoreOrders(orders.reverse());
  };

  useEffect(() => {
    if (store && currStoreOrders.length === 0) {
      log.debug(store);
      fetchStoreOrders();
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const dynamicSearch = () => {
    const orders = currStoreOrders.filter((order) => {
      return order.id.toLowerCase().includes(searchByID.toLowerCase());
    });
    return orders;
  };

  const NoOrders = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          fontFamily: "Roaster Original",
          textTransform: "lowercase",
          fontSize: "3rem",
        }}>
        <p>no Orders For selected Store</p>
      </div>
    );
  };

  const goBack = () => {
    history.push(`${baseName}/mystore/instoreorders/select`);
  };

  if (isLoading) {
    return <Loading text="fetching store orders" />;
  }

  // log.debug("currStoreOrders:", currStoreOrders[0]);
  // log.debug(currStoreOrders.includes(currStoreOrders[0]));

  return (
    <>
      <GoBackBtn goBack={goBack} />
      <Wrapper>
        {isModalOpen.open === true && (
          <OrderModal
            order={isModalOpen.orderData}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
          // <div
          //   className="order-modal"
          //   onClick={() => {
          //     setIsModalOpen((oldS) => ({ ...oldS, open: false }));
          //   }}>
          //   <p>{isModalOpen?.orderData?.id}</p>
          // </div>
        )}
        <div className="details-container">
          {/* <Button
          className="go-back-btn"
          onClick={goBack}
          style={{ textTransform: "lowercase" }}>
          &larr; Go Back
        </Button> */}
          <Button
            className="refresh-btn"
            onClick={() => window.location.reload()}>
            <FiRefreshCcw />
          </Button>
          <InputGroup className="search-item">
            <Input
              style={{ textTransform: "lowercase" }}
              placeholder="Search by ID"
              onChange={(event) => setSearchByID(event)}
            />
            <InputGroup.Addon>
              <Icon icon="search" />
            </InputGroup.Addon>
          </InputGroup>
        </div>

        <div className="details-container">
          <p>orders today:</p>
          <span style={{ fontSize: "1.5rem", marginLeft: "1rem" }}>
            {" "}
            {currStoreOrders.length}
          </span>
        </div>

        {currStoreOrders.length > 0 ? (
          searchByID.length > 0 ? (
            <div className="order-grid-container">
              {dynamicSearch().map((order) => {
                return (
                  <SingleOrder
                    key={order.id}
                    order={order}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                  />
                );
              })}
            </div>
          ) : currStoreOrders.length > 0 ? (
            <div className="order-grid-container">
              {currStoreOrders.map((order) => {
                return (
                  <SingleOrder
                    key={order.id}
                    order={order}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                  />
                );
              })}
            </div>
          ) : (
            <NoOrders />
          )
        ) : (
          <NoOrders />
        )}
      </Wrapper>
      <AdminNav style={{ top: "90vh" }} />
    </>
  );
};

const Wrapper = styled.div`
  .grid-item {
    padding-top: 0.7rem;
    display: flex;
    justify-content: center;

    @media (max-width: 900px) {
      flex-direction: column;
      margin: 1rem 0;
    }
  }

  .order-grid-container {
    margin: 0 2.5vw;
    display: grid;
    grid-template-columns: repeat(5, 18vw);
    grid-column-gap: 1vw;
    @media (max-width: 900px) {
      margin: 0 2.5vw;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1vw;
    }
    @media (max-width: 600px) {
      margin: 0 2.5vw;
      grid-template-columns: 1fr;
    }
  }

  .details-container {
    font-family: "Roaster Original";
    text-transform: lowercase;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }

  .search-item {
    width: 25vw;

    @media (max-width: 900px) {
    }
  }
  .go-back-btn {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
  }

  .refresh-btn {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(-50%, -50%);
  }
`;

export default SelectedStoreOrders;
