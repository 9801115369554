import React from "react";
import { Route, Redirect } from "react-router-dom";

const baseName = process.env.REACT_APP_BASENAME;

const PrivateRoute = ({ fromPage, component: Component, ...rest }) => {
  if (window.localStorage.getItem("upid") === "jkadfbsjkdb") {
    return (
      <Route {...rest} render={(props) => <Component {...props} />}></Route>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: `${baseName}/sotonuni/login`,
        }}
      />
    );
  }
};

export default PrivateRoute;
