import React from "react";
import styled from "styled-components";
import { colors } from "../constants/constants";
import DescrComp from "../components/DescrComp";
import HeaderComp from "../components/HeaderComp";
import AboutUsVideo from "../../Pages/AboutUsVideo";
import ConvectionCurrent from "../../resources/img/sotonUni/convection-conduction.png";

const About = () => {
  return (
    <Wrapper style={{ backgroundColor: colors.secondaryColor }}>
      <HeaderComp
        backgColor={colors.primaryColor}
        text="Naked Coffee was created by a bunch of crazy Gals & Guys who were
        passionate about wanting to make change in a heavily wasteful & Carbon
        Negative Industry."
        title="Intro"
      />

      <DescrComp
        backgColor={colors.primaryColor}
        title="The Aim"
        text=" To find a way to make the coffee roasting process carbon positive."
      />

      <DescrComp
        backgColor={colors.primaryColor}
        title="How We Did this"
        text="We teamed up with a nutty proffesor in Germany who had created a new style of roasting that didn’t have the need for the
        heavily toxic gasses other roasters use. We then struck a deal with the German company to exclusively bring the roasters to the UK."
      />

      <DescrComp
        backgColor={colors.primaryColor}
        title="The Result"
        text="From sourcing the best quality green beans to methodical roasting techniques, we're committed to providing you
        with the best possible coffee tasting experience when drinking our ‘carbon free’ roasted blends.
        This paired with our ethical practices & caring team have helped us stand up against some of the largest powerhouses in
        coffee and win, resulting in four outlets in university & on educational grounds with other multiple independant stores. Cool Hey!"
      />

      <div className="big-img mg-2">
        <img src={ConvectionCurrent} alt="ConvectionCurrent" />
      </div>
      <div className="center">
        <AboutUsVideo displayNav={false} />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  padding: 2rem;
  /* height: 100vh; */
  /* display: grid;
  grid-template-rows: 1fr 4fr; */

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mg-2 {
    margin: 2rem 0;
  }
`;

export default About;
