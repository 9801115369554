import log from "loglevel";
import React from "react";
import { useAuth } from "../../ecwid/context/auth_context";
import { Route, Redirect } from "react-router-dom";

const baseName = process.env.REACT_APP_BASENAME;

const PrivateRoute = ({
  fromPage,
  needAdmin,
  needStaff,
  component: Component,
  ...rest
}) => {
  const { currentUser } = useAuth();
  log.debug("PrivateRoute currentUser=", currentUser);

  log.debug("currentUser from private Route:", currentUser);

  if (
    typeof currentUser?.emailVerified !== "undefined" &&
    !currentUser?.emailVerified
  ) {
    return (
      <Redirect
        to={{
          pathname: `${baseName}/confirmemail`,
          state: fromPage,
        }}
      />
    );
  } else if (
    currentUser?.uid &&
    ((!needAdmin && !needStaff) ||
      (needAdmin && currentUser?.isAdmin) ||
      (needStaff && (currentUser?.isStaff || currentUser?.isAdmin)))
  ) {
    return (
      <Route {...rest} render={(props) => <Component {...props} />}></Route>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: `${baseName}/login`,
          state: fromPage,
        }}
      />
    );
  }
};

export default PrivateRoute;
