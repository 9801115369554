import log from 'loglevel';
import React from "react";
import Beans from "../../resources/details/beans-details";

const BeanGrid = ({ product }) => {
  // const { descr1, descr2, descr3 } = Virgin;

  //create array of objets and loop through it

  // const honduras = descr1.c1[1];
  // const ethiopian = descr1.c2[1];
  // const brazilian = descr1.c3[1];
  // const espresso = descr2.c1[1];
  // const americano = descr2.c2[1];
  // const MilkCoffee = descr2.c3[1];

  log.debug(product.name);

  return (
    <>
      {Beans.filter((bean) => bean.name === product.name).map((bean) => {
        log.debug(bean.descr1);
        return (
          <>
            <div className="single-beans__text-2">
              {/* row 1 */}
              <div className="single-beans__text-2--title-1">
                <h2>{bean.descr1.title}</h2>
              </div>

              {bean.descr1.c1 ? (
                <div
                  className={`single-beans__text-2--L1-${bean.descr1.numCol}`}>
                  <h4>{bean.descr1.c1[0]}</h4>
                  <div className="beanImgResponsive">
                    <img
                      className="centerBean"
                      src={bean.descr1.c1[1]}
                      alt="bean"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {bean.descr1.c2 ? (
                <div
                  className={`single-beans__text-2--M1-${bean.descr1.numCol}`}>
                  <h4>{bean.descr1.c2[0]}</h4>
                  <div className="beanImgResponsive">
                    <img src={bean.descr1.c2[1]} alt="bean" />
                  </div>
                </div>
              ) : (
                ""
              )}

              {bean.descr1.c3 ? (
                <div
                  className={`single-beans__text-2--R1-${bean.descr1.numCol}`}>
                  <h4>{bean.descr1.c3[0]}</h4>
                  <div className="beanImgResponsive">
                    <img src={bean.descr1.c3[1]} alt="bean" />
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* row2 */}
              <div className="single-beans__text-2--title-2">
                <h2>{bean.descr2.title} </h2>
              </div>
              {bean.descr2.c1 ? (
                <div
                  className={`single-beans__text-2--L2-${bean.descr2.numCol}`}>
                  <h4>{bean.descr2.c1[0]} </h4>
                  <div>
                    <img src={bean.descr2.c1[1]} alt="bean" />
                  </div>
                </div>
              ) : (
                ""
              )}

              {bean.descr2.c2 ? (
                <div
                  className={`single-beans__text-2--M2-${bean.descr2.numCol}`}>
                  <h4>{bean.descr2.c2[0]}</h4>
                  <div>
                    <img src={bean.descr2.c2[1]} alt="bean" />
                  </div>
                </div>
              ) : (
                ""
              )}

              {bean.descr2.c3 ? (
                <div
                  className={`single-beans__text-2--R2-${bean.descr2.numCol}`}>
                  <h4>{bean.descr2.c3[0]}</h4>
                  <div>
                    <img src={bean.descr2.c3[1]} alt="bean" />
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* row 3 */}
              <div className="single-beans__text-2--title-3">
                <h2>{bean.descr3.title}</h2>
              </div>

              <div className="single-beans__text-2--comment">
                <p>{bean.descr3.c1}</p>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default BeanGrid;
