import React from "react";

const CardOption = ({
  classParam,
  productState,
  dispatchProductState,
  coloursForBackground,
  setQuantity,
}) => {
  const handleChangeOptionSelect = (e) => {
    dispatchProductState({
      type: "select-product-option",
      payload: {
        optionName: e.target.name,
        optionValue: e.target.value,
        setQty: setQuantity,
      },
    });
  };

  return (
    <>
      {Object.keys(productState.options).map((optionName, index) => {
        //log.debug(productsState);
        const optionObject = productState.options[optionName];

        //log.debug("Found option name " + optionName);
        //log.debug(optionObject);

        //log.debug(
        //  "Number of choices: " + Object.keys(optionObject.choices).length
        //);
        if (Object.keys(optionObject.choices).length > 1) {
          return (
            <li
              style={{
                fontFamily: "Roaster Original",
                textTransform: "lowercase",
              }}
              key={optionName}
              className={classParam || coloursForBackground?.colour(index)}>
              <label>{optionName}:</label>

              <select
                style={{
                  fontFamily: "Roaster Original",
                  textTransform: "lowercase",
                }}
                name={optionName}
                value={optionObject.selectedChoiceName}
                onChange={handleChangeOptionSelect}>
                {Object.keys(optionObject.choices).map((choiceName) => {
                  return (
                    <option key={choiceName} value={choiceName}>
                      {choiceName.substring(0, 10)}
                    </option>
                  );
                })}
              </select>
            </li>
          );
        } else if (Object.keys(optionObject.choices).length === 1) {
          return (
            <li
              style={{
                fontFamily: "Roaster Original",
                textTransform: "lowercase",
              }}
              key={optionName}
              className={classParam || coloursForBackground?.colour(index)}>
              <span
                style={{
                  fontFamily: "Roaster Original",
                  textTransform: "lowercase",
                }}
                className="span-name">
                {optionName}:
              </span>
              <span
                style={{
                  fontFamily: "Roaster Original",
                  textTransform: "lowercase",
                }}>
                {Object.keys(optionObject.choices)[0]}
              </span>
            </li>
          );
        } else {
          return "";
        }
      })}
    </>
  );
};

export default CardOption;
