import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";

import TreatsImg from "../../resources/img/instore/treats.png";

import { treatsColor } from "./menu/constants";

const Treats = () => {
  return (
    <div className="instore-grid">
      <Header img={TreatsImg} color={treatsColor} />
      <div></div>
      <Footer color={treatsColor} />
    </div>
  );
};

export default Treats;
