import log from "loglevel";
import React, { useState, useRef } from "react";
import {
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  HelpBlock,
  FormControl,
  SelectPicker,
  Alert,
  Schema,
} from "rsuite";
import axios from "axios";
import { useAuth } from "../../ecwid/context/auth_context";
import firebase from "firebase/app";
import styled from "styled-components";
import Button from "../Button";
import Loading from "../Loading";
import { sendVerificationEmail } from "../../ecwid/helpers/helpers";

const { StringType } = Schema.Types;

const registerModel = Schema.Model({
  // oldPassword: StringType()
  //   .minLength(6, "The field cannot be less than 6 characters")
  //   .maxLength(25, "The field cannot be greater than 25 characters")
  //   .isRequired("This field is required"),
  newPassword: StringType()
    .minLength(6, "The field cannot be less than 6 characters")
    .maxLength(25, "The field cannot be greater than 25 characters")
    .isRequired("This field is required"),
  newConfirmPassword: StringType()
    .minLength(6, "The field cannot be less than 6 characters")
    .maxLength(25, "The field cannot be greater than 25 characters")
    .isRequired("This field is required"),
});

const InputModal = ({ showModal, setShowModal }) => {
  const formRef = useRef();
  const [country, setCountry] = useState("");
  const [formValue, setFormValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { currentUser, refreshContext } = useAuth();

  const handleChange = (value) => {
    setFormValue(value);
  };

  const close = () => {
    setShowModal((oldS) => ({ ...oldS, show: false }));
  };

  const countryList = [{ label: "United Kingdom", value: "GB" }];

  const onFormSubmit = async () => {
    // data Validation
    let formObject = formValue;

    //validation
    if (showModal.type === "address") {
      formObject["countryCode"] = country;
      const formValuesLength = Object.keys(formObject).length;
      const formKeys = Object.keys(formObject);
      for (let i = 0; i < formValuesLength; i++) {
        if (formKeys[i] === "companyName") continue;
        if (formObject[formKeys[i]]?.length === 0 || !formObject[formKeys[i]]) {
          Alert.error("Please enter valid data");
          return;
        }
      }
    } else if (showModal.type === "email") {
      // check if old email matches, and that both fields arent empty
      //check if they have entered anything as otherwise it will be an empty object
      if (
        Object.keys(formValue).length === 0 ||
        Object.keys(formValue).length === 1 ||
        formValue?.oldEmail?.length === 0 ||
        formValue?.newEmail?.length === 0
      ) {
        Alert.error("Fields can't be empty");
        return;
      }
      if (formValue?.oldEmail !== currentUser.email) {
        Alert.error("Your old email doesn't match");
        return;
      }
    } else if (showModal.type === "password") {
      log.debug(formValue);
      //check if any fields are empty
      //check if new passwords match
      //old password is checked server-side
      if (
        Object.keys(formValue).length === 0 ||
        Object.keys(formValue).length === 1 ||
        // Object.keys(formValue).length === 2 ||
        // formValue?.oldPassword?.length === 0 ||
        formValue?.newPassword?.length === 0 ||
        formValue?.newConfirmPassword?.length === 0
      ) {
        Alert.error("Fields can't be empty");
        return;
      }
      if (
        // formValue?.oldPassword?.length < 6 ||
        // formValue?.oldPassword?.length > 25 ||
        formValue?.newPassword?.length < 6 ||
        formValue?.newPassword?.length > 25 ||
        formValue?.newConfirmPassword?.length < 6 ||
        formValue?.newConfirmPassword?.length > 25
      ) {
        Alert.error("Fields must be between 6 and 25 characters long");
        return;
      }

      if (formValue?.newPassword !== formValue?.newConfirmPassword) {
        Alert.error("New passwords do not match");
        return;
      }
    }

    // if only 1 field then take the value
    let newUserDetails;
    if (showModal.type === "address") {
      newUserDetails = formValue;
    } else if (showModal.type === "email") {
      newUserDetails = formValue.newEmail;
    } else if (showModal.type === "name") {
      newUserDetails = formValue.name;
    } else if (showModal.type === "password") {
      newUserDetails = {
        // old_password: formValue.oldPassword,
        new_password: formValue.newPassword,
      };
    }
    log.debug("newUserDetails", newUserDetails);

    if (showModal.type === "password") {
      // make a call to the api to change the password
      //get firebaseIdToken:

      try {
        setIsLoading(true);

        const idToken = await currentUser.auth.currentUser.getIdToken(true);
        log.debug("idToken:", idToken);
        if (idToken) {
          try {
            const resp = await axios.post(
              `https://identitytoolkit.googleapis.com/v1/accounts:update?key=${currentUser.app.options_.apiKey}`,
              {
                idToken: idToken,
                password: newUserDetails.new_password,
                returnSecureToken: true,
              }
            );

            log.debug("password_reset resp:", resp);
            if (resp.status === 200) {
              Alert.success("Successfully changed your password");

              if (window.PasswordCredential) {
                let c = await new window.PasswordCredential(
                  newUserDetails.new_password
                );
                return navigator.credentials.store(c);
              }
            }
          } catch (error) {
            log.debug(error.message);
            log.debug(error?.response);
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        log.debug(error);
      }
    } else if (showModal.type === "address") {
      try {
        setIsLoading(true);
        log.debug("newUserDetails:", newUserDetails);

        let resp;
        if (showModal.changeOrAdd === "Change") {
          const authToken = JSON.parse(
            window.localStorage.getItem("auth_token")
          );
          resp = await axios.post(
            `${process.env.REACT_APP_API_ADDRESS}/update_user_shipping_address`,
            //prettier-ignore
            { "token": authToken ,"shippingAddress": newUserDetails, index: 0}
          );
        } else {
          const authToken = JSON.parse(
            window.localStorage.getItem("auth_token")
          );
          resp = await axios.post(
            `${process.env.REACT_APP_API_ADDRESS}/add_user_shipping_address`,
            //prettier-ignore
            { "token": authToken ,"shippingAddress": newUserDetails}
          );
        }

        log.debug("resp", resp);

        await refreshContext("shippingAddresses", resp.data.shippingAddresses);

        //check if there is a new token

        if (resp.status === 200) {
          Alert.success(`Succesfully updated your ${showModal.type}`);
        }

        setIsLoading(false);
        setFormValue({});
      } catch (error) {
        setFormValue({});
        log.debug(error.response);
        Alert.error("Something went wrong");
        setIsLoading(false);
      }
    } else {
      // call the backend to update the data
      try {
        setIsLoading(true);
        const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
        log.debug("typeof authToken", typeof authToken);
        log.debug("typeof item_to_update", typeof showModal.type);
        log.debug("typeof newUserDetails", typeof newUserDetails);
        const resp = await axios.post(
          `${process.env.REACT_APP_API_ADDRESS}/update_user_details`,
          //prettier-ignore
          { "token": authToken ,"item_to_update": showModal.type, "updated_value": newUserDetails }
        );
        log.debug("resp", resp);

        //check if there is a new token
        if (Array.isArray(resp.data)) {
          log.debug("resp token", resp.data[1]);
          window.localStorage.removeItem("auth_token");
          window.localStorage.setItem(
            "auth_token",
            JSON.stringify(resp.data[1])
          );
        }
        if (showModal.type === "password") {
          if (resp.status === 200) {
            Alert.success(resp.data);
          }
        }

        if (showModal.type === "name") {
          refreshContext("name", newUserDetails);
        }

        if (showModal.type === "email") {
          refreshContext("email", newUserDetails);
          refreshContext("emailVerified", false);
        }

        if (resp.status === 200) {
          Alert.success(`Successfully updated your ${showModal.type}`);
        }

        setIsLoading(false);
        setFormValue({});
      } catch (error) {
        setFormValue({});
        log.debug(error.response);
        Alert.error("Something went wrong");
        setIsLoading(false);
      }
    }
    close();
  };

  const sendPasswordResetEmail = async () => {
    if (!formValue?.email || formValue?.email?.length === 0) {
      Alert.error("Fields cannot be empty");
      return;
    }
    // here the user wont be logged in so firebase wont be initialised
    // so we initialize it with the minimum configuration required for the API to be used.
    let config = {
      apiKey: "AIzaSyAX1wj3tIa3EjNAxv7mSOnp46hFlGe1GXA", // Copy this key from the web initialization
      // snippet found in the Firebase console.
    };

    let app;
    if (!firebase.apps.length) {
      app = firebase.initializeApp(config);
    } else {
      app = firebase.app(); // if already initialized, use that one
    }
    let auth = app.auth();

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: window.location.href,
      // This must be true.
      handleCodeInApp: true,
    };

    auth
      .sendPasswordResetEmail(formValue.email, actionCodeSettings)
      .then(() => {
        Alert.success("Succesfully sent email link to reset your password");
      })
      .catch((error) => {
        log.debug(error);
        log.debug(error.message);
        Alert.error("Something went wrong");
        // ..
      });
  };

  return (
    <div>
      {isLoading && (
        <div className="centre-blur">
          <Loading />
        </div>
      )}
      <Modal show={showModal.show} onHide={close} size="xs">
        <Modal.Header>
          <Modal.Title
            style={{
              textTransform: "lowercase",
              fontFamily: "Roaster Original",
            }}>
            {showModal.type === "reset_password"
              ? "Reset Password"
              : showModal.type === "Email Verified"
              ? "Verify Email"
              : showModal.changeOrAdd + " " + showModal.type}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showModal.type === "address" ? (
            <Form fluid onChange={handleChange} formValue={formValue}>
              <FormGroup>
                <ControlLabel
                  style={{
                    textTransform: "lowercase",
                    fontFamily: "Roaster Original",
                  }}>
                  Country/Region
                </ControlLabel>
                <SelectPicker
                  block
                  value={country}
                  data={countryList}
                  onChange={(value) => setCountry(value)}
                  placeholder="Select Country"
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel
                  style={{
                    textTransform: "lowercase",
                    fontFamily: "Roaster Original",
                  }}>
                  Name
                </ControlLabel>
                <FormControl name="name" type="name" />
              </FormGroup>
              <FormGroup>
                <ControlLabel
                  style={{
                    textTransform: "lowercase",
                    fontFamily: "Roaster Original",
                  }}>
                  Address
                </ControlLabel>
                <FormControl name="street" type="city" />
              </FormGroup>
              <FormGroup>
                <ControlLabel
                  style={{
                    textTransform: "lowercase",
                    fontFamily: "Roaster Original",
                  }}>
                  Post Code
                </ControlLabel>
                <FormControl name="postalCode" type="postal-code" />
              </FormGroup>
              <FormGroup>
                <ControlLabel
                  style={{
                    textTransform: "lowercase",
                    fontFamily: "Roaster Original",
                  }}>
                  City
                </ControlLabel>
                <FormControl name="city" type="city" />
              </FormGroup>
              <FormGroup>
                <ControlLabel
                  style={{
                    textTransform: "lowercase",
                    fontFamily: "Roaster Original",
                  }}>
                  Company
                </ControlLabel>
                <FormControl name="companyName" type="companyName" />
                <HelpBlock>Optional</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel
                  style={{
                    textTransform: "lowercase",
                    fontFamily: "Roaster Original",
                  }}>
                  phone
                </ControlLabel>
                <FormControl name="phone" type="phone" />
              </FormGroup>
            </Form>
          ) : showModal.type === "email" ? (
            <Form fluid onChange={handleChange} formValue={formValue}>
              <FormGroup>
                <ControlLabel
                  style={{
                    textTransform: "lowercase",
                    fontFamily: "Roaster Original",
                  }}>
                  Old Email
                </ControlLabel>
                <FormControl name="oldEmail" />
                <HelpBlock>Required</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel
                  style={{
                    textTransform: "lowercase",
                    fontFamily: "Roaster Original",
                  }}>
                  New Email
                </ControlLabel>
                <FormControl name="newEmail" type="email" />
                <HelpBlock>Required</HelpBlock>
              </FormGroup>
            </Form>
          ) : showModal.type === "password" ? (
            <Form
              fluid
              onChange={handleChange}
              formValue={formValue}
              model={registerModel}
              ref={formRef}>
              <FormGroup>
                <ControlLabel
                  style={{
                    textTransform: "lowercase",
                    fontFamily: "Roaster Original",
                  }}
                  l>
                  New Password
                </ControlLabel>
                <FormControl name="newPassword" type="password" />
                <HelpBlock>Required</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel
                  style={{
                    textTransform: "lowercase",
                    fontFamily: "Roaster Original",
                  }}>
                  Confirm Password
                </ControlLabel>
                <FormControl name="newConfirmPassword" type="password" />
                <HelpBlock>Required</HelpBlock>
              </FormGroup>
            </Form>
          ) : showModal.type === "name" ? (
            <Form fluid onChange={handleChange} formValue={formValue}>
              <FormGroup>
                <ControlLabel
                  style={{
                    textTransform: "lowercase",
                    fontFamily: "Roaster Original",
                  }}>
                  New Name 😜
                </ControlLabel>
                <FormControl name="name" />
                <HelpBlock>Required</HelpBlock>
              </FormGroup>
            </Form>
          ) : showModal.type === "Email Verified" ? (
            <FBemailLlink>
              {/* <h3>Follow instructions below</h3> */}
              <p>
                Click button below to send a verification link to your
                registered email address
              </p>
              <Button
                size="small"
                style={{
                  textTransform: "lowercase",
                  width: "9rem",
                  margin: "1rem 0",
                }}
                onClick={() => {
                  sendVerificationEmail(currentUser);
                  close();
                }}
                loading={isLoading}>
                Send Verification Link
              </Button>
            </FBemailLlink>
          ) : showModal.type === "reset_password" ? (
            <FBemailLlink>
              <Form
                fluid
                onChange={handleChange}
                formValue={formValue}
                className="form-pass-reset">
                <FormGroup>
                  <ControlLabel
                    style={{
                      textTransform: "lowercase",
                      fontFamily: "Roaster Original",
                    }}>
                    Email address
                  </ControlLabel>
                  <FormControl name="email" />
                </FormGroup>
              </Form>
              <Button
                size="small"
                style={{ textTransform: "lowercase", width: "9rem" }}
                onClick={sendPasswordResetEmail}
                appearance="secondary"
                loading={isLoading}>
                Send Reset Password Link
              </Button>
            </FBemailLlink>
          ) : (
            <p>No name selected from myvip page</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {showModal.type !== "Email Verified" &&
            showModal.type !== "reset_password" && (
              <>
                <Button
                  style={{
                    fontFamily: "Roaster original",
                    width: "6rem",
                    color: "red",
                    borderColor: "red",
                    borderWidth: 1.5,
                    backgroundColor: "transparent",
                  }}
                  appearance="secondary"
                  size="small"
                  shouldHover={false}
                  onClick={close}
                  loading={isLoading}>
                  Cancel
                </Button>
                <Button
                  style={{
                    fontFamily: "Roaster original",
                    width: "6rem",
                    marginLeft: "1rem",
                  }}
                  size="small"
                  onClick={onFormSubmit}
                  appearance="secondary"
                  loading={isLoading}>
                  Confirm
                </Button>
              </>
            )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const FBemailLlink = styled.div`
  font-family: "Roaster Original";
  text-transform: lowercase;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .form-pass-reset {
    margin-bottom: 2rem;
  }

  button {
    align-self: center;
    width: fit-content;
  }
`;

export default InputModal;
