import log from 'loglevel';
import { LOGIN_USER, LOGOUT_USER, REFRESH_CONTEXT } from "../actions";

const authReducer = (state, action) => {
  log.debug(
    "authReducer state=",
    state,
    "action=",
    action,
    "action.payload=",
    action.payload
  );

  switch (action.type) {
    case LOGIN_USER:
      // uid, name, email, isAdmin, database
      const payload = action?.payload;

      const newState = {
        uid: payload?.uid || "",
        name: payload?.name || "",
        email: payload?.email || "",
        ec_token: payload?.ecToken,
        shippingAddresses: payload?.shippingAddresses || "",
        loyalty_points: payload?.loyalty_points,
        isAdmin: payload?.isAdmin,
        isStaff: payload?.isStaff,
        database: payload?.database,
        emailVerified: payload?.emailVerified || false,
        app: payload?.app,
        auth: payload?.auth,
        analytics: payload?.analytics,
        ecwidCustomerId: payload?.ecwidCustomerId,
      };

      log.debug("authReducer LOGIN_USER returning ", newState);
      return newState;

    // case UPDATE_USER_DETAILS:
    //   const updatedState = state;
    //   updatedState[action.payload.itemToUpdate] = action.payload.UpdatedValue;
    //   log.debug("updatedState:", updatedState);
    //   log.debug("authReducer UPDATE_USER_DETAILS returning ", updatedState);

    //   return;

    case REFRESH_CONTEXT:
      const updatedState = state;
      updatedState[action.payload.itemToUpdate] = action.payload.updatedValue;
      return updatedState;

    case LOGOUT_USER:
      return {};

    default:
      throw new Error(`No matching action type ${action.type}`);
  }
};

export default authReducer;
