import axios from "axios";
import log from "loglevel";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Alert } from "rsuite";
import styled from "styled-components";
import GoBackBtn from "../../../components/GoBackBtn";
import Loading from "../../../components/Loading";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AdminNav from "../../../components/AdminNav";
import { IoAddCircleOutline } from "react-icons/io5";
import OnlineCouponModal from "./OnlineCouponModal";
import InstoreCouponModal from "./InstoreCouponModal";

const baseName = process.env.REACT_APP_BASENAME;

const AdminDiscounts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState({ open: false, type: "" });
  const [modalCoupon, setModalCoupon] = useState({});
  let history = useHistory();
  let { instoreoronline } = useParams();

  const getOnlineCoupons = async () => {
    try {
      setIsLoading(true);
      const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
      log.debug("authToken", typeof authToken);
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/get_online_coupons`,
        //prettier-ignore
        {token: authToken}
      );
      log.debug(resp);

      setCoupons(resp?.data?.items);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Alert.error("Something went wrong when trying to fetch discounts");
      log.debug(error);
    }
  };

  useEffect(() => {
    if (instoreoronline === "online") {
      getOnlineCoupons();
    } else {
      // getInstoreCoupons()
    }
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return <Loading text="Fetching coupons" />;
  }

  // log.debug(coupons);

  const updateCoupon = async () => {
    log.debug(modalCoupon);
    try {
      setIsLoading(true);
      const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/update_online_coupon`,
        //prettier-ignore
        { "token": authToken , "updated_coupon": modalCoupon}
      );

      log.debug("resp", resp);

      Alert.success(`Succesfully updated coupon: ${modalCoupon.name}`);

      setIsLoading(false);
    } catch (error) {
      log.debug(error.response);
      Alert.error("Something went wrong");
      setIsLoading(false);
    }
  };

  const createNewCoupon = async () => {
    if (
      !modalCoupon?.name ||
      !modalCoupon?.code ||
      !modalCoupon?.discountType ||
      !modalCoupon?.status ||
      !modalCoupon.discount ||
      !modalCoupon.totalLimit ||
      !modalCoupon.usesLimit ||
      !modalCoupon.applicationLimit
    ) {
      alert("Input fields cannot be empty");
      return;
    }

    if (!modalCoupon.expirationDate) {
      modalCoupon.expirationDate = null;
    }
    modalCoupon.creationDate = String(new Date(Date.UTC(0, 0, 0, 0, 0, 0)));
    log.debug("new coupon", modalCoupon);

    //call api
    try {
      setIsLoading(true);
      const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/create_online_coupon`,
        //prettier-ignore
        { "token": authToken , "new_coupon": modalCoupon}
      );

      log.debug("resp", resp);

      Alert.success(`Succesfully created coupon: ${modalCoupon.name}`);

      setIsLoading(false);
    } catch (error) {
      log.debug(error.response);
      Alert.error("Something went wrong");
      setIsLoading(false);
    }
  };

  const deleteCoupon = async (code, name) => {
    if (
      window.confirm(
        "Are you sure you want to delete this coupon, This action cannot be undone"
      )
    ) {
      try {
        setIsLoading(true);
        const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
        const resp = await axios.post(
          `${process.env.REACT_APP_API_ADDRESS}/delete_online_coupon`,
          //prettier-ignore
          { "token": authToken , "code": code}
        );

        log.debug("resp", resp);

        Alert.success(`Succesfully deleted coupon: ${name}`);

        setIsLoading(false);
      } catch (error) {
        log.debug(error.response);
        Alert.error("Something went wrong");
        setIsLoading(false);
      }
    }
  };

  const goBack = () => {
    history.push(`${baseName}/mystore/managediscounts/select`);
  };

  log.debug(instoreoronline);

  if (instoreoronline === "online") {
    return (
      <div style={{ backgroundColor: "rgb(236,238,240)" }}>
        <GoBackBtn goBack={goBack} />
        <Wrapper>
          <h2>Online Discount coupons</h2>
          <button
            className="new-discount-btn"
            onClick={() => {
              setModalCoupon({});
              setIsModalOpen({ open: true, type: "new" });
            }}>
            <IoAddCircleOutline fontSize={20} />
            Add new discount
          </button>
          {coupons?.length > 0 ? (
            <div className="coupon-container">
              {coupons.map((coupon) => {
                return (
                  <div className="single-coup-container">
                    <div className="centre-item">
                      <p>{coupon.name}</p>
                    </div>
                    <div className="centre-item">
                      <p>{coupon.code}</p>
                    </div>
                    <div className="centre-item">
                      <p>{coupon.status}</p>
                    </div>
                    <div className="centre-item">
                      <button
                        onClick={() => {
                          setModalCoupon(coupon);
                          setIsModalOpen({ open: true, type: "edit" });
                        }}>
                        <FiEdit fontSize="1.1rem" />
                      </button>
                    </div>
                    <div className="centre-item">
                      <button
                        onClick={() => deleteCoupon(coupon.code, coupon.name)}>
                        <MdDelete fontSize="1.1rem" color="red" />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <h1>No Coupons</h1>
          )}
          {isModalOpen.open && (
            <OnlineCouponModal
              setModalCoupon={setModalCoupon}
              setIsModalOpen={setIsModalOpen}
              modalCoupon={modalCoupon}
              updateCoupon={updateCoupon}
              isModalOpen={isModalOpen}
              createNewCoupon={createNewCoupon}
            />
          )}
        </Wrapper>
        <AdminNav style={{ top: "90vh" }} />
      </div>
    );
  }
  return (
    <div style={{ backgroundColor: "rgb(236,238,240)" }}>
      <GoBackBtn goBack={goBack} />
      <Wrapper>
        <h2>Instore Discount coupons</h2>
        <button
          className="new-discount-btn"
          onClick={() => {
            setModalCoupon({});
            setIsModalOpen({ open: true, type: "new" });
          }}>
          <IoAddCircleOutline fontSize={20} />
          Add new discount
        </button>
        {coupons?.length > 0 ? (
          <div className="coupon-container">
            {coupons.map((coupon) => {
              return (
                <div className="single-coup-container">
                  <div className="centre-item">
                    <p>{coupon.name}</p>
                  </div>
                  <div className="centre-item">
                    <p>{coupon.code}</p>
                  </div>
                  <div className="centre-item">
                    <p>{coupon.status}</p>
                  </div>
                  <div className="centre-item">
                    <button
                      onClick={() => {
                        setModalCoupon(coupon);
                        setIsModalOpen({ open: true, type: "edit" });
                      }}>
                      <FiEdit fontSize="1.1rem" />
                    </button>
                  </div>
                  <div className="centre-item">
                    <button
                      onClick={() => deleteCoupon(coupon.code, coupon.name)}>
                      <MdDelete fontSize="1.1rem" color="red" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <h1>No Coupons</h1>
        )}
        {isModalOpen.open && (
          <InstoreCouponModal
            setModalCoupon={setModalCoupon}
            setIsModalOpen={setIsModalOpen}
            modalCoupon={modalCoupon}
            updateCoupon={updateCoupon}
            isModalOpen={isModalOpen}
            createNewCoupon={createNewCoupon}
          />
        )}
      </Wrapper>
      <AdminNav style={{ top: "90vh" }} />
    </div>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  height: auto;
  background-color: rgb(236, 238, 240);
  /* position: relative; */

  h2 {
    font-family: "Roaster Original";
    text-transform: lowercase;
  }

  .coupon-container {
    padding: 10vh 0 10vh 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .single-coup-container {
    background-color: white;
    margin-bottom: 1rem;
    width: 75vw;
    padding: 1rem;
    border-radius: 1rem;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr 1fr;
  }

  .centre-item {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: transparent;
    }
  }

  //Modal
  .coupon-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 70vh;
    width: 70vw;
    background-color: white;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    padding: 1rem;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    overflow-y: scroll;
  }

  .modal-top {
    display: grid;
    grid-template-columns: 4fr 1fr;
    button {
      background-color: transparent;
    }
    div {
      h4 {
        font-family: "Roaster original";
        text-transform: lowercase;
      }
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
  }

  .modal-content-editor {
    margin: 0.5rem 0 0.5rem 0;

    p {
      font-family: "Roaster Original";
      text-transform: lowercase;
      margin-bottom: 0.3rem;
    }
  }

  .btn-modal-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 25%;
    padding: 1rem 0 1rem 0;
  }

  .disc-amount-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 50%;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .new-discount-btn {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5vh;
    right: 5vw;
    font-family: "Roaster Original";
    text-transform: lowercase;
  }
`;

export default AdminDiscounts;
