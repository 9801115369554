import React, { useEffect } from "react";
import log from "loglevel";
import { useHistory } from "react-router";
import { Notification } from "rsuite";
import styled from "styled-components";
import Button from "../../components/Button";
import Navigation from "../../components/Navigation";
import { useAuth } from "../../ecwid/context/auth_context";
import VIPLogo from "../../resources/img/vip-logo.png";
import { sendVerificationEmail } from "../../ecwid/helpers/helpers";
import Footer from "../../components/Footer";

const baseName = process.env.REACT_APP_BASENAME;

const MyAccountHomeScreen = () => {
  const { currentUser } = useAuth();
  let isUserLoggedIn = currentUser?.uid ? true : false;

  let history = useHistory();

  log.debug(currentUser);
  log.debug(isUserLoggedIn);

  const goToVip = () => {
    // history.push(`${baseName}/myvip`);

    if (currentUser.emailVerified) {
      history.push(`${baseName}/myvip`);
    } else {
      function open(funcName) {
        Notification[funcName]({
          title: (
            <h3 className="notification-header">Your Email is not Verified</h3>
          ),
          duration: 10000,
          description: (
            <>
              <div
                style={{
                  margin: "1rem 0",
                  fontFamily: "Roaster Original",
                  textTransform: "lowercase",
                }}>
                You need to verify your email before being able to access the
                VIP section. An email shouldve been sent to you when you
                registered
              </div>
              <div className="notification-btn-container">
                <Button
                  size="medium"
                  className="notification-btn"
                  appearance="secondary"
                  onClick={() => {
                    Notification.close();
                    sendVerificationEmail(currentUser);
                  }}>
                  Send Email Again
                </Button>
                <Button
                  size="medium"
                  appearance="secondary"
                  className="notification-btn"
                  onClick={() => Notification.close()}>
                  Close
                </Button>
              </div>
            </>
          ),
        });
      }

      open("info");
    }
  };

  useEffect(() => {
    if (history?.location?.state?.showEmailVerLogin) {
      function open(funcName) {
        Notification[funcName]({
          title: (
            <h3 className="notification-header">Your email is not verified</h3>
          ),
          duration: 10000,
          description: (
            <>
              <div
                style={{
                  margin: "1rem 0",
                  fontFamily: "Roaster Original",
                  textTransform: "lowercase",
                }}>
                Succesfully logged you in, Please verify your email to have
                access to our V.I.P deals
              </div>
              <div className="notification-btn-container">
                <Button
                  size="medium"
                  className="notification-btn"
                  appearance="secondary"
                  onClick={() => {
                    Notification.close();
                    sendVerificationEmail(currentUser);
                  }}>
                  send email again
                </Button>
                <Button
                  size="medium"
                  appearance="secondary"
                  className="notification-btn"
                  onClick={() => Notification.close()}>
                  close
                </Button>
              </div>
            </>
          ),
        });
      }
      open("info");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Navigation />
      <Wrapper>
        <div className="top-container">
          <div className="vip-logo-container">
            <img className="vip-logo" src={VIPLogo} alt="vip-img" />
          </div>
          <div className="vip-btn-container">
            {isUserLoggedIn ? (
              <Button onClick={goToVip} size="large" appearance="secondary">
                My VIP
              </Button>
            ) : (
              <Button
                onClick={() => history.push(`${baseName}/register`)}
                size="medium"
                appearance="secondary">
                My VIP
              </Button>
            )}

            <Button
              size={isUserLoggedIn ? "large" : "medium"}
              appearance="secondary"
              style={{ marginTop: "1.5rem" }}>
              Go Back
            </Button>
          </div>
        </div>
        <div className="vip-header-container">
          <h1>Live Free, Live VIP</h1>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};

const Wrapper = styled.div`
  font-family: "Roaster original";
  text-transform: lowercase;
  height: 100vh;
  display: grid;
  grid-template-rows: 60vh 40vh;

  @media (max-width: 900px) {
    grid-template-rows: 70vh 30vh;
  }

  /* div {
    border: 1px solid red;
  } */

  .top-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    @media (max-width: 900px) {
      grid-template-columns: none;
      grid-template-rows: 2fr 1fr;
    }
    /* div {
      border: 1px solid blue;
    } */
  }

  .vip-header-container {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 5rem;
      color: rgb(14, 114, 185) !important;
      @media (max-width: 900px) {
        font-size: 3rem;
      }
      @media (max-width: 600px) {
        font-size: 2rem;
      }
      @media (max-width: 350px) {
        font-size: 1.6rem;
      }
    }
  }

  .vip-logo-container {
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 70%;
      transform: translate(-50%, -50%);
      max-height: 70vh;

      @media (max-width: 900px) {
        top: 45%;
        left: 45%;
        max-height: 40vh;
      }
    }
  }

  .vip-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    button {
      width: 12rem;
      height: 2.3rem;
    }
  }
`;

export default MyAccountHomeScreen;
