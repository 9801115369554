import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { FaBoxes } from "react-icons/fa";
import { GiSellCard } from "react-icons/gi";

import Button from "../../../components/Button";
import GoBackBtn from "../../../components/GoBackBtn";

const baseName = process.env.REACT_APP_BASENAME;

const SelectSales = () => {
  let history = useHistory();

  const goBack = () => {
    history.push(`${baseName}/mystore`);
  };
  return (
    <>
      <GoBackBtn goBack={goBack} />
      <Header>
        <h1>Your store hub</h1>
      </Header>
      <Wrapper>
        <div className="admin-card">
          <div className="btn-admin-container">
            <FaBoxes className="icon-admin" />
            <Button
              appearance="secondary"
              style={{ width: "11rem" }}
              onClick={() => history.push(`${baseName}/mystore/sales/instore`)}>
              Instore Sales
            </Button>
          </div>
        </div>
        <div className="admin-card">
          <div className="btn-admin-container">
            <GiSellCard className="icon-admin" />
            <Button
              appearance="secondary"
              style={{ width: "11rem" }}
              onClick={() => history.push(`${baseName}/mystore/sales/online`)}>
              Online Sales
            </Button>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  font-family: "Roaster Original";
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2rem;
  grid-column-gap: 2rem;

  .admin-card {
    height: 80%;
    background-color: rgba(14, 114, 185, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -3px 4px 5px 2px rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
  }

  .icon-admin {
    font-size: 3.5rem;
    color: white;
  }

  .btn-admin-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 50%;
    width: 50%;
  }

  button {
    border: none !important;
    color: white !important;
  }
`;

const Header = styled.div`
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: rgb(14, 114, 185) !important;
    font-family: "Roaster Original";
    text-shadow: 2px 2px rgba(75, 75, 75, 0.3);
    text-transform: lowercase;
  }
`;
export default SelectSales;
