import log from "loglevel";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Button from "../../components/Button";
import { useAuth } from "../../ecwid/context/auth_context";
import { sendVerificationEmail } from "../../ecwid/helpers/helpers";

const baseName = process.env.REACT_APP_BASENAME;

const CheckRegisterEmail = () => {
  const { currentUser } = useAuth();
  log.debug(currentUser);

  let history = useHistory();

  useEffect(() => {
    if (currentUser?.emailVerified) {
      history.push(`${baseName}/myvip`);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <div>
        <h1>please verify your email before continuing </h1>
      </div>
      <div>
        <div>
          <p>
            An email should have been sent to you, please click on the link to
            continue. You can close this tab when you are done
          </p>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <i>Please check your junk folder</i>
          <i>You can only request 1 email per minute!</i>
        </div>
      </div>
      <div>
        <Button
          size="small"
          appearance="secondary"
          style={{
            textTransform: "lowercase",
            width: "9rem",
            margin: "1rem 0",
          }}
          onClick={() => {
            sendVerificationEmail(currentUser);
          }}>
          Send New Verification Link
        </Button>
      </div>
      {/* 

       */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  padding: 15vh 15vw 15vh 15vw;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  h1 {
    font-family: "Roaster Original";
    text-transform: lowercase;
    font-size: 2.5rem;
    @media (max-width: 900px) {
      font-size: 2rem;
    }
    @media (max-width: 600px) {
      line-height: 2rem;
      font-size: 1rem;
    }
  }

  p {
    font-family: "Roaster Original";
    text-transform: lowercase;
    margin-top: 1rem;
    font-size: 1rem;
    @media (max-width: 900px) {
      font-size: 0.7rem;
    }
    @media (max-width: 600px) {
      font-size: 0.5rem;
    }
  }

  i {
    font-family: "Roaster Original";
    text-transform: lowercase;

    @media (max-width: 900px) {
      font-size: 0.6rem;
    }
    @media (max-width: 600px) {
      font-size: 0.4rem;
    }
  }
`;

export default CheckRegisterEmail;
