import log from 'loglevel';
import React from "react";
import { Link } from "react-router-dom";
// import { useAppContext } from "../../context";
import { useProductState } from "../../ecwid/reducers/useProductState";

const Beans = ({ product, id: productId, index, hdThumbnailUrl: img }) => {
  // const { products, loading } = useAppContext();

  log.debug(product);

  const [productState] = useProductState(product);

  const baseName = process.env.REACT_APP_BASENAME;
  return (
    <>
      <div className={`beans--single animation-${index}`}>
        <Link
          style={{ textDecoration: "none" }}
          to={{
            pathname: `${baseName}/beans/${productId}`,
            productState: productState,
          }}>
          <div className="beans--single--img-container">
            <img src={img} alt="bean" />
          </div>
          <h2 className="beans--single-title">{product.name}</h2>
        </Link>
      </div>
    </>
  );
};
export default Beans;
