import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import LooseLeafMenu from "../../resources/img/sotonUni/loose-leaf.png";
import YellowLooseLeaf from "../../resources/img/sotonUni/yellow-loose-leaf.png";
import GreenLooseLeaf from "../../resources/img/sotonUni/green-loose-leaf.png";
import PurpleLooseLeaf from "../../resources/img/sotonUni/purple-loose-leaf.png";
import OrangeLooseLeaf from "../../resources/img/sotonUni/orange-loose-leaf.png";
import ThreeCoffees from "../../resources/img/sotonUni/three-coffees.png";
import { colors } from "../constants/constants";
import DescrComp from "../components/DescrComp";

//140px 200px

const LooseLeaf = () => {
  return (
    <>
      <Wrapper style={{ backgroundColor: colors.tertiaryColor }}>
        {/* Main Image  */}
        <img
          src={ThreeCoffees}
          alt="three coffees"
          className="three-cofee-img"
        />

        {/* smaller images  */}
        <img
          src={YellowLooseLeaf}
          alt="three coffees"
          className="loose-coffee-1"
        />
        <img
          src={GreenLooseLeaf}
          alt="three coffees"
          className="loose-coffee-2"
        />
        <img
          src={PurpleLooseLeaf}
          alt="three coffees"
          className="loose-coffee-3"
        />
        <img
          src={OrangeLooseLeaf}
          alt="three coffees"
          className="loose-coffee-4"
        />
      </Wrapper>
      <Menu style={{ backgroundColor: colors.tertiaryColor, color: "white" }}>
        <DescrComp
          includeTitle={false}
          text="Excitement is looming over our new loose leaf tea range which will be released in September to all naked stores"
        />
        <div className="big-img">
          <ScrollAnimation animateIn="fadeInDown" offset={300}>
            <img src={LooseLeafMenu} alt="menu" />
          </ScrollAnimation>
        </div>
      </Menu>
    </>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;

  position: relative;

  @media screen and (max-width: 600px) {
    height: 80vh;
  }

  .three-cofee-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;

    @media screen and (max-width: 600px) {
      width: 80vw;
    }
  }

  .loose-coffee-1 {
    position: absolute;
    top: 25%;
    left: 20%;
    width: 10%;
    transform: translate(-50%, -50%) rotate(-10deg);
    @media screen and (max-width: 600px) {
      width: 20%;
    }
  }
  .loose-coffee-2 {
    position: absolute;
    top: 25%;
    left: 80%;
    width: 10%;
    transform: translate(-50%, -50%) rotate(10deg);
    @media screen and (max-width: 600px) {
      width: 20%;
    }
  }
  .loose-coffee-3 {
    position: absolute;
    top: 75%;
    left: 20%;
    width: 10%;
    transform: translate(-50%, -50%) rotate(10deg);
    @media screen and (max-width: 600px) {
      width: 20%;
    }
  }
  .loose-coffee-4 {
    position: absolute;
    top: 75%;
    left: 80%;
    width: 10%;
    transform: translate(-50%, -50%) rotate(-10deg);
    @media screen and (max-width: 600px) {
      width: 20%;
    }
  }
`;

const Menu = styled.div`
  padding: 2rem;
`;
export default LooseLeaf;
