import React from "react";
import LoyaltyScreen from "../resources/img/App/loyalty-screen.png";
import ActivityScreen from "../resources/img/App/activity-screen.png";
import HomeScreen from "../resources/img/App/home-screen.png";
import ReceiptScreen from "../resources/img/App/receipt-screen.png";
import StoreFinderScreen from "../resources/img/App/store-finder-screen.png";

const AppImages = () => {
  return (
    <div>
      <img src={LoyaltyScreen} alt="loyalty-screen" />
      <img src={ActivityScreen} alt="activity-screen" />
      <img src={HomeScreen} alt="home-screen" />
      <img src={ReceiptScreen} alt="receipt-screen" />
      <img src={StoreFinderScreen} alt="store-finder-screen" />
    </div>
  );
};

export default AppImages;
