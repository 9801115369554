import log from "loglevel";
import { useReducer } from "react";
import {
  initialProductOptions,
  calculateTaxRate,
  calculatePrice,
  determineStock,
  findMatchingVariation,
} from "../helpers/helpers";

export const useProductState = (product, paramInitialState) => {
  const initialProductState = (() => {
    const initialOptionsObject = initialProductOptions(
      product,
      product.id,
      product.options
    );

    const variation = findMatchingVariation(
      initialOptionsObject,
      product.combinations
    );

    const initialTaxRate = calculateTaxRate(product.tax);

    const initialFinalPrice = calculatePrice(
      product.price,
      initialOptionsObject,
      variation,
      initialTaxRate
    );

    const initialProductObject = {
      id: product.id,
      options: initialOptionsObject,
      taxRate: initialTaxRate,
      finalPrice: initialFinalPrice,
      basePrice: product.price,
      finalSku: variation && "sku" in variation ? variation.sku : product.sku,
      variations: product.combinations,
      finalStock: determineStock(product.enabled, variation, product.inStock),
      imageUrl:
        variation && "imageUrl" in variation
          ? variation.imageUrl
          : product.imageUrl,
    };

    return initialProductObject;
  })();

  // log.debug("this is param initial state");
  // log.debug(paramInitialState);

  const reducerProductState = (state, action) => {
    switch (action.type) {
      case "select-product-option":
        if (action.payload.setQty) {
          action.payload.setQty(1);
        }
        const newState = { ...state };
        newState.options[action.payload.optionName].selectedChoiceName =
          action.payload.optionValue;
        //e.g. newState.274387434.options.Size.selectedChoiceMName

        log.debug(product.combinations);
        // Find matching variation
        const variation = findMatchingVariation(
          newState.options, // this is right
          product.combinations
        );

        // Update SKU
        newState.finalSku =
          variation && "sku" in variation ? variation.sku : product.sku;

        // Update Stock
        newState.finalStock = determineStock(
          product.enabled,
          variation,
          product.inStock
        );

        // Update price
        const newPrice = calculatePrice(
          newState.basePrice,
          newState.options,
          variation,
          newState.taxRate
        );
        newState.finalPrice = newPrice;

        //Image
        newState.imageUrl =
          variation && "imageUrl" in variation
            ? variation.imageUrl
            : product.imageUrl;

        // log.debug(newState);
        return newState;

      default:
        throw new Error();
    }
  };

  // Store state regarding currently selected Choice for each Option
  const [productState, dispatchProductState] = useReducer(
    reducerProductState,
    paramInitialState !== undefined ? paramInitialState : initialProductState
  );

  return [productState, dispatchProductState];
};
