import React from "react";
import EcoImg from "../resources/img/eco-logo.png";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Alert } from "rsuite";
import Button from "../components/Button";
import { useAuth } from "../ecwid/context/auth_context";

const baseName = process.env.REACT_APP_BASENAME;

const Footer = ({ disclaimer }) => {
  let history = useHistory();

  const { currentUser } = useAuth();

  const goToRegister = () => {
    window.gtag("event", "attempt_register_from_footer	", {
      event_category: "auth",
      event_label: "method",
    });

    history.push(`${baseName}/register`);
  };
  const goToMyAccount = () => {
    history.push(`${baseName}/myvip`);
  };

  return (
    <Wrapper>
      <div className="footer-header-container">
        <h2>
          Register with us and sign up to our newsletter to gain superhuman
          powers, oh and to receive our cool offers / deals!
        </h2>
      </div>
      <div className="footer-row2-container">
        <div className="footer-form-container">
          {currentUser?.uid ? (
            <Button
              onClick={goToMyAccount}
              style={{ width: "10rem" }}
              appearance="primary"
              size="medium"
              className="footer-form-button">
              Go to my account
            </Button>
          ) : (
            <Button
              onClick={goToRegister}
              style={{ width: "10rem" }}
              appearance="primary"
              size="medium"
              className="footer-form-button">
              Create an account
            </Button>
          )}
        </div>
        <div className="footer-img-container">
          <img src={EcoImg} alt="eco-img" />
        </div>
      </div>
      <div className="footer-disclaimer-container">
        {disclaimer ? (
          <p>{disclaimer}</p>
        ) : (
          <p>
            Disclaimer - we do not take liability for misuse of your super
            powers, we also take no liability if you are not satisfied with your
            powers
          </p>
        )}
      </div>
      <div className="footer-links-container">
        <div className="footer-link-1">
          <a href="mailto:info@nakedcoffee.co">info@nakedcoffee.co</a>
        </div>
        <div className="footer-link-2">
          <button onClick={() => history.push(`${baseName}/storefinder`)}>
            Find a store
          </button>
        </div>
        <div className="footer-link-3">
          <a href="mailto:developer@nudodesigns.com">Web fault</a>
        </div>
        <div className="footer-link-4">
          <button
            onClick={() => {
              Alert.info(
                "This page is coming soon. Sorry for any Inconvenience"
              );
            }}>
            FAQ's
          </button>
        </div>
        <div className="footer-link-5">
          <button
            onClick={() => {
              history.push(`${baseName}/terms`);
            }}>
            T&C's
          </button>
        </div>
      </div>
      <div className="deliv-cent">
        <a href="tel:07482186461">
          {" "}
          07482186461 If you have any problems with delivery please call this
          number
        </a>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  padding: 2rem 0;
  height: 80vh;
  min-height: 28rem;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  background-color: #ec1e79;
  @media (max-width: 900px) {
    grid-template-rows: 1fr 2fr 1fr 2fr;
    min-height: 100vh;
    height: 115vh;
  }
  /* 
  div {
    border: 1px solid black;
  } */

  //FIRST ROW
  .footer-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2vw 0 2vw;
  }
  h2 {
    font-family: "Snacker Comic";
    color: white;
    text-align: center;
    font-size: 2.5rem;
    @media (max-width: 1200px) {
      font-size: 2rem;
    }
    @media (max-width: 900px) {
      font-size: 1.7rem;
    }
  }

  //end of first row
  //SECOND ROW
  .footer-row2-container {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    @media (max-width: 900px) {
      grid-template-columns: none;
      grid-template-rows: 1fr 1fr;
    }
    /* div {
      border: 1px solid blue;
    } */
  }

  .footer-form-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 900px) {
      justify-content: center;
    }
  }

  .footer-form-button {
    height: 2.5rem;
    width: 8rem;
    border: 1px solid pink;
    color: white;
    border-radius: 1rem;

    @media (max-width: 900px) {
      width: 7rem;
    }
  }

  .footer-form {
    width: 40vw;
    height: 2.5rem;

    @media (max-width: 900px) {
      width: 45vw;
    }
  }

  .footer-img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      //height is the total height of footer divided by 4
      height: calc(70vh / 4);
      @media (max-width: 900px) {
        height: 5rem;
      }
    }
  }

  //end of second row
  //THIRD ROW
  .footer-disclaimer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10vw 0 10vw;
    p {
      font-family: "Snacker Comic";
      color: white;
      font-size: 1.5rem;
      text-align: center;
      @media (max-width: 900px) {
        font-size: 1rem;
      }
    }
  }
  //end of third row
  //FOURTH ROW
  .footer-links-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin: 0 2vw 0 2vw;

    @media (max-width: 900px) {
      grid-template-columns: none;
      grid-template-rows: repeat(5, 1fr);
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        background-color: transparent;
        font-family: "Snacker Comic";
        font-size: 1.7rem;
        @media (max-width: 1200px) {
          font-size: 1.5rem;
        }
      }
      a {
        background-color: transparent;
        font-family: "Snacker Comic";
        font-size: 1.7rem;
        transition: 0.4s;
        outline: none;
        text-decoration: none;
        @media (max-width: 1200px) {
          font-size: 1.5rem;
        }
      }
      a:hover {
        color: black;
      }
    }
  }

  .footer-link-1 {
    color: #28aae1;
  }

  .footer-link-2 {
    color: white;
    transition: 0.4s;
    &:hover {
      color: #000;
    }
  }

  .footer-link-3 {
    a {
      color: #42210b;
      transition: 0.4s;
      &:hover {
        color: #000;
      }
    }
  }

  .footer-link-4 {
    color: #f6eb0f;
    transition: 0.4s;
    &:hover {
      color: #000;
    }
  }

  .footer-link-5 {
    color: #8bc540;
    transition: 0.4s;
    &:hover {
      color: #000;
    }
  }
  .deliv-cent {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      color: rgba(255, 255, 255, 0.8);
      font-family: "Roaster original";
      text-transform: lowercase;
    }
  }
  //end of fourth row
`;

export default Footer;
