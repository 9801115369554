import log from "loglevel";
import { Alert } from "rsuite";
import axios from "axios";
import firebase from "firebase/app";
import { Notification, Button } from "rsuite";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/analytics";

const baseName = process.env.REACT_APP_BASENAME;

// Only works if the user has a valid token
export const loginUserByToken = async () => {
  try {
    //when decoded contains email uid etc
    let authToken = JSON.parse(window.localStorage.getItem("auth_token"));
    if (!authToken) return {};
    log.debug("authToken:", authToken);

    const resp = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/login_user_by_token`,
      //prettier-ignore
      { "token": authToken}
    );

    log.debug("resp-from loginUserByToken:", resp);

    if (
      resp.status === 200 &&
      "uid" in resp.data &&
      "name" in resp.data &&
      "email" in resp.data &&
      "token" in resp.data &&
      "firebase_token" in resp.data &&
      "ecwid_token" in resp.data &&
      "shippingAddresses" in resp.data &&
      "lifetime_loyalty_points" in resp.data &&
      "is_admin" in resp.data &&
      "is_staff" in resp.data &&
      "email_verified" in resp.data &&
      "ecwid_customer_id" in resp.data
    ) {
      log.debug("sso_ecwid_token:", resp.data.ecwid_token);

      //show success alert
      // Alert.success(`Welcome back ${resp.data.name}`);

      return resp.data;
    } else {
      window.localStorage.removeItem("auth_token");
      window.localStorage.removeItem("ec_token");
      window.localStorage.removeItem("uid");
      return {};
    }
  } catch (error) {
    log.debug(error.message);
    //Alert.error(error.message);
    window.localStorage.removeItem("auth_token");
    window.localStorage.removeItem("ec_token");
    window.localStorage.removeItem("uid");
    return {};
  }
};

export const getNewEcwidToken = async () => {
  const authToken = JSON.parse(window.localStorage.getItem("auth_token"));

  const newEcwidToken = await axios.post(
    `${process.env.REACT_APP_API_ADDRESS}/get_ec_token`,
    //prettier-ignore
    { "token": authToken }
  );
  log.debug("getNewEcwidToken returns:", newEcwidToken.data.ecwid_token);
  return newEcwidToken.data.ecwid_token;
};

export const addToCart = (
  productId,
  quantity,
  productStateOptions,
  storeType,
  history
) => {
  // check if its an instore or online order then check cart
  let cartItems;
  window.Ecwid.Cart.get((cart) => {
    cartItems = cart.items;
  });

  //CHECK IF IT'S AN ONLINE ITEM
  if (storeType === "onlinestore") {
    for (let i = 0; i < cartItems.length; i++) {
      let currItem = cartItems[i];
      let currItemSKU = currItem.product.sku.slice(0, 11);
      if (currItemSKU === "NKD-INSTORE") {
        Alert.warning(
          "You have instore products in you're cart, Please remove before continuing"
        );
        return;
      }
    }
  }

  //CHECK IF IT'S AN INSTORE ITEM
  if (storeType === "instore") {
    for (let i = 0; i < cartItems.length; i++) {
      let currItem = cartItems[i];
      let currItemSKU = currItem.product.sku.slice(0, 11);
      if (currItemSKU !== "NKD-INSTORE") {
        Alert.warning(
          "You have Online products in you're cart, Please remove before continuing"
        );
        return;
      }
    }
  }

  const EcwidOptions = {};

  for (const [optionName, optionObject] of Object.entries(
    productStateOptions
  )) {
    EcwidOptions[optionName] = optionObject.selectedChoiceName;
  }

  // log.debug("addToCart for product " + productId + " options are: ");
  // log.debug(EcwidOptions + "these are the ecwid options");

  const EcwidProductObject = {
    id: productId,
    quantity: quantity,
    options: EcwidOptions,
    callback: function (success, product, cart) {
      // log.debug("addProduct status = " + success); // true or false

      if (success) {
        function open(funcName) {
          Notification[funcName]({
            title: (
              <h3 className="notification-header">
                Successfully added to cart
              </h3>
            ),
            description: (
              <div className="notification-btn-container">
                <Button
                  className="notification-btn"
                  onClick={() => Notification.close()}>
                  continue
                </Button>
                <Button
                  className="notification-btn"
                  appearance="primary"
                  onClick={() => {
                    Notification.close();
                    history.push(`${baseName}/cart`, "onlinestore");

                    // if (storeType === "instore") {
                    //   history.push(`${baseName}/instore/cart`, "instore");
                    // } else {
                    //   history.push(`${baseName}/cart`, "onlinestore");
                    // }
                  }}>
                  checkout
                </Button>
              </div>
            ),
          });
        }

        open("success");
        // Alert.success("Successfully added to cart");
        // log.debug("Product added to cart = " + product.name);
      } else {
        Alert.error("Error adding to cart");
      }
    },
  };

  // log.debug(EcwidProductObject);

  window.Ecwid.Cart.addProduct(EcwidProductObject);
};

export const itemQuantity = (productState, setQuantity) => {
  let increaseQty;
  let decreaseQty;

  log.debug(productState);
  if (productState.variations.length > 0) {
    const variationId = productState.finalSku;
    const currVariation = productState.variations.find((item) => {
      return item.sku === variationId;
    });
    const currVariationStock = currVariation.quantity;

    increaseQty = () => {
      setQuantity((oldQty) => {
        let tempQty;
        if (oldQty >= currVariationStock) {
          tempQty = oldQty;
        } else {
          tempQty = oldQty + 1;
        }
        return tempQty;
      });
    };

    decreaseQty = () => {
      setQuantity((oldQty) => {
        let tempQty = oldQty - 1;
        if (tempQty < 1) {
          tempQty = 1;
        }
        return tempQty;
      });
    };

    return { increaseQty, decreaseQty };
  } else {
    increaseQty = () => {
      setQuantity((oldQty) => {
        let tempQty;
        if (oldQty >= 10) {
          tempQty = oldQty;
        } else {
          tempQty = oldQty + 1;
        }
        return tempQty;
      });
    };

    decreaseQty = () => {
      setQuantity((oldQty) => {
        let tempQty = oldQty - 1;
        if (tempQty < 1) {
          tempQty = 1;
        }
        return tempQty;
      });
    };

    return { increaseQty, decreaseQty };
  }
};

export const initialiseUser = async (
  uid,
  name,
  email,
  firebaseToken,
  ecwidToken,
  shippingAddresses,
  loyalty_points,
  isAdmin,
  isStaff,
  emailVerified,
  ecwidCustomerId
) => {
  log.debug(
    "auth_context loginUser: uid=" +
      uid +
      ", name=" +
      name +
      ", email=" +
      email +
      ", shippingAddresses=" +
      shippingAddresses +
      ", loyalty_points=" +
      loyalty_points +
      ", emailVerified=" +
      emailVerified
  );

  var database = undefined;

  if (uid.length > 0) {
    log.debug("initialiseUser logging into Firebase");

    const firebaseConfig = {
      apiKey: "AIzaSyAX1wj3tIa3EjNAxv7mSOnp46hFlGe1GXA",
      authDomain: "naked-coffee.firebaseapp.com",
      databaseURL:
        "https://naked-coffee-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "naked-coffee",
      storageBucket: "naked-coffee.appspot.com",
      messagingSenderId: "432375479868",
      appId: "1:432375479868:web:072e06f58d330393250d39",
      measurementId: "G-VYL43P9YRT",
    };

    const appName = "app" + String(new Date().getTime());

    try {
      const app = firebase.initializeApp(firebaseConfig, appName);

      log.debug("Attempting firebase auth with token ", firebaseToken);

      const auth = await app.auth().signInWithCustomToken(firebaseToken || "");

      const user = auth.user;
      log.debug("Firebase auth successful user=", user);

      const idTokenResult = await user.getIdTokenResult();
      log.debug("firebase idTokenResult ", idTokenResult);
      let isAnAdmin = isAdmin;

      if (!!idTokenResult.claims.admin) {
        // Show admin UI.
        log.debug("firebase user has admin claim setting isAdmin true");
        isAnAdmin = true;
      } else {
        // Show regular user UI.
        log.debug("firebase user does not have admin claim");
      }

      log.debug("firebase auth =", auth);
      database = app.database();

      let analytics;
      log.debug("firebase in helper:", firebase);

      if (!firebase.analytics.length) {
        analytics = firebase.analytics(app);
      } else {
        analytics = firebase.analytics; // if already initialized, use that one
      }
      // const analytics = firebase.analytics(app);

      const newState = {
        uid: uid || "",
        name: name || "",
        email: email || "",
        firebaseToken: firebaseToken,
        ecToken: ecwidToken,
        shippingAddresses: shippingAddresses || "",
        loyalty_points: loyalty_points, //because 0 is a falsy value in javascript
        isAdmin: isAnAdmin,
        isStaff: isStaff,
        database: database,
        emailVerified: emailVerified,
        ecwidCustomerId: ecwidCustomerId,
        app: app,
        auth: app.auth(),
        analytics: analytics,
      };

      log.debug("initialiseUser returning  ", newState);
      return newState;
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.error(
        "Firebase auth failed error code = ",
        errorCode,
        " message = ",
        errorMessage
      );
    }
  }
};

export const setNewEcwidSsoProfile = async (callback) => {
  const loggedInUser = await loginUserByToken();
  const newEcwidToken = loggedInUser?.ecwid_token;
  log.debug("setNewEcwidSsoProfile newEcwidToken", newEcwidToken);

  window.Ecwid.setSsoProfile(newEcwidToken);
  if (newEcwidToken.length > 0) {
    callback();
  }
};

export const getInitials = (currentUser) => {
  const names = currentUser.name.split(" ");
  if (names.length > 1) {
    const firstName = names[0].substring(0, 1).toLowerCase();
    const SurName = names[1].substring(0, 1).toLowerCase();
    return `${firstName}${SurName}`;
  } else {
    return `${names[0].substring(0, 1).toLowerCase()}`;
  }
};

export const updateOrder = async (orderId, orderStatus, setIsLoading) => {
  setIsLoading(true);
  const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/update_order_status`,
      //prettier-ignore
      { "token": authToken, "order_id": orderId, "order_status":  orderStatus}
    );
    log.debug("updating order status resp:", resp);
    if (resp.status === 200)
      Alert.success(`Succesfully updated order #${orderId}`);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    log.debug(error.response);
    Alert.error(error.response.data);
  }
};

export const checkout = async () => {
  //checkout
  //sign in user to ecwid again (can't use same token twice)
  // setIsCartLoading(true);

  const canGoToCheckout = window.Ecwid.Cart.canGotoCheckout(() => {});

  if (canGoToCheckout) {
    const newEcwidToken = await getNewEcwidToken();
    log.debug("newEcwidToken:", newEcwidToken);

    if (newEcwidToken.length > 0) {
      setNewEcwidSsoProfile(() => {
        // setIsCartLoading(false);

        window.Ecwid.Cart.gotoCheckout(function () {});
      });
    } else {
      // setIsCartLoading(false);
      return;
    }
    log.debug("newEcwidToken:", newEcwidToken);
  } else {
    Alert.error("Something went wrong");
  }
};
