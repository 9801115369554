import styled from "styled-components";

const DescrComp = ({
  title = false,
  text,
  backgColor,
  includeTitle = true,
  className,
  link = null,
}) => {
  return (
    <Wrapper className={`descr-container ${className && className}`}>
      {includeTitle && (
        <h2
          style={{
            fontFamily: "BradyBunchRemastered",
            color: backgColor,
            WebkitTextStrokeColor: "white",
            WebkitTextStrokeWidth: "1px",
            textAlign: "left",
          }}>
          {link ? (
            <a
              style={{
                fontFamily: "BradyBunchRemastered",
                color: backgColor,
                WebkitTextStrokeColor: "white",
                WebkitTextStrokeWidth: "1px",
                textAlign: "left",
              }}
              href="https://nakedcoffee.co">
              www.nakedcoffee.co
            </a>
          ) : (
            title
          )}
        </h2>
      )}

      <p className="descr">{text}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .descr {
    font-size: 1.4rem;
    font-family: "BradyBunchRemastered";
    /* text-transform: lowercase; */

    @media screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;

    @media screen and (max-width: 600px) {
      font-size: 1.8rem;
    }
  }
`;

export default DescrComp;
