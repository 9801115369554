import React from "react";
import styled from "styled-components";
import Logo from "../resources/img/logo.png";

const Loading = ({ text = null, showBackground, component, className }) => {
  return (
    <>
      <Wrapper>
        <div className={`loading ${className}`}>
          <img src={Logo} alt="logo" />
        </div>
        {text && (
          <p
            style={{
              textTransform: "lowercase",
              fontFamily: "Roaster Original",
            }}>
            {text}
          </p>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default Loading;
