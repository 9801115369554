import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Button } from "rsuite";
import QRCode from "qrcode.react";
import QRCape from "../../resources/img/qr-cape.png";
import { getInitials } from "../../helpers/helper";
import { useAuth } from "../../ecwid/context/auth_context";
import Logo from "../../resources/img/logo.png";

const baseName = process.env.REACT_APP_BASENAME;

const MyDiscount = () => {
  const { currentUser } = useAuth();
  let history = useHistory();

  return (
    <Wrapper>
      <Button
        className="go-back"
        style={{ fontFamily: "Roaster Original" }}
        appearance="default"
        onClick={() => history.push(`${baseName}/myvip`)}>
        &larr; go back
      </Button>
      <div className="qr-header">
        <div className="avatar-container">
          <div className="avatar">
            <span>{getInitials(currentUser)}</span>
          </div>
        </div>
        <p className="qr-title">super hero v.i.p</p>
      </div>
      <div className="qr-container">
        <div className="qr-img-container">
          <div className="inner-img">
            <QRCode
              size="215"
              value={"%TRN49?"}
              bgColor={"#fff"}
              fgColor={"rgba(249, 193, 22, 1)"}
              includeMargin={true}
              renderAs={"svg"}
              imageSettings={{
                src: Logo,
                x: null,
                y: null,
                height: 30,
                width: 30,
                excavate: false,
              }}
            />
          </div>
          <img src={QRCape} className="outer-img" alt="qr-cape" />
        </div>
      </div>

      <div className="qr-footer">
        <p>scan me in-store to recieve your super discount</p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: auto;
  display: grid;
  grid-template-rows: 2fr 2fr 1fr;
  grid-row-gap: 1rem;
  position: relative;

  .qr-header {
    padding-top: 1rem;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 1rem;
    /* border: 1px solid red; */
  }
  .qr-title {
    font-family: "Roaster Original";
    font-size: 3rem;
    -webkit-text-stroke: 1px white;
    text-shadow: 0px 4px 2px #ce2e2e;
    @media (max-width: 900px) {
      font-size: 2rem;
    }
  }

  .qr-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid blue; */
  }

  .go-back {
    position: absolute;
    top: 5%;
    left: 5%;
  }

  //qr code stuff
  .qr-img-container {
    border: 1px solid red;
    position: relative;
  }
  .inner-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-82%, -50%);
    z-index: 100;
  }
  .outer-img {
    height: 15rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .qr-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-family: "Roaster Original";
      font-size: 2rem;
      text-transform: lowercase;
      text-align: center;
      margin: 0 10vw;

      @media (max-width: 1200px) {
        font-size: 1.5rem;
      }

      @media (max-width: 900px) {
        font-size: 1.2rem;
      }
    }
  }

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar {
    background-color: rgba(25, 25, 25, 0.9);
    color: white;
    height: 7rem;
    width: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100rem;
    font-family: "Roaster Original";

    span {
      font-size: 2rem;
    }
  }
`;

export default MyDiscount;

/* <div>
          <h4>Store 3, Table 3:</h4>
          <QRCode
            value={
              "https://marcoshardy.co.uk/marcoswebreactsso/instore/selecttable?instore_table=table-3&instore_store=store-3"
            }
            bgColor={"#fff"}
            fgColor={"rgba(249, 193, 22, 1)"}
            includeMargin={false}
            renderAs={"svg"}
            imageSettings={{
              src: "https://cdn0.iconfinder.com/data/icons/controls-essential/48/v-46-2-512.png",
              x: null,
              y: null,
              height: 24,
              width: 24,
              excavate: true,
            }}
          />
        </div> */
