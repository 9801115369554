import React from "react";
import {
  HomePage,
  Beans,
  Error,
  Merch,
  StoreFinder,
  VideoAbout,
  AboutUsVideo,
} from "./Pages";
import SingleBeansContainer from "./components/Beans/SingleBeansContainer";
import SingleMerchContainer from "./components/Merch/SingleMerchContainer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Register from "./Pages/auth/Register";
import Login from "./Pages/auth/Login";
import PrivateRoute from "./components/auth/PrivateRoute";
import { AppProvider } from "./ecwid/context/product_context";
import { InStoreProductProvider } from "./ecwid/context/in_store_product_context";
import Cart from "./Pages/Cart";
import AdminOrders from "./adminShop/pages/orders/SelectStore";
import Menu from "./inStoreOrdering/pages/Menu";
import Food from "./inStoreOrdering/pages/Food";
import Drinks from "./inStoreOrdering/pages/drinks/Drinks";
import SelectTable from "./inStoreOrdering/pages/SelectTable";
import Checkout from "./inStoreOrdering/pages/Checkout";
import Treats from "./inStoreOrdering/pages/Treats";
import Points from "./inStoreOrdering/pages/Points";
import InStoreOrders from "./Pages/orders/MyOrders";
import FullSingleOrder from "./Pages/orders/FullSingleOrder";
import UserPermissions from "./inStoreOrdering/admin/UserPermissions";
import SotonUni from "./sotonUni/SotonUni";
import SotonUniLogin from "./sotonUni/auth/SotonUniLogin";
import SotonPrivRoute from "./sotonUni/auth/SotonPrivRoute";
import FirebaseEmailCall from "./Pages/myaccount/FirebaseEmailCall";
import DiscountPage from "./Pages/myaccount/DiscountPage";
import SelectedStoreOrders from "./adminShop/pages/orders/SelectedStoreOrders";
import InstorePrivateRoute from "./inStoreOrdering/pages/auth/InstorePrivateRoute";
import DrinksOrFoodSelect from "./adminShop/pages/orders/DrinksOrFoodSelect";
import AdminHome from "./adminShop/AdminHome";
import Orders from "./adminShop/pages/Orders";
import OnlineSales from "./adminShop/pages/sales/OnlineSales";
import InstoreSales from "./adminShop/pages/sales/InstoreSales";
import SelectSales from "./adminShop/pages/sales/SelectSales";
import MyAccountHomeScreen from "./Pages/myaccount/MyAccountHomeScreen";
import MyAccount from "./Pages/myaccount/MyDetails";
import ResetPassword from "./Pages/auth/ResetPassword";
import MyVip from "./Pages/myaccount/MyVip";
import CheckRegisterEmail from "./Pages/auth/CheckRegisterEmail";
import AdminDiscounts from "./adminShop/pages/discounts/AdminDiscounts";
import SelectDiscount from "./adminShop/pages/discounts/SelectDiscount";
import TermsAndConditions from "./Pages/tAndC/TermsAndConditions";
import AppImages from "./Pages/AppImages";

const baseName = process.env.REACT_APP_BASENAME;

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute
          fromPage="mydetails"
          exact
          path={`${baseName}/mydetails`}
          component={MyAccount}
        />

        <PrivateRoute
          fromPage="myvip"
          exact
          path={`${baseName}/myvip`}
          component={MyVip}
        />

        <PrivateRoute
          fromPage="myorders"
          exact
          path={`${baseName}/myorders`}
          component={InStoreOrders}
        />

        <Route exact path={`${baseName}/cart`} component={Cart} />

        <Route exact path={`${baseName}/`} component={HomePage} />
        <Route exact path={`${baseName}/vip`} component={MyAccountHomeScreen} />
        <Route exact path={`${baseName}/storefinder`} component={StoreFinder} />
        <Route exact path={`${baseName}/about`} component={VideoAbout} />
        <Route exact path={`${baseName}/aboutvideo`} component={AboutUsVideo} />
        <Route exact path={`${baseName}/login`} component={Login} />
        <Route exact path={`${baseName}/register`} component={Register} />
        <Route
          exact
          path={`${baseName}/terms`}
          component={TermsAndConditions}
        />
        <Route
          exact
          path={`${baseName}/appimagesajsdasdc`}
          component={AppImages}
        />
        <Route
          exact
          path={`${baseName}/confirmemail`}
          component={CheckRegisterEmail}
        />
        <Route
          exact
          path={`${baseName}/fbrstoradddtls`}
          component={FirebaseEmailCall}
        />

        <Route
          exact
          path={`${baseName}/fbrstoradddtls/resetpassword`}
          component={ResetPassword}
        />
        <Route
          exact
          path={`${baseName}/mydiscounts`}
          component={DiscountPage}
        />

        {/* end of admin stuff */}

        <Route path={[`${baseName}/merch`, `${baseName}/beans`]}>
          <AppProvider>
            <Switch>
              <Route exact path={`${baseName}/merch`} component={Merch} />
              <Route exact path={`${baseName}/beans`} component={Beans} />
              <Route
                exact
                path={`${baseName}/beans/:id`}
                component={SingleBeansContainer}
              />
              <Route
                exact
                path={`${baseName}/merch/:id`}
                component={SingleMerchContainer}
              />
            </Switch>
          </AppProvider>
        </Route>

        <Route path={`${baseName}/instore`}>
          {/* in store ordering  */}
          <InStoreProductProvider>
            <Switch>
              <InstorePrivateRoute
                component={Menu}
                exact
                path={`${baseName}/instore/menu`}
              />
              <InstorePrivateRoute
                component={Food}
                exact
                path={`${baseName}/instore/menu/food`}
              />
              <InstorePrivateRoute
                component={Drinks}
                exact
                path={`${baseName}/instore/menu/drinks`}
              />
              <InstorePrivateRoute
                component={Treats}
                exact
                path={`${baseName}/instore/menu/treats`}
              />
              <InstorePrivateRoute
                component={Points}
                exact
                path={`${baseName}/instore/menu/points`}
              />
              <InstorePrivateRoute
                component={Cart}
                exact
                path={`${baseName}/instore/cart`}
              />
              {/* <Route exact path={`${baseName}/instore/menu`} component={Menu} /> */}
              {/* <Route
                exact
                path={`${baseName}/instore/menu/food`}
                component={Food}
              />
              <Route
                exact
                path={`${baseName}/instore/menu/drinks`}
                component={Drinks}
              />

              <Route
                exact
                path={`${baseName}/instore/menu/treats`}
                component={Treats}
              />

              <Route
                exact
                path={`${baseName}/instore/menu/points`}
                component={Points}
              /> */}

              <InstorePrivateRoute
                exact
                path={`${baseName}/instore/cart`}
                component={Cart}
              />

              <Route
                exact
                path={`${baseName}/instore/selecttable`}
                component={SelectTable}
              />

              <InstorePrivateRoute
                exact
                path={`${baseName}/instore/checkout`}
                component={Checkout}
              />

              <Route path="*">
                <Error homeLink={`${baseName}/instore/menu`} />
              </Route>
            </Switch>
          </InStoreProductProvider>
        </Route>

        <Route path={`${baseName}/sotonuni`}>
          {/* in store ordering  */}
          <Switch>
            <SotonPrivRoute
              exact
              path={`${baseName}/sotonuni/`}
              component={SotonUni}
            />

            <Route
              exact
              path={`${baseName}/sotonuni/login`}
              component={SotonUniLogin}
            />

            <Route path="*">
              <Error homeLink={`${baseName}/sotonuni`} />
            </Route>
          </Switch>
        </Route>

        {/* admin home shop stuff  */}
        <Route path={`${baseName}/mystore`}>
          <Switch>
            {/* <PrivateRoute
              needAdmin={true}
              exact
              path={`${baseName}/merch`}
              component={Merch}
            /> */}

            <PrivateRoute
              needAdmin={true}
              exact
              path={`${baseName}/mystore`}
              component={AdminHome}
            />

            <PrivateRoute
              fromPage="myvip" //because if they're not an admin we wan't to go back to myacount
              needStaff={true}
              exact
              path={`${baseName}/mystore/instoreorders`}
              component={AdminOrders}
            />

            <PrivateRoute
              fromPage="myvip" //because if they're not an admin we wan't to go back to myacount
              needStaff={true}
              exact
              path={`${baseName}/mystore/instoreorders/:store/:foodordrink`}
              component={SelectedStoreOrders}
            />

            <PrivateRoute
              fromPage="myvip" //because if they're not an admin we wan't to go back to myacount
              needStaff={true}
              exact
              path={`${baseName}/mystore/instoreorders/select`}
              component={DrinksOrFoodSelect}
            />

            <PrivateRoute
              fromPage="myvip" //because if they're not an admin we wan't to go back to myacount
              needAdmin={true}
              exact
              path={`${baseName}/mystore/userpermissions`}
              component={UserPermissions}
            />

            <PrivateRoute
              fromPage="myvip" //because if they're not an admin we wan't to go back to myacount
              needStaff={true}
              exact
              path={`${baseName}/mystore/instoreorders`}
              component={AdminOrders}
            />

            <PrivateRoute
              fromPage="instoreorders"
              exact
              path={`${baseName}/mystore/instoreorders/:id`}
              component={FullSingleOrder}
            />

            <PrivateRoute
              needAdmin={true}
              exact
              path={`${baseName}/mystore/orders`}
              component={Orders}
            />

            <PrivateRoute
              needAdmin={true}
              exact
              path={`${baseName}/mystore/selectsales`}
              component={SelectSales}
            />

            <PrivateRoute
              needAdmin={true}
              exact
              path={`${baseName}/mystore/sales/online`}
              component={OnlineSales}
            />

            <PrivateRoute
              needAdmin={true}
              exact
              path={`${baseName}/mystore/sales/instore`}
              component={InstoreSales}
            />

            <PrivateRoute
              fromPage="myvip" //because if they're not an admin we wan't to go back to myacount
              needAdmin={true}
              exact
              path={`${baseName}/mystore/managediscounts/select`}
              component={SelectDiscount}
            />

            <PrivateRoute
              fromPage="myvip" //because if they're not an admin we wan't to go back to myacount
              needAdmin={true}
              exact
              path={`${baseName}/mystore/managediscounts/:instoreoronline`}
              component={AdminDiscounts}
            />

            <Route path="*">
              <Error homeLink={`${baseName}/mystore`} />
            </Route>
          </Switch>
        </Route>
        {/* end of admin home shop stuff  */}

        <Route path="*">
          <Error homeLink={`${baseName}/`} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
