import log from "loglevel";
import React, { useEffect, useState, useRef } from "react";
import "rsuite/dist/styles/rsuite-default.css";
import { loginUserByToken, initialiseUser } from "./helpers/helper";
import EcwidApp from "./EcwidApp";

// Make log available globally
window.log = log;
// const homePage = process.env.REACT_APP_HOSTNAME;

const App = () => {
  const [isEcwidScriptAdded, setIsEcwidScriptAdded] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({});
  const storeDiv = useRef(null);
  const scriptRef = useRef(null);

  useEffect(() => {
    (async () => {
      window.localStorage.setItem("show_ec_logs", "false");
      const checkLoggedInUser = await loginUserByToken();
      log.debug("checkLoggedInUser", checkLoggedInUser);

      const newUserAuthToken = checkLoggedInUser.token;
      log.debug("newUserToken:", newUserAuthToken);
      if (newUserAuthToken) {
        window.localStorage.setItem(
          "auth_token",
          JSON.stringify(newUserAuthToken)
        );
      }

      const initialLoggedInUser = await initialiseUser(
        checkLoggedInUser?.uid || "",
        checkLoggedInUser?.name || "",
        checkLoggedInUser?.email || "",
        checkLoggedInUser?.firebase_token,
        checkLoggedInUser?.ecwid_token,
        checkLoggedInUser?.shippingAddresses || "",
        checkLoggedInUser?.loyalty_points, //because 0 is a falsy value in javascript
        checkLoggedInUser?.is_admin || "",
        checkLoggedInUser?.is_staff || "",
        checkLoggedInUser?.email_verified || false,
        checkLoggedInUser?.ecwid_customer_id || ""
      );

      log.debug("Setting initialLoggedInUser to ", initialLoggedInUser);
      setLoggedInUser(initialLoggedInUser);

      const ecwid_token = checkLoggedInUser?.ecwid_token || "";
      window.ecwid_sso_profile = ecwid_token;
      log.debug("Setting ecwid_token to: ", ecwid_token);

      if (
        window.sessionStorage.getItem("instore_table") !== null &&
        window.sessionStorage.getItem("instore_store") !== null
      ) {
        const instore_table = window.sessionStorage.getItem("instore_table");
        const instore_store = window.sessionStorage.getItem("instore_store");

        window.ec = window.ec || Object();
        window.ec.order = window.ec.order || Object();
        window.ec.order.extraFields = window.ec.order.extraFields || Object();

        window.ec.order.extraFields.select_store = {
          title: "Select Store:",
          type: "select",
          required: true,
          options: [
            {
              title: instore_store,
            },
          ],
          value: instore_store, // Default value
          checkoutDisplaySection: "payment_details",
        };

        window.ec.order.extraFields.select_table = {
          title: "Select Table:",
          type: "select",
          required: true,
          options: [
            {
              title: instore_table,
            },
          ],
          value: instore_table, // Default value
          checkoutDisplaySection: "payment_details",
        };
        window.ec.order.extraFields.allergy = {
          title: "Allergies or specific dietary requirements?",
          textPlaceholder: "",
          type: "text",
          tip: "We will notify staff in the kitchen when the order is placed",
          required: false,
          checkoutDisplaySection: "payment_details",
        };
      }

      let script = document.createElement("script");
      // script.charset = "utf-8";
      script.type = "text/javascript";
      script.src =
        "https://app.ecwid.com/script.js?33357088&data_platform=singleproduct_v2";
      script.defer = false;
      script.ref = scriptRef;

      if (!scriptRef.current) {
        log.debug("Appending storeDiv");
        storeDiv.current.appendChild(script);
        setIsEcwidScriptAdded(true);
      }
    })();
  }, []);

  return (
    <>
      <div id="my-store-33357088" ref={storeDiv}></div>
      <EcwidApp
        isEcwidScriptAdded={isEcwidScriptAdded}
        loggedInUser={loggedInUser}
      />
    </>
  );
};

export default App;
