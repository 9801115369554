import React from "react";
// import { Link, useHistory } from "react-router-dom";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
  Button,
  Loader,
  HelpBlock,
} from "rsuite";
import styled from "styled-components";

const InputForm = ({
  onFormSubmit,
  formRef,
  isLoading,
  formValue,
  setFormValue,
  initialForm,
}) => {
  const onFormChange = (value) => {
    //rsuite returns all the form inputs as an object
    setFormValue(value);
  };

  const onFormReset = () => {
    setFormValue(initialForm);
  };

  if (isLoading) {
    return <Loader className="center" content="Loading..." />;
  }

  //   const baseName = process.env.REACT_APP_BASENAME;

  return (
    <Wrapper>
      <h1
        style={{
          marginBottom: "100px",
          fontFamily: "Roaster Original",
          textTransform: "lowercase",
        }}>
        Enter Password
      </h1>
      <Form onChange={onFormChange} formValue={formValue} ref={formRef}>
        <FormGroup>
          <ControlLabel
            style={{
              fontFamily: "Roaster Original",
              textTransform: "lowercase",
            }}>
            Password
          </ControlLabel>
          <FormControl
            name="password"
            type="password"
            autoComplete="current-password"
          />
          <HelpBlock tooltip>Given by Ian</HelpBlock>
        </FormGroup>

        <FormGroup className="center">
          <ButtonToolbar>
            <Button appearance="primary" onClick={onFormSubmit}>
              Submit
            </Button>
            <Button appearance="default" onClick={onFormReset}>
              Cancel
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* font-family: "Roaster Original";
  text-transform: lowercase; */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .instead-btn {
    background-color: transparent;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    margin-top: 2rem;
    font-family: "Roaster Original" !important;
    text-transform: lowercase;
  }
`;

export default InputForm;
