import log from 'loglevel';
import React, { useState, useEffect } from "react";
import VideoPlayer from "react-video-js-player";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import Nav from "../components/Navigation";
import About from "../resources/Video/about-us.mp4";
import PosterImg from "../resources/img/video-poster.png";

const VideoAbout = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const checkSize = () => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    log.debug("useEffect");
    window.addEventListener("resize", checkSize);
    return () => {
      log.debug("cleanup");
      window.removeEventListener("resize", checkSize);
    };
  }, []);

  log.debug(size);

  const history = useHistory();
  const handleEnd = () => {
    history.push("/");
  };
  const videoSrc = About;
  const poster = PosterImg;

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    preload: "metadata",
    width: size.width,
    height: size.height,
    notSupportedMessage: "Your browser does not support this video.",
  };

  return (
    <>
      <Nav />
      <Wrapper>
        <VideoPlayer
          src={videoSrc}
          poster={poster}
          playbackRates={null}
          autoplay
          {...videoJsOptions}
          onEnd={handleEnd}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default VideoAbout;
