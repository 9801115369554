import React, { useEffect } from "react";
import Nav from "../components/Navigation";
import Footer from "../components/Footer";
import merchHeader from "../resources/img/Merch/merch-header.png";
import MerchContainer from "../components/Merch/MerchContainer";

const Merch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Nav />
      <img src={merchHeader} alt="merch header" className="merch-header" />
      <MerchContainer />
      <Footer />
    </>
  );
};

export default Merch;
