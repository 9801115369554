import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import Popover from "@material-ui/core/Popover";

import Card from "./MerchCard";
import Loading from "../Loading";
import StoreDetails from "../../resources/details/store-finder-details";
import { useAppContext } from "../../ecwid/context/product_context";
import { useHistory } from "react-router";

const baseName = process.env.REACT_APP_BASENAME;

const MerchContainer = () => {
  const { loading, products } = useAppContext();

  const [isPopupActive, setIsPopupActive] = useState(true);

  let history = useHistory();

  const removePopup = () => setIsPopupActive(false);

  setTimeout(() => {
    removePopup();
  }, 10000);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="merch-tshirt marginLR" onClick={removePopup}>
          {products.merch.items.map((product, i) => {
            return (
              <Card
                key={product.id}
                productId={product.id}
                productIndex={i}
                cardClass={"card img" + ((i % 13) + 1)}
              />
            );
          })}
          <Popover
            className="pop-up clickable-popup"
            anchorReference="none"
            disableEnforceFocus={true}
            open={isPopupActive}
            marginThreshold={0}>
            <ImCross className="pop-up-cross" onClick={removePopup} />
            <img
              src={StoreDetails[5].img}
              alt="store manager"
              className="pop-up-avatar"
            />
            <p className="pop-up-text">
              Hi I'm Sooney and im here to help you pick a naked style! Feel
              free to ask me any questions:{" "}
              <a href="mailto:info@nakedcoffee.co">Email me</a>
            </p>
          </Popover>
        </div>
        {history.location.state === "myvip" && (
          <div className="go-back-container">
            <button onClick={() => history.push(`${baseName}/myvip`)}>
              &larr; Back to my account
            </button>
          </div>
        )}
      </>
    );
  }
};

export default MerchContainer;
