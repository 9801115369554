import React from "react";
import styled from "styled-components";

import MenuLogo from "../../../resources/img/instore/menu-logo.png";

const Header = ({ img, color }) => {
  return (
    <Wrapper style={{ backgroundColor: color }}>
      <div className="main-header-container">
        <img src={MenuLogo} alt="logo" />
      </div>
      <div className={img ? "img-header-container" : "main-header-container"}>
        {img ? (
          <img src={img} alt="menu-item" className="menu-item-img" />
        ) : (
          <p className="header-text">Press on icons to order food</p>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 6rem;
  height: 15vh;
  display: grid;
  grid-template-columns: 1fr 4fr;
  -webkit-box-shadow: 0px 8px 14px -4px rgba(0, 0, 0, 0.48);
  box-shadow: 0px 8px 14px -4px rgba(0, 0, 0, 0.48);

  @media screen and (max-width: 600px) {
    padding: 0 1rem;
    grid-column-gap: 0.3rem;
  }

  .main-header-container {
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img-header-container {
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .menu-item-img {
    padding-right: 15vw;
  }

  img {
    height: 5rem;
    @media screen and (max-width: 1200px) {
      height: 4rem;
    }

    @media screen and (max-width: 600px) {
      height: 3rem;
    }
  }

  .header-text {
    font-size: 2rem;
    text-transform: lowercase;
    font-family: "Roaster Original";
    text-shadow: 1px 1px white;
    @media screen and (max-width: 1200px) {
      font-size: 1.3rem;
    }

    @media screen and (max-width: 600px) {
      font-size: 1.2rem;
      letter-spacing: -1.6px;
    }
  }
`;

export default Header;
