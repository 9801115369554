import React from "react";
import styled from "styled-components";
import UpdateBtn from "../../../inStoreOrdering/admin/components/UpdateBtn";

const OrderModal = ({ order, isModalOpen, setIsModalOpen }) => {
  const closeModal = () => {
    setIsModalOpen((oldS) => ({ ...oldS, open: false }));
  };
  return (
    <Wrapper>
      <div onClick={closeModal}>
        {/* <h1>{order?.extraFields?.select_table}</h1> */}
        <h2>{order?.id}</h2>
      </div>
      <div className="items-container" onClick={closeModal}>
        <div>
          <h3>Items:</h3>
        </div>

        {order?.items?.map((order) => {
          return (
            <div className="single-item-container">
              <p>
                {order.name} x<span>{order.quantity}</span>
              </p>
              <img
                src={order.hdThumbnailUrl}
                alt="orders-img"
                className="single-item-img"
              />
            </div>
          );
        })}
      </div>
      <div className="button-container">
        <UpdateBtn
          progressState={isModalOpen.progressState}
          order={order}
          setProgressState={isModalOpen.setProgressState}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.25);

  border-radius: 2rem;
  height: 70vh;
  width: 50vw;
  z-index: 999;
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;

  div {
    font-family: "Roaster Original";
    text-transform: lowercase;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    text-transform: capitalize;
  }

  //Middle Grid
  .items-container {
    display: grid;
    grid-template-rows: 1fr 2fr;
  }

  .single-item-container {
    border: 1px solid blue;
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* flex-direction: column; */
  }

  .single-item-img {
    height: 3rem;
  }

  //LAST GRID
  .button-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1rem;
    padding: 0 1rem 0 1rem;
  }
`;

export default OrderModal;
