import React, { useState } from "react";
import Button from "../Button";
import { useHistory } from "react-router-dom";
import CardOption from "./MerchCardOption";
import { useProductState } from "../../ecwid/reducers/useProductState";
import Nav from "../Navigation";
import { addToCart, itemQuantity } from "../../helpers/helper";

const baseName = process.env.REACT_APP_BASENAME;

const SingleMerch = ({ productId, product, prevProductState }) => {
  const [quantity, setQuantity] = useState(1);
  const [productState, dispatchProductState] = useProductState(
    product,
    prevProductState
  );

  let history = useHistory();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const { finalPrice: price, finalStock: stock, imageUrl: img } = productState;

  const { description: descr, name } = product;

  const { increaseQty, decreaseQty } = itemQuantity(productState, setQuantity);
  // <div dangerouslySetInnerHTML={createMarkup()} />;

  // const logOption = () => {
  //   getSingleImg(productId);
  // };

  return (
    <>
      <Nav />
      <section className="single-merch">
        <div className="single-merch__image">
          <img src={img} alt={name} />
        </div>
        <div className="single-merch__text">
          <h1 className="single-merch__text--title">{name}</h1>
          <h2 className="single-merch__text--price">
            <span style={{ marginRight: "5px" }}>£</span>
            {price}
          </h2>

          <CardOption
            classParam="single-merch__text--options"
            key={productId}
            productState={productState}
            dispatchProductState={dispatchProductState}
            setQuantity={setQuantity}
            // returnsVStock={() => log.debug("hello world")}
          />

          <li className="single-merch__text--options">
            <span className="span-name">stock:</span>
            <span>{stock ? "Yes" : "No"}</span>
          </li>

          <p
            dangerouslySetInnerHTML={{ __html: descr }}
            className="single-merch__text--descr"
          />

          <h4 className="quantity"> Quantity: {quantity}</h4>
          <div className="btn-container">
            {stock ? (
              <>
                <Button
                  appearance="secondary"
                  className="btn-container--links btn-container--links-prev"
                  onClick={() => history.push(`${baseName}/merch`)}
                  size="medium">
                  Previous
                </Button>
                <div>
                  <Button
                    appearance="secondary"
                    size="small"
                    onClick={increaseQty}
                    className="btn-container--links">
                    &#43;
                  </Button>
                  <Button
                    appearance="secondary"
                    size="small"
                    onClick={decreaseQty}
                    className="btn-container--links">
                    &minus;
                  </Button>
                </div>
                <Button
                  appearance="secondary"
                  size="medium"
                  onClick={() =>
                    addToCart(
                      product.id,
                      quantity,
                      productState.options,
                      "onlinestore",
                      history
                    )
                  }
                  className="btn-container--links">
                  Add to cart
                </Button>
              </>
            ) : (
              <>
                <Button
                  appearance="secondary"
                  className="btn-container--links btn-container--links-prev"
                  onClick={() => history.push(`${baseName}/merch`)}
                  size="medium">
                  Previous
                </Button>
                <div>
                  <Button
                    appearance="secondary"
                    size="medium"
                    onClick={increaseQty}
                    className="btn-container--links">
                    &#43;
                  </Button>
                  <Button
                    appearance="secondary"
                    size="medium"
                    onClick={decreaseQty}
                    className="btn-container--links">
                    &minus;
                  </Button>
                </div>
                <Button
                  disabled={true}
                  shouldHover={false}
                  size="medium"
                  appearance="secondary"
                  style={{
                    backgroundColor: "rgba(25,25,25,0.3)",
                    borderRadius: "2rem",
                  }}>
                  Out of Stock
                </Button>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleMerch;
