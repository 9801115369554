import log from "loglevel";
import React, { useState, useContext, useEffect, createContext } from "react";
// import { useCallback } from "react";

/* Following are for Marcos Hardy test store 
const storeId = "32429266";
const token = "public_pug5xGzLhCTTEWRf9ziAjGZ61Psjke1F";
const categoryId = "72312951";
*/

/* Naked Coffee production store */
const storeId = "33357088";
const token = "public_pug5xGzLhCTTEWRf9ziAjGZ61Psjke1F";
//const categoryId = "72636417";
const categories = { drinks: 109694391, food: 109566733 };

const productsUrl = `https://app.ecwid.com/api/v3/${storeId}/products?limit=100&token=${token}&withSubcategories=true&sortBy=DEFINED_BY_STORE_OWNER&enabled=true&category=`;
const profileUrl = `https://app.ecwid.com/api/v3/${storeId}/profile?token=${token}&showExtendedInfo=true`;

const InStoreProductContext = createContext();

const InStoreProductProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    try {
      const products = {};

      for (const categoryName in categories) {
        const response = await fetch(productsUrl + categories[categoryName]);
        products[categoryName] = await response.json();
      }

      const profileResponse = await fetch(profileUrl);
      products.profile = await profileResponse.json();

      log.debug("INSTORE products object from ecwid:");
      log.debug(products);
      setProducts(products);

      setLoading(false);
    } catch (error) {
      log.debug(error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <InStoreProductContext.Provider value={{ loading, products }}>
      {children}
    </InStoreProductContext.Provider>
  );
};

// make sure use
export const useInstoreProductContext = () => {
  return useContext(InStoreProductContext);
};

export { InStoreProductContext, InStoreProductProvider };
