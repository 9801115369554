import log from "loglevel";
import React, { useState, useEffect } from "react";
import { Button } from "rsuite";
import styled from "styled-components";

import Loading from "../../components/Loading";
import axios from "axios";
import SingleOrder from "./SingleOrder";
import { useHistory } from "react-router";
import GoBackBtn from "../../components/GoBackBtn";

const baseName = process.env.REACT_APP_BASENAME;

const InStoreOrders = () => {
  const [customerOrders, setCustomerOrders] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  let history = useHistory();

  const getCustomersOrders = async () => {
    setIsLoading(true);
    const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
    if (authToken) {
      try {
        setIsLoading(true);

        const resp = await axios.post(
          `${process.env.REACT_APP_API_ADDRESS}/get_customer_orders`,
          { token: authToken }
        );
        // log.debug(resp);
        console.log("resp:", resp);

        const orders = Object.values(resp.data).reverse();
        log.debug("reversed orders", orders);

        setCustomerOrders(orders);

        setIsLoading(false);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        log.debug(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getCustomersOrders();
  }, []);

  if (isLoading) {
    return (
      <>
        <GoBackBtn goBack={() => history.push(`${baseName}/myvip`)} />
        <Loading text="Fetching your orders" />
      </>
    );
  }

  if (customerOrders?.length > 0) {
    return (
      <>
        <GoBackBtn goBack={() => history.push(`${baseName}/myvip`)} />
        <OrderHeader>
          <h1>Your Orders</h1>
        </OrderHeader>

        <SingleOrderContainer>
          {customerOrders.map((order) => {
            return (
              <SingleOrder
                key={order.id}
                order={order}
                getCustomersOrders={getCustomersOrders}
              />
            );
          })}
        </SingleOrderContainer>
      </>
    );
  }

  return (
    <>
      <GoBackBtn goBack={() => history.push(`${baseName}/myvip`)} />

      <Wrapper>
        <h1>You have no Orders</h1>
        <Button
          appearance="ghost"
          onClick={() => history.push(`${baseName}/merch`, "myip")}>
          make an Order
        </Button>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  font-family: "Roaster Original";
  text-transform: lowercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  padding: 25vh 0;

  /* h2 {
    margin: 3rem 0;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 2rem 0;
  } */
`;

const SingleOrderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 4vw;
  grid-row-gap: 3rem;
  margin: 3rem 4vw;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 3rem;

    div {
      margin: 0 auto;
    }
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 3rem;

    div {
      margin: 0 auto;
    }
  }

  /* grid-template-rows: auto; */
`;

const OrderHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roaster Original";
  text-transform: lowercase;
  margin: 1rem 0;
`;
export default InStoreOrders;
