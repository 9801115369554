import React from "react";
import styled from "styled-components";

const Button = ({
  children,
  onClick,
  loading,
  className,
  style,
  size,
  appearance,
  disabled,
  shouldHover = true,
}) => {
  if (appearance === "primary") {
    if (shouldHover) {
      return (
        <PrimaryHoverWrapper
          disabled={disabled}
          className={className}
          style={
            size === "small"
              ? { fontSize: "0.65rem", ...style }
              : size === "medium"
              ? { fontSize: "0.8rem", ...style }
              : { fontSize: "1.3rem", height: "2.5rem", ...style }
          }
          onClick={onClick}>
          {loading ? <div className="loader"></div> : children}
        </PrimaryHoverWrapper>
      );
    } else {
      return (
        <PrimaryNoHoverWrapper
          disabled={disabled}
          className={className}
          style={
            size === "small"
              ? { fontSize: "0.65rem", ...style }
              : size === "medium"
              ? { fontSize: "0.8rem", ...style }
              : { fontSize: "1.3rem", height: "2.5rem", ...style }
          }
          onClick={onClick}>
          {loading ? <div className="loader"></div> : children}
        </PrimaryNoHoverWrapper>
      );
    }
  }

  if (appearance === "secondary") {
    if (shouldHover) {
      return (
        <SecondaryHoverWrapper
          disabled={disabled}
          className={className}
          style={
            size === "small"
              ? { fontSize: "0.65rem", ...style }
              : size === "medium"
              ? { fontSize: "0.8rem", ...style }
              : { fontSize: "1.3rem", height: "2.5rem", ...style }
          }
          onClick={onClick}>
          {loading ? <div className="loader"></div> : children}
        </SecondaryHoverWrapper>
      );
    } else {
      return (
        <SecondaryNoHoverWrapper
          disabled={disabled}
          className={className}
          style={
            size === "small"
              ? { fontSize: "0.65rem", ...style }
              : size === "medium"
              ? { fontSize: "0.8rem", ...style }
              : { fontSize: "1.3rem", height: "2.5rem", ...style }
          }
          onClick={onClick}>
          {loading ? <div className="loader"></div> : children}
        </SecondaryNoHoverWrapper>
      );
    }
  }

  return <button>{children}</button>;
};

const PrimaryHoverWrapper = styled.button`
  font-family: "Roaster Original";
  text-transform: lowercase;
  text-align: center;
  white-space: nowrap;
  color: #ed1e79;
  border: 1.7px solid #ed1e79;
  height: 2rem;
  text-transform: lowercase;
  border-radius: 2rem;
  background-color: transparent;
  text-decoration: none;
  transition: 0.4s;
  line-height: 0.8rem;

  &:focus:hover {
    transform: none;
    box-shadow: none;
    color: #ed1e79;
    background-color: white;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 2px 14px 3px rgba(0, 0, 0, 0.18);
    color: white;
    background-color: #ed1e79;

    & > a {
      color: white;
    }
  }

  //for spinner
  .loader {
    margin: 0 auto;
    border: 4px solid #f9c116;
    border-radius: 50%;
    border-top: 4px solid rgb(78, 150, 247);
    border-right: 4px solid #94c43e;
    border-bottom: 4px solid #ed1e79;
    width: 1rem;
    height: 1rem;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const PrimaryNoHoverWrapper = styled.button`
  font-family: "Roaster Original";
  text-transform: lowercase;
  text-align: center;
  white-space: nowrap;
  height: 2rem;
  text-transform: lowercase;
  border-radius: 2rem;
  text-decoration: none;
  background-color: #ed1e79;
  color: white;
  transition: 0.4s;
  line-height: 0.8rem;

  &:hover {
    box-shadow: 0px 2px 14px 3px rgba(0, 0, 0, 0.18);
  }

  //for spinner
  .loader {
    margin: 0 auto;
    border: 4px solid #f9c116;
    border-radius: 50%;
    border-top: 4px solid rgb(78, 150, 247);
    border-right: 4px solid #94c43e;
    border-bottom: 4px solid #ed1e79;
    width: 1rem;
    height: 1rem;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SecondaryHoverWrapper = styled.button`
  font-family: "Roaster Original";
  text-transform: lowercase;
  text-align: center;
  white-space: nowrap;
  color: rgb(78, 150, 247);
  border: 1.7px solid rgb(78, 150, 247);
  text-transform: lowercase;
  border-radius: 0.7rem;
  /* padding: 10px 2rem; */
  height: 2rem;
  background-color: transparent;
  text-decoration: none;
  transition: 0.4s;
  line-height: 0.8rem;

  &:focus:hover {
    transform: none;
    box-shadow: none;
    color: rgb(78, 150, 247);
    background-color: white;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 2px 14px 3px rgba(0, 0, 0, 0.18);
    color: white;
    background-color: rgb(78, 150, 247);
    & > a {
      color: white;
    }
  }

  //for spinner
  .loader {
    margin: 0 auto;

    border: 4px solid #f9c116;
    border-radius: 50%;
    border-top: 4px solid rgb(78, 150, 247);
    border-right: 4px solid #94c43e;
    border-bottom: 4px solid #ed1e79;
    width: 1rem;
    height: 1rem;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SecondaryNoHoverWrapper = styled.button`
  font-family: "Roaster Original";
  text-transform: lowercase;
  text-align: center;
  white-space: nowrap;
  color: rgb(78, 150, 247);
  /* border: 1.7px solid rgb(78, 150, 247); */
  text-transform: lowercase;
  border-radius: 0.7rem;
  /* padding: 10px 2rem; */
  height: 2rem;
  background-color: rgb(78, 150, 247);
  color: white;
  text-decoration: none;
  transition: 0.4s;
  line-height: 0.8rem;

  &:hover {
    box-shadow: 0px 2px 14px 3px rgba(0, 0, 0, 0.18);
  }

  //for spinner
  .loader {
    margin: 0 auto;

    border: 4px solid #f9c116;
    border-radius: 50%;
    border-top: 4px solid rgb(78, 150, 247);
    border-right: 4px solid #94c43e;
    border-bottom: 4px solid #ed1e79;
    width: 1rem;
    height: 1rem;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Button;
