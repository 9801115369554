import React from "react";
import Video from "./HeroVideo";

function Header() {
  return (
    <>
      <header className="header">
        <div className="header__container">
          <Video />
        </div>
      </header>
    </>
  );
}

export default Header;
