import log from "loglevel";
import React, { useEffect, useRef, useState } from "react";
import RegisterForm from "../../components/auth/InputForm";
import axios from "axios";
import { Schema, Alert } from "rsuite";
import { useAuth } from "../../ecwid/context/auth_context";
import { useHistory } from "react-router-dom";
import Footer from "../../components/Footer";
import Navigation from "../../components/Navigation";
import { checkout } from "../../helpers/helper";

const { StringType } = Schema.Types;

const initialForm = {
  name: "",
  email: "",
  password: "",
};

const registerModel = Schema.Model({
  name: StringType()
    .minLength(3, "Username cannot be less than 3 characters")
    .maxLength(19, "Username cannot be greater than 19 characters")
    .isRequired("User name is required"),
  email: StringType()
    .isEmail("Please enter the correct email")
    .isRequired("This field is required"),
  password: StringType()
    .minLength(6, "The field cannot be less than 6 characters")
    .maxLength(25, "The field cannot be greater than 25 characters")
    .isRequired("This field is required"),
});

const baseName = process.env.REACT_APP_BASENAME;

const Register = () => {
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(initialForm);
  const [acceptMailing, setAcceptMailing] = useState(false);

  //scroll to top incase user has come from footer
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //location to redirect after user logs in
  let history = useHistory();
  log.debug("redirect to", history?.location?.state);
  let redirectTo = history?.location?.state
    ? history?.location?.state
    : "confirmemail";

  log.debug("redirectTo in Register.js", redirectTo);

  // alert(from);
  const { loginUser, currentUser } = useAuth();

  //checks if user is already logged in to avoid navigating to this page from url
  if (currentUser?.uid && currentUser?.name && currentUser?.email) {
    if (redirectTo) {
      history.push(`${baseName}/${redirectTo}`, redirectTo);
    } else {
      history.push(`${baseName}/"vip`, "vip");
    }
  }

  const onFormSubmit = async () => {
    setIsLoading(true);
    if (!formRef.current.check()) {
      setIsLoading(false);
      alert("Wrong details");
      return;
    }

    // let isOk = false;
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/register_user`,
        { ...formValue, acceptMarketing: acceptMailing }
      );
      log.debug("resp.data in register", resp.data);
      if (resp.status === 200) {
        const authToken = resp.data.token;
        // const sso_token = resp.data.sso_token;

        // save user and token to local storage
        window.localStorage.setItem("auth_token", JSON.stringify(authToken));

        await loginUser(
          resp?.data?.uid || "",
          resp?.data?.name || "",
          resp?.data?.email || "",
          resp?.data?.firebase_token || "",
          resp?.data?.ecwid_token || "",
          resp?.data?.shippingAdresses || "",
          resp?.data?.loyalty_points,
          resp?.data?.is_admin || false,
          resp?.data?.is_staff || false,
          resp?.data?.email_verified
        );

        //log ananlytic event
        if (currentUser) {
          log.debug(currentUser);
          currentUser?.analytics?.logEvent("sign_up");
        }

        // send analytics

        //user is allowed to place order with unverified email
        let redirectUrl = window.localStorage.getItem("redirectUrl");
        log.debug("redirectUrl in login user", redirectUrl);

        if (redirectUrl) {
          redirectUrl = JSON.parse(redirectUrl);
          history.push({
            pathname: redirectUrl,
          });
          window.localStorage.removeItem("redirectUrl");
          checkout();
        }

        window.gtag("event", "succesfull_register	", {
          event_category: "auth",
          event_label: "method",
        });

        //show success Alert
        Alert.success(`Succesfully added ${formValue.name} to our community`);
        //by default if no history push to myip
        //set loading state
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      window.gtag("event", "failed_register	", {
        event_category: "auth",
        event_label: "method",
      });
      log.debug(error);
      log.debug(error.response);
      Alert.error(
        "The user with the provided email already exists, or something went wrong"
      );
    }
  };
  return (
    <>
      <Navigation />
      <RegisterForm
        formType="Register"
        onFormSubmit={onFormSubmit}
        registerModel={registerModel}
        formRef={formRef}
        isLoading={isLoading}
        formValue={formValue}
        setFormValue={setFormValue}
        initialForm={initialForm}
        isLoadingText="Adding you to the naked coffee community..."
        fromState={redirectTo}
        history={history}
        setAcceptMailing={setAcceptMailing}
        acceptMailing={acceptMailing}
      />
      <Footer />
    </>
  );
};

export default Register;
