import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import { colors } from "../constants/constants";
import HeaderComp from "../components/HeaderComp";
import DescrComp from "../components/DescrComp";
import DrinksMenu from "../../resources/img/sotonUni/soton-drinks-menu.png";
import CoffeePouring from "../../resources/img/sotonUni/coffee-pouring.png";
import Milk from "../../resources/img/sotonUni/milk.png";
import Smoothie from "../../resources/img/sotonUni/smoothie.png";
import SmoothieMenu from "../../resources/img/sotonUni/smoothie-menu.png";

const OurOfferings = () => {
  return (
    <Wrapper style={{ backgroundColor: colors.tertiaryColor }}>
      <div className="header-about">
        <HeaderComp
          backgColor={colors.primaryColor}
          text="From locally sourcing to in house bakers. We ensure our Coffee, drinks &
          food is as fresh as it gets."
          title="Our Offerings"
        />
      </div>

      <div>
        <DescrComp
          backgColor={colors.primaryColor}
          title="Catering for all"
          text="You learn at an early age that everyone has thier own identity. This follows us into our drinking habits, tastes and styles.
          We felt identity was so important that we moulded our offerings around this.
          We did this by offering three main roasts, each one explosively different from the other. 
          We then expressed this ethos into our food. We wanted to ensure that we could give options to ALL. whether you are
          gluten free, vegan, vegetarian, or even a lover of meat we're the place to be."
        />

        <DescrComp
          backgColor={colors.primaryColor}
          title="Drinks"
          text="We offer our three unique roasts with any coffee style you desire. Complemented by a variety of alternative milks, whilst partnering with
          the Organic UK Sharpham park farm to bring a spelt milk to the coffee market & even using 100% vegan Syrups supplied
          from a recent UK start up company called Sweet Bird."
        />
      </div>

      <div className="img-container">
        <div className="big-img">
          <ScrollAnimation animateIn="fadeIn" offset={300}>
            <img src={DrinksMenu} alt="drinks menu" />
          </ScrollAnimation>
        </div>

        <div className="three-img-container">
          <ScrollAnimation animateIn="bounceInLeft" offset={300}>
            <img src={CoffeePouring} alt="coffee pouring" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceInUp" offset={300}>
            <img src={Milk} alt="organic milk" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceInRight" offset={300}>
            <img src={Smoothie} alt="smoothie" />
          </ScrollAnimation>
        </div>
      </div>

      <DescrComp
        className="smoothie-descr"
        includeTitle={false}
        text="We use 100% fruit with Ice in our Smoothies & we created our own home made 'super food' range, bringing a
        healthy & exciting new range to the market"
      />

      <div className="big-img" style={{ marginTop: "4vw" }}>
        <ScrollAnimation animateIn="fadeInUp" offset={300}>
          <img src={SmoothieMenu} alt="drinks menu" />
        </ScrollAnimation>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
  color: white;
  overflow: hidden;

  /* display: grid;
  grid-template-rows: 1fr 4fr; */

  .img-container {
    height: 100vw;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 1rem;

    @media screen and (max-width: 600px) {
      grid-template-rows: 1fr 4fr;
      height: auto;
    }
  }

  .three-img-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    @media screen and (max-width: 600px) {
      grid-template-columns: none;
      grid-template-rows: repeat(3, 1fr);
      grid-row-gap: 1rem;
      width: 100%;
      margin: 0 auto;
    }

    img {
      width: 100%;
    }
  }

  .smoothie-descr {
    @media screen and (max-width: 600px) {
      margin: 1rem 0;
    }
  }
`;

export default OurOfferings;
