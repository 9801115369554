import React from "react";
import Landing from "./pages/Landing";
import About from "./pages/About";
import OurOfferings from "./pages/OurOfferings";
import LooseLeaf from "./pages/LooseLeaf";
import Food from "./pages/Food";
import Ethical from "./pages/Ethical";
import WhyUs from "./pages/WhyUs";
import MobilisationPlan from "./pages/MobilisationPlan";

const SotonUni = () => {
  return (
    <>
      <Landing />
      <About />
      <OurOfferings />
      <LooseLeaf />
      <Food />
      <Ethical />
      <WhyUs />
      <MobilisationPlan />
    </>
  );
};

export default SotonUni;
