import log from "loglevel";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Button } from "rsuite";
import { useState } from "react";
import axios from "axios";
// import { Stores, storeLocationData } from "../../../data/store-location-data";
import Loading from "../../../components/Loading";
import GoBackBtn from "../../../components/GoBackBtn";
import { useEffect } from "react";

const baseName = process.env.REACT_APP_BASENAME;

const SalesReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState({
    orders: null,
  });
  let history = useHistory();

  const goBack = () => {
    history.push(`${baseName}/mystore/selectsales`);
  };

  useEffect(() => {
    //this is to get rid of warnings
    setIsLoading(false);
    setOrders({ orders: null });
  }, []);

  const fetchStoreOrders = async () => {
    setIsLoading(true);
    try {
      const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/get_store_orders`,
        {
          token: authToken,
          store: "online_store",
        }
      );
      log.debug(resp);
      if (resp.status === 200) {
        setOrders((oldS) => ({ ...oldS, orders: Object.values(resp.data) }));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      log.debug(error);
    }
  };

  const calcTotalTax = () => {
    let totalTax = 0;
    for (let i = 0; i < orders.orders.length; i++) {
      let currOrder = orders.orders[i];
      totalTax = totalTax + currOrder.tax;
    }
    return totalTax.toFixed(2);
  };

  log.debug(orders);
  return (
    <>
      {isLoading && (
        <div className="centre-blur">
          <Loading />
        </div>
      )}
      <Wrapper>
        <GoBackBtn goBack={goBack} />
        <div className="wrapper">
          <div className="header-orders">
            <h1>Your Online sales</h1>
            <Button onClick={fetchStoreOrders} appearance="ghost">
              Fetch SalesReport
            </Button>
          </div>

          <div className="orders-data-container">
            <div className="orders-controls">No controls</div>
            <div className="data-container">
              {orders.orders ? (
                <>
                  <p>total tax: £{calcTotalTax()}</p>
                  <p>Number of sales: {orders.orders.length}</p>
                </>
              ) : (
                <p>will display data here</p>
              )}
            </div>

            <div></div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  height: auto;
  position: relative;

  .go-back-btn {
    top: 2rem;
  }

  .header-orders {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 20vh;
    h1 {
      font-family: "Roaster Original";
      text-transform: lowercase;
    }
  }
  .wrapper {
    height: 100vh;
    display: grid;
    grid-template-rows: 1fr 3fr;
  }

  .orders-data-container {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }

  .data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .orders-controls {
    padding-left: 2rem;
  }

  .order-item {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5vw;
    width: 50vw;
    height: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.15);
  }
  .order-item-single-header {
    font-family: "Roaster original";
    text-transform: lowercase;
  }
`;

export default SalesReport;
