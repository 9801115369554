import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import { colors } from "../constants/constants";
import HeaderComp from "../components/HeaderComp";
import EcoMask from "../../resources/img/main-mask.png";
import DescrComp from "../components/DescrComp";
import EthicalShirt from "../../resources/img/sotonUni/ethical-shirt.png";
import ReactPlayer from "react-player/youtube";
import YCTimg from "../../resources/img/sotonUni/youth-cancer-trust.png";
import YCT2img from "../../resources/img/sotonUni/youth-cancer-trust-2.png";
import Ethical1 from "../../resources/img/sotonUni/ethical-1.png";
import Ethical2 from "../../resources/img/sotonUni/ethical-2.png";

const Ethical = () => {
  return (
    <Wrapper style={{ backgroundColor: colors.quartenaryColor }}>
      <HeaderComp
        title="Our Ethical & Green credentials"
        text=" Becoming one of the first carbon possitive Coffee roasters on the Planet was an epic feat, but this was just the start of
          the Naked venture."
        backgColor={colors.primaryColor}
      />
      <div className="ethical-mask-img">
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={EcoMask} alt="eco mask" />
        </ScrollAnimation>
      </div>
      <DescrComp
        includeTitle={false}
        text="Our next goal was to move towards a more sustainable experience. 
        We took the next step of changing our packaging to compostable where possible and introduced 
        ‘Compostable bamboo’ reusable cups which was at double the cost of our competitors but a step we felt needed.
        We soon learned that this was not a  fully sustainable practice & instead looked at implementing a
        stage by stage process. This was a hard decision but one that ensured we were able to fight another day in global 
        sustainability.  
        We altered our stratergy aiming toward the achievables first. We switched our energy to renewable in the stores that
        we had the ability to do, followed by signing up to green aliance projects supporting ethical charities
        like ‘bottletop’ who support work projects in poverty sticken countries. We then focused on supporting local needs
        like YCT (youth cancer trust) who we are now Patrons for
        "
      />

      <div className="two-row-container-yct">
        <ScrollAnimation animateIn="bounceInLeft" offset={300}>
          <img src={YCTimg} alt="YCTimg" />
        </ScrollAnimation>

        <ScrollAnimation animateIn="bounceInRight" offset={300}>
          <img src={YCT2img} alt="YCTimg" />
        </ScrollAnimation>
      </div>

      <DescrComp
        includeTitle={false}
        text="We leant to share our beliefs of sustainability & ethicality in a more harmonious way, 
one that gave us forfilment/meaning in our mission but also alowing us to still plan for a bigger platform. 
Our co founder ‘Ian Saul’ has always strived for a realistic long term goal partnered with short term direction.
Why not check out the link below to understand a little more about us and what we do.
        "
      />

      <div className="center-yt">
        <ReactPlayer url="https://youtu.be/bvi-ZIq_-nk" />
      </div>

      <DescrComp
        includeTitle={false}
        text="
        We are proud to boast that we smashed our 24 month Carbon offset goal within the first 6 months of 2021."
      />

      <div className="two-row-container mg">
        <ScrollAnimation animateIn="bounceInLeft" offset={300}>
          <img src={Ethical1} alt="Ethical1" />
        </ScrollAnimation>

        <ScrollAnimation animateIn="bounceInRight" offset={300}>
          <img src={Ethical2} alt="Ethical2" />
        </ScrollAnimation>
      </div>

      <DescrComp
        includeTitle={false}
        text="
        We did this by switching our delivery vehicles to electric, ensuring every food packet we were able to 
        provide was compostable and by commiting to ensuring ALL of our new equipment was made from at least
        80% recycled materials.

        We spent much time & effort into understanding sustainable supply in a commercial enviroment. 
        We found that it is not possible to be 100% green whilst dealing with large scale supply but our aproach does mean
        we can start the process to ensuring we can educate."
      />

      <HeaderComp
        title="Together we can heal the world, one cup at a time"
        text=""
        backgColor={colors.primaryColor}
      />
      <div className="ethical-mask-img">
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={EthicalShirt} alt="eco mask" />
        </ScrollAnimation>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
  overflow: hidden;

  .ethical-mask-img {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 30rem;
      @media screen and (max-width: 600px) {
        height: 15rem;
      }
    }
  }

  .two-row-container-yct {
    width: calc(100vw - 4rem);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;

    @media screen and (max-width: 600px) {
      grid-template-columns: none;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 1rem;
    }
    div {
      width: 100%;
    }
    img {
      height: 25rem;
      width: 100%;
    }
  }

  .two-row-container-mask {
    width: calc(100vw - 4rem);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    div {
      width: 100%;
    }
    img {
      height: 25rem;
    }
  }
  .center-yt {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
  }
  .mg {
    margin: 2rem 0;
  }
`;

export default Ethical;
