import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Button, InputPicker } from "rsuite";
import styled from "styled-components";
import { storeLocationData } from "../../data/store-location-data";
const baseName = process.env.REACT_APP_BASENAME;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SelectTable = () => {
  const [selectedStoreData, setSelectedStoreData] = useState({
    instore_table: null,
    instore_store: null,
  });

  let history = useHistory();
  let query = useQuery();

  let qr_table = query.get("instore_table");
  let qr_store = query.get("instore_store");

  useEffect(() => {
    if (qr_table && qr_store) {
      window.sessionStorage.setItem("instore_table", qr_table);
      window.sessionStorage.setItem("instore_store", qr_store);

      setSelectedStoreData(() => ({
        instore_table: qr_table,
        instore_store: qr_store,
      }));
    }
    // eslint-disable-next-line
  }, []);

  const onTableDataChange = (item) => {
    if (item === "table-1" || item === "table-2" || item === "table-3") {
      setSelectedStoreData((oldS) => {
        return { ...oldS, instore_table: item };
      });

      window.ec.order.extraFields.select_table = {
        title: "Select Table:",
        type: "select",
        required: true,
        options: [
          {
            title: item,
          },
        ],
        value: item, // Default value
        checkoutDisplaySection: "payment_details",
      };
      window.Ecwid.refreshConfig();
      window.sessionStorage.setItem("instore_table", item);
    } else {
      window.sessionStorage.removeItem("instore_table");

      setSelectedStoreData((oldS) => {
        return { ...oldS, instore_table: null };
      });
    }
  };

  const onStoreDataChange = (item) => {
    if (item === "store-1" || item === "store-2" || item === "store-3") {
      setSelectedStoreData((oldS) => {
        return { ...oldS, instore_store: item };
      });

      window.ec.order.extraFields.select_store = {
        title: "Select Store:",
        type: "select",
        required: true,
        options: [
          {
            title: item,
          },
        ],
        value: item, // Default value
        checkoutDisplaySection: "payment_details",
      };
      window.Ecwid.refreshConfig();
      window.sessionStorage.setItem("instore_store", item);
    } else {
      window.sessionStorage.removeItem("instore_store");

      setSelectedStoreData((oldS) => {
        return { ...oldS, instore_store: null };
      });
    }
  };

  return (
    <Wrapper>
      <div>
        <h2 className="menu-title ">
          Please select the table you are currently sat at
        </h2>
      </div>
      <div className="input-container">
        {selectedStoreData.instore_store && (
          <p>
            Welcome to{" "}
            {
              storeLocationData[
                storeLocationData.findIndex((currentValue, index, arr) => {
                  if (currentValue.value === selectedStoreData.instore_store) {
                    return currentValue;
                  } else {
                    return null;
                  }
                })
              ].label
            }
          </p>
        )}

        <InputPicker
          data={storeLocationData}
          placeholder="select store"
          defaultValue={qr_store || ""}
          onChange={(item) => onStoreDataChange(item)}
          style={{ width: 224 }}
        />
        {selectedStoreData.instore_store && (
          <p>You are at Table {selectedStoreData.instore_table}</p>
        )}

        <InputPicker
          data={[
            {
              label: "Table 1",
              value: "table-1",
              // role: "Master",
            },
            {
              label: "Table 2",
              value: "table-2",
              // role: "Master",
            },
            {
              label: "Table 3",
              value: "table-3",
              // role: "Master",
            },
          ]}
          placeholder="select table number"
          defaultValue={qr_table || ""}
          onChange={(item) => onTableDataChange(item)}
          style={{ width: 224 }}
        />
        <Button
          appearance="ghost"
          onClick={() => history.push(`${baseName}/instore/menu`)}>
          Menu
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  height: 100vh;
  margin: 0 5vw;
  grid-template-rows: 1fr 3fr;

  div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }
`;

export default SelectTable;
