import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Progress } from "rsuite";
import UpdateBtn from "../../../inStoreOrdering/admin/components/UpdateBtn";

const { Circle } = Progress;

const SingleOrders = ({ order, setIsModalOpen, isModalOpen }) => {
  const [timeLeft, setTimeLeft] = useState(
    //prettier-ignore
    (Math.round(new Date(order.createDate).getTime() / 1000) +
      300) -
      (Math.round(new Date().getTime() / 1000))
  );
  const [backgroundColor, setBackgroundColor] = useState(
    timeLeft < 0
      ? "124,252,0"
      : timeLeft > 0 && timeLeft < 150
      ? "255, 0, 0"
      : "255,255,0"
  );
  const [progressState, setProgressState] = useState({
    status:
      order.fulfillmentStatus === "WILL_NOT_DELIVER" ||
      order.fulfillmentStatus === "RETURNED"
        ? "fail"
        : order.fulfillmentStatus === "DELIVERED" ||
          order.fulfillmentStatus === "READY_FOR_PICKUP"
        ? "success"
        : null,
    percent:
      order.fulfillmentStatus === "WILL_NOT_DELIVER"
        ? 0
        : order.fulfillmentStatus === "RETURNED"
        ? 1
        : order.fulfillmentStatus === "AWAITING_PROCESSING"
        ? 25
        : order.fulfillmentStatus === "PROCESSING"
        ? 50
        : order.fulfillmentStatus === "SHIPPED" ||
          order.fulfillmentStatus === "OUT_FOR_DELIVERY"
        ? 75
        : order.fulfillmentStatus === "DELIVERED" ||
          order.fulfillmentStatus === "READY_FOR_PICKUP"
        ? 100
        : 0,
    color:
      order.fulfillmentStatus === "AWAITING_PROCESSING"
        ? "#F15A24"
        : order.fulfillmentStatus === "WILL_NOT_DELIVER" ||
          order.fulfillmentStatus === "RETURNED"
        ? "blue"
        : order.fulfillmentStatus === "PROCESSING"
        ? "blue"
        : order.fulfillmentStatus === "SHIPPED" ||
          order.fulfillmentStatus === "OUT_FOR_DELIVERY"
        ? "rgb(243, 236, 14)"
        : order.fulfillmentStatus === "DELIVERED" ||
          order.fulfillmentStatus === "READY_FOR_PICKUP" ||
          order.fulfillmentStatus === "RETURNED"
        ? "green"
        : "blue",
  });

  function seconds2time(seconds) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - hours * 3600) / 60);
    let newSeconds = seconds - hours * 3600 - minutes * 60;
    let time = "";

    if (hours !== 0) {
      time = hours + ":";
    }
    if (minutes !== 0 || time !== "") {
      minutes = minutes < 10 && time !== "" ? "0" + minutes : String(minutes);
      time += minutes + ":";
    }
    if (time === "") {
      time = newSeconds + "";
    } else {
      time += newSeconds < 10 ? "0" + newSeconds : String(newSeconds);
    }
    return time;
  }

  const openModal = () => {
    setIsModalOpen((oldS) => ({
      ...oldS,
      open: true,
      orderData: order,
      progressState: progressState,
      setProgressState: setProgressState,
    }));
  };

  useEffect(() => {
    let timer1 =
      timeLeft >= 0 &&
      setTimeout(() => {
        setTimeLeft(timeLeft - 1);
        timeLeft < 0
          ? setBackgroundColor("124,252,0")
          : timeLeft > 0 && timeLeft < 150
          ? setBackgroundColor("255, 0, 0")
          : timeLeft === 0 && setBackgroundColor("124,252,0");
      }, 1000);

    return () => {
      clearTimeout(timer1);
    };
  }, [timeLeft]);

  return (
    <>
      <Wrapper style={{ backgroundColor: `rgba(${backgroundColor}, 0.4)` }}>
        <p className="order-timer" onClick={openModal}>
          {timeLeft <= 0 ? "Completed" : seconds2time(timeLeft)}
        </p>

        <Circle
          onClick={openModal}
          style={{ width: "7rem", margin: "0 auto", paddingTop: "1rem" }}
          showInfo={true}
          strokeLinecap="round"
          percent={progressState.percent}
          strokeColor={progressState.color}
          status={progressState.status}
        />

        <div className="order-item" onClick={openModal}>
          <div className="single-order-item">
            <h4>Order ID:</h4>
            <p>{`#${order.id}`}</p>
            {/* <hr /> */}
          </div>
          {/* <div className="single-order-item">
            <h4>Customer email:</h4>
            <p>{order.email}</p>
            <hr />
          </div> */}
          {/* <div className="single-order-item">
            <h4>Order ID:</h4>
            <p>{`#${order.id}`}</p>
            <hr />
          </div> */}
          <div className="single-order-item">
            <h4>Order Items:</h4>
            <div className="order-items">
              {order?.items?.map((item) => {
                return (
                  <span key={item.id}>
                    {item.name} x{item.quantity}
                  </span>
                );
              })}
            </div>
            {/* <hr /> */}
          </div>
          {/* <div className="single-order-item">
            <h4>Payment Method</h4>
            <p>{order.paymentMethod}</p>
            {/* <hr /> 
            </div> */}
          <div className="single-order-item">
            <h4>Table</h4>
            <p>
              {order.extraFields.select_table
                ? order.extraFields.select_table
                : "unknown"}
            </p>
          </div>
        </div>
        <div className="btn-container">
          <UpdateBtn
            order={order}
            progressState={progressState}
            setProgressState={setProgressState}
          />
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 1rem 0;
  overflow-y: scroll;
  width: 18vw;
  font-family: "Roaster Original";
  text-transform: lowercase;
  margin: 1rem 0;
  border-radius: 1rem;
  display: grid;
  height: 90vh;
  grid-template-rows: 1fr 1fr 1fr;
  border: 0.5px solid blue;

  @media (max-width: 900px) {
    width: 45vw;
    margin: 1vw 0;
    padding: 1vw 0;
  }

  @media (max-width: 600px) {
    width: 90vw;
    margin: 1rem 0;
  }

  .singleOrder-h4 {
    font-family: "Roaster Original";
    text-transform: lowercase;
  }

  .btn-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .order-item {
    font-size: 0.6rem;
    display: grid;
    grid-template-rows: 1fr 1.5fr 1fr;
    /* border: 1px solid white; */
  }

  .single-order-item {
    height: 3rem;
    text-align: center;

    h4 {
      font-size: 0.8rem;
      font-family: "Roaster Original";
      text-transform: lowercase;
    }
    p {
      font-size: 0.6rem;
    }
  }

  .admin-btn {
    display: flex;
    align-self: center;
    justify-self: center;
    width: 95%;
    font-size: 0.6rem;
    text-transform: lowercase;
    @media (max-width: 900px) {
      margin: 5px;
    }
  }

  .order-items {
    text-align: center;
    overflow-y: scroll;
    height: inherit;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    h4 {
      font-size: 0.8rem;
    }
  }

  .order-timer {
    position: absolute;
    top: 1%;
    left: 3%;
  }
`;

export default SingleOrders;
