import React from "react";
import styled from "styled-components";

const MenuItem = ({ img, name, height, textColor, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <div>
        <h1 className="menu-title" style={{ color: textColor }}>
          {name}
        </h1>
      </div>
      <div>
        <img
          className="menu-img"
          src={img}
          alt={name}
          style={{ height: height }}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 4fr;
  transition: all 0.3s;
  &:hover {
    transform: scale(0.9);
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-img {
    @media screen and (max-width: 1200px) {
      height: 10rem !important;
    }
  }
`;

export default MenuItem;
