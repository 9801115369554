import log from "loglevel";
import { Alert } from "rsuite";
const manualTaxRates = {
  228864882: 0,
  1702438653: 20,
};

export const initialProductOptions = (product) => {
  //passed in product from useProductState
  const initialProductOptionsObject = {};

  for (const ecwidOptionObject of product.options) {
    // log.debug("these are ecwid options");

    // Found an option with at least one choice
    if (ecwidOptionObject.choices?.length > 0) {
      const choices = ecwidOptionObject.choices;
      const choiceObject = {};

      for (let choice of choices) {
        // log.debug("......found choice " + choice.text);
        choiceObject[choice.text] = {
          priceModifier: choice.priceModifier,
          priceModifierType: choice.priceModifierType,
        };
      }

      initialProductOptionsObject[ecwidOptionObject.name] = {
        selectedChoiceName: choices[ecwidOptionObject.defaultChoice].text,
        choices: choiceObject,
      };
    }
  }
  return initialProductOptionsObject;
};

export const calculateTaxRate = (tax) => {
  var totalTaxRate = 0;

  // log.debug("Calculating tax rate for tax object ", tax);

  if (tax.taxable) {
    // log.debug("Is taxable");

    if ("enabledManualTaxes" in tax && tax.enabledManualTaxes.length > 0) {
      //log.debug("Has manual taxes");
      for (const taxId of tax.enabledManualTaxes) {
        //log.debug("Found manual tax with id ", taxId);

        if (taxId in manualTaxRates) {
          let taxRate = manualTaxRates[taxId];
          // log.debug(
          //   "Manual tax rate",
          //   taxId,
          //   "found matching manual tax rate of",
          //   taxRate
          // );
          totalTaxRate += taxRate;
        } else {
          console.error("Can not find manual tax rate", taxId);
        }
      }
    } else if ("defaultLocationIncludedTaxRate" in tax) {
      totalTaxRate = tax.defaultLocationIncludedTaxRate;
      // log.debug("Using default location tax rate ", totalTaxRate);
    }
  }
  return totalTaxRate;
};

export const calculatePrice = (
  basePrice,
  optionsObject,
  variation,
  taxRate
) => {
  let calculatedPrice =
    variation && "price" in variation ? variation.price : basePrice;

  //log.debug("optionsObject");
  //log.debug(optionsObject);

  for (const optionObject of Object.values(optionsObject)) {
    //log.debug("Option object");
    //log.debug(optionObject);
    const selectedChoiceName = optionObject.selectedChoiceName;
    const choicesObject = optionObject.choices;
    //log.debug("choicesObject");
    //log.debug(choicesObject);
    const priceModifierType =
      choicesObject[selectedChoiceName].priceModifierType;
    const priceModifier = choicesObject[selectedChoiceName].priceModifier;

    switch (priceModifierType) {
      case "ABSOLUTE":
        calculatedPrice += priceModifier;
        break;
      case "PERCENT":
        calculatedPrice += (basePrice * priceModifier) / 100;
        break;
      default:
        // log.debug("Unrecognised priceModiferType " + priceModifierType);
        throw new Error();
    }
  }

  // 29-Dec-2021: ECWID options changed so that product
  // prices include tax therefore taxRate for final price
  // calculation is 0.  ECWID will automatically handle tax
  // breakdown in cart
  taxRate = 0;

  return (calculatedPrice * ((100 + taxRate) / 100)).toFixed(2);
};

export const determineStock = (
  productEnabled,
  productVariation,
  productInStock
) => {
  if (productEnabled) {
    if (productVariation && "inStock" in productVariation) {
      return productVariation.inStock;
    } else {
      return productInStock;
    }
  } else {
    return "false";
  }
};

export const findMatchingVariation = (options, variations) => {
  if (variations) {
    for (let variation of variations) {
      let variationOptionTotalCount = 0;
      let variationOptionMatchCount = 0;

      for (let variationOption of variation.options) {
        variationOptionTotalCount++;
        const optionName = variationOption.name;
        const optionValue = variationOption.value;

        log.debug(
          "   variation has option " + optionName + " with value " + optionValue
        );

        log.debug(options[optionName].selectedChoiceName);
        log.debug(optionName);
        log.debug(options);

        // See whether this matches currently selected
        // value for that option
        if (
          optionName in options &&
          options[optionName].selectedChoiceName === optionValue
        ) {
          variationOptionMatchCount++;
        }
      }

      if (
        variationOptionTotalCount > 0 &&
        variationOptionTotalCount === variationOptionMatchCount
      ) {
        log.debug("Selected options match this variation - hooray!!!!!");
        log.debug(variation);
        return variation;
      }
    }
  }
  return undefined;
};

export const sendVerificationEmail = (
  currentUser,
  fromPageUrl = window.location.href
) => {
  log.debug(currentUser);
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: fromPageUrl,
    // This must be true.
    handleCodeInApp: true,
  };
  currentUser.auth.currentUser
    .sendEmailVerification(actionCodeSettings)
    .then(() => {
      // Email verification sent!
      // ...
      Alert.success("Succesfully sent email reset link");
    })
    .catch((err) => {
      log.debug(err);
      log.debug(err.message);
      if (err?.code === "auth/user-token-expired") {
        window.location.href = "http://localhost:3000/marcoswebreact/login";
      } else if (err?.code === "auth/too-many-requests") {
        Alert.error("You have made too many requests, Please try again later");
      } else {
        Alert.error("Something went wrong");
      }
    });
};
