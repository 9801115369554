import React from "react";
import { Button } from "rsuite";

const GoBackBtn = ({ goBack }) => {
  return (
    <Button className="go-back-btn" onClick={goBack}>
      &larr; go back
    </Button>
  );
};

export default GoBackBtn;
