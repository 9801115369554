import log from 'loglevel';
import React, { useState, useEffect, useRef } from "react";

import video from "../resources/img/newWebm2.webm";
import videoH from "../resources/img/newHvec2.mov";
import fallBack from "../resources/img/fallbackImg.png";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

export default function App() {
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      log.debug("We are Safari");
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        log.debug("Found reference for player");
        log.debug(player);
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            log.debug("Got a promise");
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }

    const isFirefox = typeof InstallTrigger !== "undefined";
    if (isFirefox) {
      setShouldUseImage(true);
    }
  }, []);

  return shouldUseImage ? (
    <img src={fallBack} alt="logoImage" className="header__image" />
  ) : (
    <div
      ref={videoParentRef}
      dangerouslySetInnerHTML={{
        __html: `
        <video
          muted
          autoplay
          playsinline
          preload="metadata"
          class="header__container"
        >
        <source src=${videoH} type="video/quicktime"  />
        <source src=${video} type="video/webm"  />
        <img src=${fallBack} alt="logoImage" class="header__image" />
        </video>`,
      }}
    />
  );
}

// function HeroVideo() {
//   return (
//     <>
//       <video autoPlay muted playsInline className="header__container-video">
//         <source src={videoH} type="video/quicktime" />
//         <source src={video} type="video/webm" />
//         <img src={fallBack} alt="logoImage" />
//       </video>
//     </>
//   );
// }

// export default HeroVideo;
