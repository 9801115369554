import React from "react";
import styled from "styled-components";
import { GiMeal } from "react-icons/gi";
import { MdLocalDrink } from "react-icons/md";
import AdminNav from "../../../components/AdminNav";
import Button from "../../../components/Button";

import { useHistory } from "react-router";
import GoBackBtn from "../../../components/GoBackBtn";

const baseName = process.env.REACT_APP_BASENAME;

const DrinksOrFoodSelect = () => {
  let history = useHistory();
  let store = history.location.state;

  const onSelect = (foodOrDrink) => {
    history.push(`${baseName}/mystore/instoreorders/${store}/${foodOrDrink}`);
  };

  const goBack = () => {
    history.push(`${baseName}/mystore`);
  };

  return (
    <>
      <GoBackBtn goBack={goBack} />
      <Wrapper>
        <div className="admin-card">
          <div className="btn-admin-container">
            <GiMeal className="icon-admin" />
            <Button
              appearance="secondary"
              onClick={() => onSelect("food")}
              style={{ width: "11rem" }}>
              Food
            </Button>
          </div>
        </div>
        <div className="admin-card">
          <div className="btn-admin-container">
            <MdLocalDrink className="icon-admin" />
            <Button
              appearance="secondary"
              onClick={() => onSelect("drinks")}
              style={{ width: "11rem" }}>
              Drinks
            </Button>
          </div>
        </div>
      </Wrapper>
      <AdminNav style={{ top: "90vh" }} />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  /* width: 100vw; */
  padding: 10vh 0 10vh 0;
  height: 100vh;
  font-family: "Roaster original";
  text-transform: lowercase;

  button {
    border: none !important;
    color: white !important;
  }

  .admin-card {
    height: 35vh;
    width: 30vw;
    background-color: rgba(14, 114, 185, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    box-shadow: -3px 4px 5px 2px rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
  }

  .btn-admin-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 70%;
    width: 50%;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-family: "Roaster original";
    text-transform: lowercase;
    margin-bottom: 2rem;
    text-shadow: 2px 2px #f9c116;
    font-size: 2.7rem;
    @media (max-width: 900px) {
      font-size: 2.3rem;
    }
  }

  .store-dist {
    font-size: 1rem;
  }

  .icon-admin {
    font-size: 3.5rem;
    color: white;
  }
`;

export default DrinksOrFoodSelect;
