import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

const SppechComp = ({ avatar, descrComponent }) => {
  return (
    <Wrapper>
      <div className="img-comp-container">
        <ScrollAnimation animateIn="bounceInLeft" offset={300}>
          <img src={avatar} alt="avatar" />
        </ScrollAnimation>
      </div>
      <div className="descr-comp-container">{descrComponent}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr;

  @media screen and (max-width: 1200px) {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
  }

  margin: 2rem 0;

  img {
    height: 15rem;
    @media screen and (max-width: 600px) {
      height: 50vw;
    }
  }

  .img-comp-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .descr-comp-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    // layout
    position: relative;
    max-width: 30em;

    // looks
    background-color: #fff;
    padding: 1.125em 1.5em;
    font-size: 1.25em;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
      0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
  }

  p::before {
    // layout
    content: "";
    position: absolute;
    width: 0;
    top: 50%;
    height: 0;
    bottom: 100%;
    transform: rotate(-90deg);
    left: -0.75em; // offset should move with padding of parent
    border: 0.75rem solid transparent;
    border-top: none;

    @media screen and (max-width: 1200px) {
      // layout
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: -0.75rem;
      bottom: 100%;
      left: 50%; // offset should move with padding of parent
      border: 0.75rem solid transparent;
      border-top: none;
      transform: none;
      // looks
      border-bottom-color: #fff;
      filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
    }

    // looks
    border-bottom-color: #fff;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }
`;

export default SppechComp;
