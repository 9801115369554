import React from "react";
import Typewriter from "typewriter-effect";
import styled from "styled-components";

const AnimatedText = ({ text, link }) => {
  return (
    <Wrapper>
      <Typewriter
        options={{
          delay: 33,
          skipAddStyles: true,
        }}
        onInit={(typewriter) => {
          typewriter.typeString(`<a href=${link}>${text}</a>`).start();
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: left;
  font-family: "Roaster Original";
  font-size: 1rem;
`;

export default AnimatedText;
