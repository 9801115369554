import log from "loglevel";
import React, { useState } from "react";
import styled from "styled-components";
import {
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Button,
  Alert,
} from "rsuite";
import { useHistory } from "react-router";
import passwordHash from "password-hash";

const baseName = process.env.REACT_APP_BASENAME;

const EnterDiscount = ({ SetEnteredCode }) => {
  const [formValue, setFormValue] = useState({});

  let history = useHistory();

  const showDiscounts = () => {
    if (formValue.code.length === 0) {
      Alert.error("Please enter a code");
    } else {
      log.debug(formValue.code);
      let hashedPassword = passwordHash.generate(formValue.code);
      log.debug("hashedPassword:", hashedPassword);

      window.sessionStorage.setItem("disc_p", JSON.stringify(hashedPassword));
      SetEnteredCode(formValue.code);
    }
  };

  log.debug(formValue);
  return (
    <Wrapper>
      <Button
        className="go-back"
        appearance="default"
        style={{ textTransform: "lowercase" }}
        onClick={() => history.push(`${baseName}/myvip`)}>
        &larr; Go Back
      </Button>
      <div className="header-container">
        <h2>Enter Discount code</h2>
      </div>
      <div className="form-container">
        <Form
          layout="vertical"
          
          onChange={(val) => setFormValue(val)}
          formValue={formValue}>
          <FormGroup>
            <ControlLabel>Code</ControlLabel>
            <FormControl name="code" className="form-control-make-admin" />
            <HelpBlock tooltip>Required</HelpBlock>
          </FormGroup>

          <Button
            type="submit"
            style={{ textTransform: "lowercase" }}
            onClick={() => showDiscounts()}>
            Submit
          </Button>
        </Form>
        <hr />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-rows: 1fr 5fr;
  font-family: "Roaster Original";
  text-transform: lowercase;

  .go-back {
    position: absolute;
    top: 5%;
    left: 5%;
  }

  .user-permission-container {
    display: grid;
    grid-template-rows: 1fr 3fr;
  }

  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 900px) {
     margin: 0 5vw;
     flex-direction: row;

    }
  }

  .form-control-make-admin {
    width: 20vw;
    @media (max-width: 900px) {
      width: 75vw;
    }
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      font-family: "Roaster Original";
      text-transform: lowercase;
    }
  }
`;

export default EnterDiscount;
