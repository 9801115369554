import React from "react";
import bean from "../resources/img/colour-bean.png";
import mask from "../resources/img/main-mask.png";
import merch from "../resources/img/monkey-merch.png";
import { Link } from "react-router-dom";
import styled from "styled-components";

const baseName = process.env.REACT_APP_BASENAME;

function MainScreen() {
  return (
    <>
      <section className="unique-blends">
        <GridContainer>
          <h1 className="unique-blends__heading-1">Our Unique Blends</h1>
          <Link to={`${baseName}/beans`}>
            <div className="container">
              <img src={bean} alt="bean" />
              <div className="overlay">
                <div className="text">Touch me to find out more</div>
              </div>
            </div>
          </Link>
        </GridContainer>

        <GridContainer>
          <h1 className="unique-blends__heading-2">
            Why Carbon Free Roasting?
          </h1>
          <Link to={`${baseName}/aboutvideo`}>
            <div className="container">
              <img src={mask} alt="bean" className="small-img" />
              <div className="overlay">
                <div className="text">Touch me to find out more</div>
              </div>
            </div>
          </Link>
        </GridContainer>

        <GridContainer>
          <h1 className="unique-blends__heading-3">Merchandise</h1>
          <Link to={`${baseName}/merch`}>
            <div className="container">
              <img src={merch} alt="bean" className="small-img" />
              <div className="overlay">
                <div className="text">Touch me to find out more</div>
              </div>
            </div>
          </Link>
        </GridContainer>
      </section>
    </>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 3fr;
  text-align: center;

  a {
    height: 50vh;
  }
`;

export default MainScreen;
