import log from "loglevel";
import React, { useState } from "react";
import styled from "styled-components";
import {
  Form,
  FormGroup,
  ControlLabel,
  Button,
  FormControl,
  HelpBlock,
  Alert,
  CheckPicker,
} from "rsuite";
import axios from "axios";
import AdminNav from "../../components/AdminNav";
import GoBackBtn from "../../components/GoBackBtn";
import { useHistory } from "react-router";

const baseName = process.env.REACT_APP_BASENAME;

const UserPermissions = () => {
  const [formValue, setFormValue] = useState({
    email: "",
  });
  const [permissions, setPermissions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let history = useHistory();

  log.debug(formValue);

  const grantPermissions = async () => {
    setIsLoading(true);
    const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
    let isStaff = permissions?.includes("staff");
    let isAdmin = permissions?.includes("admin");
    let isStoreAccount = permissions?.includes("storeAccount");
    log.debug("isStaff", isStaff);
    log.debug("isAdmin", isAdmin);
    log.debug("isStoreAccount", isStoreAccount);
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/grant_user_permissions`,
        //prettier-ignore
        { "token": authToken, "email": formValue.email, "staff": isStaff, "admin": isAdmin, "storeAccount": isStoreAccount}
      );
      log.debug("updating order status resp:", resp);
      if (resp.status === 200) {
        Alert.success(`successfully changed ${formValue.email} permissions`);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      log.debug(error);
    }
  };

  const handleChange = (value) => {
    // log.debug("Adding" + value + "to user permissions");
    setPermissions(value);
  };

  const goBack = () => {
    history.push(`${baseName}/mystore`);
  };

  let selectData = [
    { label: "Staff", value: "staff" },
    { label: "Admin", value: "admin" },
    { label: "Store Account", value: "storeAccount" },
  ];

  return (
    <>
      <GoBackBtn goBack={goBack} />
      <Wrapper>
        <div className="user-permission-container">
          <div className="header-container">
            <h2>Make Admins</h2>
          </div>
          <div className="form-container">
            <p style={{ paddingBottom: "15vh" }}>
              if the option is blank, it means the user is neither an admin or
              staff, select or unselect to change this
            </p>
            <Form
              layout="inline"
              onChange={(val) => setFormValue(val)}
              formValue={formValue}>
              <FormGroup>
                <ControlLabel>Email</ControlLabel>
                <FormControl name="email" className="form-control-make-admin" />
                <HelpBlock tooltip>Required</HelpBlock>
              </FormGroup>
              <CheckPicker
                onChange={handleChange}
                data={selectData}
                style={{ width: 224 }}
              />
              <Button
                style={{ textTransform: "lowercase" }}
                loading={isLoading}
                onClick={() => grantPermissions()}>
                Apply Changes
              </Button>
            </Form>
            <hr />
          </div>
        </div>
      </Wrapper>
      <AdminNav style={{ top: "90vh" }} />
    </>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  padding-top: 2rem;
  display: grid;
  grid-template-rows: 1fr 2fr;

  font-family: "Roaster Original";
  text-transform: lowercase;

  .user-permission-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 900px) {
      margin: 0 5vw;
    }
  }

  .form-control-make-admin {
    width: 20vw;
    @media (max-width: 900px) {
      width: 75vw;
    }
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      font-family: "Roaster Original";
      text-transform: lowercase;
    }
  }
`;

export default UserPermissions;
