import React from "react";
import styled from "styled-components";
import { Navbar, Nav } from "rsuite";
import { useHistory } from "react-router-dom";

const TermsAndConditions = () => {
  let history = useHistory();
  function onLinkClick(id) {
    const yOffset = -70;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }
  return (
    <>
      <Navbar>
        {/* <Navbar.Brand href="#">RSUITE</Navbar.Brand> */}
        <Nav
          style={{
            width: "100%",
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgb(240,240,240)",
            boxShadow: "1px 1px 5px rgba(150,150,150,0.6)",
          }}>
          <Nav.Item onSelect={() => history.goBack()}>&larr; Back</Nav.Item>
          <Nav.Item onSelect={() => onLinkClick("tandc")}>T&C's</Nav.Item>
          <Nav.Item onSelect={() => onLinkClick("privacypolicy")}>
            Privacy Policy
          </Nav.Item>
          <Nav.Item onSelect={() => onLinkClick("cookiepolicy")}>
            Cookie Policy
          </Nav.Item>
        </Nav>
      </Navbar>
      <Wrapper>
        <h2 style={{ fontSize: 45 }} id="tandc">
          <u>Terms & Conditions</u>
        </h2>

        <p>
          This Privacy Policy explain what personal data We collect and how We
          use it. This Policy also explain what rights you have in regards to
          your personal data and how you can exercise those rights. This Privacy
          Policy applies to all personal data We process as a Data Controller in
          respect of Our users and customers. It does not apply to the extent We
          process personal data in the role of a processor or service provider
          on behalf of Our customers or partners. References to "We", "Our" or
          "Us" in this document are references to Nudo Designs Ltd. Nudo Designs
          Ltd. is a private Limited Company (Company Registration Number
          12833846) whose registered office is Dorset House, 5 Church Street,
          Wimborne, England, Bh41 1JH.
        </p>

        <h4>How We use your data?</h4>
        <p>
          We use your data to provide our products and services and for
          marketing, research, loyalty schemes and competitions. You have the
          right to object or withdraw your consent at any time by emailing
          dataprotection@nudodesigns.com
        </p>
        <h4>How do We collect information about you?</h4>
        <p>
          We collect information about you when you use our website or apps,
          register to open an account with Us, use Our apps or services, make
          transactions or when you contact Us.
        </p>
        <h4>What information do We collect?</h4>
        <p>
          We collect information to identify you which may include title, name,
          address, postcode, email address, date of birth and any other data you
          use to set up your account, to log-in or to use our services. We
          collect information about how you use Our apps or services. This
          includes transaction details including date, time, location and
          channel of purchase, goods or services purchased, price, discount,
          loyalty points or other marketing campaign offer applied. We collect
          internal and network attributes including device identifiers, IP
          addresses, operating system or browser versions. We collect your
          preferences including your opt-in/out marketing preferences and
          notification preferences. We collect information you provide if you
          contact Us to make an enquiry, complaint or to provide feedback.
        </p>
        <h4>How We use your data </h4>
        <p>
          We use your data if We have a proper reason to do such as: i) to
          fulfil a contract; ii) when it is in our legitimate interest; iii)
          when you give consent; iv) when We are legally obliged to do so. We
          have a legitimate interest in i) data required to provide Our
          services, ii) communicate with you, iii) understanding how users
          interact with Our app or services, iv) to comply with Our regulatory
          obligations, v) to provide support or handle complaints or enquiries,
          vi) to improve Our apps, website and other services.
        </p>
        <h4>Data sharing</h4>
        <p>
          Where you have used a branded version of Our app or website for one of
          our customers or business partners We may share information about you
          and how you use the app, website and services with that customer or
          business partner. Where we share data in this way the third party will
          become a new Data Controller of your information.
        </p>
        <h4>Data retention</h4>
        <p>
          We keep your personal data for long as is required for Us to fulfil
          our contract with you or until you close your account or ask Us to
          stop processing your information. We keep payment and tax information
          in line with appropriate regulatory requirements. We may keep your
          data for longer if we are unable to delete it for legal, regulatory or
          technical reasons.
        </p>
        <h4>Your rights</h4>
        <p>
          You have rights over your personal data as per the UK Data Protection
          Act (2018) or any other applicable legal or regulatory framework. Your
          rights include: • requesting a copy of your personal data • requesting
          to have your personal data updated or corrected • asking for your
          personal data to be erased • asking Us to stop or restrict processing
          of your data asking Us to send a copy of your data in a structured
          format or provide that data to a third party
        </p>
        <h4>Contact details</h4>
        <p>
          If you wish to exercise any of your rights you can email
          dataprotection@nudodesigns.com
        </p>
        <h2 style={{ fontSize: 45 }} id="privacypolicy">
          <u>Privacy Policy</u>
        </h2>

        <p>
          This Privacy Policy explain what personal data We collect and how We
          use it. This Policy also explain what rights you have in regards to
          your personal data and how you can exercise those rights. This Privacy
          Policy applies to all personal data We process as a Data Controller in
          respect of Our users and customers. It does not apply to the extent We
          process personal data in the role of a processor or service provider
          on behalf of Our customers or partners. References to "We", "Our" or
          "Us" in this document are references to Nudo Designs Ltd. Nudo Designs
          Ltd. is a private Limited Company (Company Registration Number
          12833846) whose registered office is Dorset House, 5 Church Street,
          Wimborne, England, Bh41 1JH.
        </p>

        <h4>How We use your data?</h4>
        <p>
          We use your data to provide our products and services and for
          marketing, research, loyalty schemes and competitions. You have the
          right to object or withdraw your consent at any time by emailing
          dataprotection@nudodesigns.com
        </p>
        <h4>How do We collect information about you?</h4>
        <p>
          We collect information about you when you use our website or apps,
          register to open an account with Us, use Our apps or services, make
          transactions or when you contact Us.
        </p>
        <h4>What information do We collect?</h4>
        <p>
          We collect information to identify you which may include title, name,
          address, postcode, email address, date of birth and any other data you
          use to set up your account, to log-in or to use our services. We
          collect information about how you use Our apps or services. This
          includes transaction details including date, time, location and
          channel of purchase, goods or services purchased, price, discount,
          loyalty points or other marketing campaign offer applied. We collect
          internal and network attributes including device identifiers, IP
          addresses, operating system or browser versions. We collect your
          preferences including your opt-in/out marketing preferences and
          notification preferences. We collect information you provide if you
          contact Us to make an enquiry, complaint or to provide feedback.
        </p>
        <h4>How We use your data </h4>
        <p>
          We use your data if We have a proper reason to do such as: i) to
          fulfil a contract; ii) when it is in our legitimate interest; iii)
          when you give consent; iv) when We are legally obliged to do so. We
          have a legitimate interest in i) data required to provide Our
          services, ii) communicate with you, iii) understanding how users
          interact with Our app or services, iv) to comply with Our regulatory
          obligations, v) to provide support or handle complaints or enquiries,
          vi) to improve Our apps, website and other services.
        </p>
        <h4>Data sharing</h4>
        <p>
          Where you have used a branded version of Our app or website for one of
          our customers or business partners We may share information about you
          and how you use the app, website and services with that customer or
          business partner. Where we share data in this way the third party will
          become a new Data Controller of your information.
        </p>
        <h4>Data retention</h4>
        <p>
          We keep your personal data for long as is required for Us to fulfil
          our contract with you or until you close your account or ask Us to
          stop processing your information. We keep payment and tax information
          in line with appropriate regulatory requirements. We may keep your
          data for longer if we are unable to delete it for legal, regulatory or
          technical reasons.
        </p>
        <h4>Your rights</h4>
        <p>
          You have rights over your personal data as per the UK Data Protection
          Act (2018) or any other applicable legal or regulatory framework. Your
          rights include: • requesting a copy of your personal data • requesting
          to have your personal data updated or corrected • asking for your
          personal data to be erased • asking Us to stop or restrict processing
          of your data asking Us to send a copy of your data in a structured
          format or provide that data to a third party
        </p>
        <h4>Contact details</h4>
        <p>
          If you wish to exercise any of your rights you can email
          dataprotection@nudodesigns.com
        </p>
        <h2 style={{ fontSize: 45 }} id="cookiepolicy">
          <u>Privacy Policy</u>
        </h2>

        <p>
          This Privacy Policy explain what personal data We collect and how We
          use it. This Policy also explain what rights you have in regards to
          your personal data and how you can exercise those rights. This Privacy
          Policy applies to all personal data We process as a Data Controller in
          respect of Our users and customers. It does not apply to the extent We
          process personal data in the role of a processor or service provider
          on behalf of Our customers or partners. References to "We", "Our" or
          "Us" in this document are references to Nudo Designs Ltd. Nudo Designs
          Ltd. is a private Limited Company (Company Registration Number
          12833846) whose registered office is Dorset House, 5 Church Street,
          Wimborne, England, Bh41 1JH.
        </p>

        <h4>How We use your data?</h4>
        <p>
          We use your data to provide our products and services and for
          marketing, research, loyalty schemes and competitions. You have the
          right to object or withdraw your consent at any time by emailing
          dataprotection@nudodesigns.com
        </p>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  //   height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5% 10%;
  scroll-behavior: smooth;
  p {
    text-align: center;
    margin-bottom: 50px;
  }

  h4 {
    padding-bottom: 10px;
  }
  h2 {
    padding-bottom: 30px;
  }
`;

export default TermsAndConditions;
