import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import AdminNav from "../components/AdminNav";
import { IoSettingsOutline, IoStorefrontOutline } from "react-icons/io5";
import { FaBoxes } from "react-icons/fa";
import { GiSellCard } from "react-icons/gi";
import { RiUserSettingsLine, RiCoupon2Line } from "react-icons/ri";
import {
  HiOutlineDocumentReport,
  HiOutlineClipboardList,
} from "react-icons/hi";
import Button from "../components/Button";
import axios from "axios";
import log from "loglevel";

const baseName = process.env.REACT_APP_BASENAME;

const AdminHome = () => {
  let history = useHistory();
  return (
    <>
      <AdminNav />
      <Header>
        <h1>Your store hub</h1>
      </Header>
      <Wrapper>
        <div className="admin-card">
          <div className="btn-admin-container">
            <GiSellCard className="icon-admin" />
            <Button
              appearance="secondary"
              style={{ width: "11rem" }}
              onClick={() => history.push(`${baseName}/mystore/orders`)}>
              Your orders
            </Button>
          </div>
        </div>
        <div className="admin-card">
          <div className="btn-admin-container">
            <IoStorefrontOutline className="icon-admin" />
            <Button
              appearance="secondary"
              style={{ width: "11rem" }}
              onClick={() => history.push(`${baseName}/mystore/instoreorders`)}>
              Instore orders
            </Button>
          </div>
        </div>
        <div className="admin-card">
          <div className="btn-admin-container">
            <RiUserSettingsLine className="icon-admin" />
            <Button
              appearance="secondary"
              style={{ width: "11rem" }}
              onClick={() =>
                history.push(`${baseName}/mystore/userpermissions`)
              }>
              User Permissions
            </Button>
          </div>
        </div>

        <div className="admin-card">
          <div className="btn-admin-container">
            <RiCoupon2Line className="icon-admin" />
            <Button
              appearance="secondary"
              style={{ width: "11rem" }}
              onClick={() =>
                history.push(`${baseName}/mystore/managediscounts/select`)
              }>
              Discounts
            </Button>
          </div>
        </div>
        <div className="admin-card">
          <div className="btn-admin-container">
            <HiOutlineDocumentReport className="icon-admin" />

            <Button
              appearance="secondary"
              style={{ width: "11rem" }}
              onClick={() => history.push(`${baseName}/mystore/selectsales`)}>
              Your Sales Report
            </Button>
          </div>
        </div>

        <div className="admin-card">
          <div className="btn-admin-container">
            <FaBoxes className="icon-admin" />
            <Button appearance="secondary" style={{ width: "11rem" }}>
              Your Products
            </Button>
          </div>
        </div>
        <div className="admin-card">
          <div className="btn-admin-container">
            <HiOutlineClipboardList className="icon-admin" />
            <Button appearance="secondary" style={{ width: "11rem" }}>
              Your Stock
            </Button>
          </div>
        </div>
        <div className="admin-card">
          <div className="btn-admin-container">
            <IoSettingsOutline className="icon-admin" />
            <Button
              appearance="secondary"
              style={{ width: "11rem" }}
              onClick={async () => {
                const authToken = JSON.parse(
                  window.localStorage.getItem("auth_token")
                );
                log.debug(authToken);
                try {
                  const resp = await axios.post(
                    `${process.env.REACT_APP_API_ADDRESS}/create_coupon_order`,
                    //prettier-ignore
                    { "token": authToken, "uid":  "IWL4V55fWFZ79qsnWnxbkX20krM2", "discount_item": "cap"}
                  );
                  log.debug(resp);
                } catch (error) {
                  console.log(error.message);
                  console.log(error);
                }
              }}>
              Store Settings
            </Button>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  font-family: "Roaster Original";
  height: 120vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  padding: 2rem;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  .admin-card {
    background-color: rgba(14, 114, 185, 0.9);

    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -3px 4px 5px 2px rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
  }

  .icon-admin {
    font-size: 3.5rem;
    color: white;
  }

  .btn-admin-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 50%;
    width: 50%;
  }

  button {
    border: none !important;
    color: white !important;
  }
`;

const Header = styled.div`
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: rgb(14, 114, 185) !important;
    font-family: "Roaster Original";
    text-shadow: 2px 2px rgba(75, 75, 75, 0.3);
    text-transform: lowercase;
  }
`;
export default AdminHome;
