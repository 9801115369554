import ManagerLuke from "../img/Managers/luke_avatar.png";
import ManagerRob from "../img/Managers/rob_avatar.png";
import ManagerOcto from "../img/Managers/octo_avatar.png";
import ManagerEmily from "../img/Managers/emily_avatar.png";
import ManagerSoony from "../img/Managers/soony_avatar.png";
import ManagerVanessa from "../img/Managers/vanessa_avatar.png";
import coffeeHolic from "../img/Managers/coffee-holic.png";

const StoreDetails = [
  {
    storeManager: 1,
    store: "Naked Coffee Beales Poole",
    img: ManagerLuke,
    text:
      "Hi Luke here. This is our latest store, we have an in house roaster. Why not pop in, grab a coffee and buy some freshly roasted Beans.",
    colour: "rgb(138,202,252)",
    link:
      "https://www.google.com/maps/place/Naked+Coffee/@50.7304331,-1.9576863,13z/data=!4m8!1m2!2m1!1snaked+coffee!3m4!1s0x4873a7d181c5aa39:0xf56fd3867e15db1a!8m2!3d50.7188825!4d-1.9808576",
  },
  {
    storeManager: 6,
    store: "Naked Coffee Southampton",
    img: ManagerVanessa,
    text:
      "Hi I’m  Vanessa. We use compostable packaging and  care about the planet. Why not come in and try one of our 3 unique hand blended roasts and some home made food😘",
    colour: "rgb(235,255,65)",
    link:
      "https://www.google.com/maps/place/Naked+Coffee/@50.9084012,-1.4473847,13z/data=!4m8!1m2!2m1!1snaked+coffee!3m4!1s0x487476bb1c6797c5:0x5f4cc2fb7bc476da!8m2!3d50.9084012!4d-1.4123658",
  },
  {
    storeManager: 3,
    store: "Naked Deli International",
    img: ManagerOcto,
    text:
      "Hi, I’m Octavian. We call this the Lab. it’s where all of our crazy ideas are born. There’s plenty of seating and we’re open to the public for food and our famous shop roasted coffee🤟🏻",
    colour: "rgb(155,235,104)",
    link:
      "https://www.google.com/maps/place/Naked+Deli+International/@50.7304331,-1.9576863,13z/data=!4m8!1m2!2m1!1snaked+coffee!3m4!1s0x4873a1691b6ffa19:0xb99d6949874effab!8m2!3d50.7239376!4d-1.8650253",
  },
  {
    storeManager: 4,
    store: "Naked Coffee BU",
    img: ManagerEmily,
    text:
      "Hi I’m Emily. We’ve got two stores on campus giving you an independent right at your fingertips. With our 3 blends you can chill with friends or buzz your way through the day.",
    colour: "rgb(231,141,255)",
    link:
      "https://www.google.com/maps/place/Naked+Coffee/@50.7304331,-1.9576863,13z/data=!4m8!1m2!2m1!1snaked+coffee!3m4!1s0x4873a188b767d62b:0x41bf46fe50cbd562!8m2!3d50.7424881!4d-1.8960955",
  },
  {
    storeManager: "Rob",
    store: "The gateway Talbot campus",
    img: ManagerRob,
    text:
      "Hi Guys. I’m Rob, we’re right at your fingertips as you walk onto campus. If your new to Uni why not sign up to our newsletter to receive your discount card.",
    colour: "rgb(241,181,82)",
    link:
      "https://www.google.com/maps/place/Naked+Coffee/@50.7304331,-1.9576863,13z/data=!4m8!1m2!2m1!1snaked+coffee!3m4!1s0x4873a188b767d62b:0x41bf46fe50cbd562!8m2!3d50.7424881!4d-1.8960955",
  },
  {
    storeManager: 5,
    store: "Naked Coffee Lansdowne",
    img: ManagerSoony,
    text:
      "Hi I’m Soony, the store manager. We cater for all dietary needs and are proud of our ethical roots. Plus we’re pretty cool 😎 lol. Come and chill with friends.xx",
    colour: "rgb(255,131,178)",
    link:
      "https://www.google.com/maps/place/Naked+Coffee/@50.7304331,-1.9576863,13z/data=!4m8!1m2!2m1!1snaked+coffee!3m4!1s0x4873a1deb0b9325f:0x93a27358947129d8!8m2!3d50.7222222!4d-1.8652778",
  },
  {
    storeManager: 6,
    store: "Naked at Kings language School",
    img: coffeeHolic,
    text:
      "Hi I’m Beth, We couldn’t let our International students come to the U.K. and miss out on the naked love so we teamed up with Kings language school and Bang! Another Naked was born. We’re also open to the public so feel free to pop in😉",
    colour: "rgb(255,62,76)",
    link:
      "https://www.google.com/maps/dir/50.7354938,-1.9681058/kings+language+school+Richmond+hill/@50.7380095,-1.9931708,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x4873a1c47af38939:0x6fabfe921ab225a1!2m2!1d-1.8779189!2d50.727276",
  },
];

export default StoreDetails;
