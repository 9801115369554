import log from 'loglevel';
import React, { createContext, useContext, useReducer } from "react";
// import axios from "axios";
import { LOGIN_USER, LOGOUT_USER, REFRESH_CONTEXT } from "../actions";
import { initialiseUser } from "../../helpers/helper";
import "firebase/auth";

import reducer from "../reducers/auth_reducer";

const AuthContext = createContext();

export const AuthProvider = ({ loggedInUser, children }) => {
  const [currentUser, dispatch] = useReducer(reducer, loggedInUser);

  const loginUser = async (
    uid,
    name,
    email,
    firebaseToken,
    ecwidToken,
    shippingAddresses,
    loyalty_points,
    isAdmin,
    isStaff,
    emailVerified,
    ecwidCustomerId
  ) => {
    log.debug(
      "auto_context loginUser: uid=" +
        uid +
        ", name=" +
        name +
        ", email=" +
        email +
        ", email_verified=" +
        emailVerified
    );

    const user = await initialiseUser(
      uid,
      name,
      email,
      firebaseToken,
      ecwidToken,
      shippingAddresses,
      loyalty_points,
      isAdmin,
      isStaff,
      emailVerified,
      ecwidCustomerId
    );

    dispatch({ type: LOGIN_USER, payload: user });
  };

  // const updatedUserDetails = async (itemToUpdate, UpdatedValue) => {
  //   dispatch({
  //     type: UPDATE_USER_DETAILS,
  //     payload: { itemToUpdate, UpdatedValue },
  //   });
  // };

  const refreshContext = async (itemToUpdate, updatedValue) => {
    log.debug("itemToUpdate:", itemToUpdate);
    log.debug("updatedValue:", updatedValue);
    dispatch({
      type: REFRESH_CONTEXT,
      payload: { itemToUpdate, updatedValue },
    });
  };

  const logoutUser = async () => {
    // Logout from firebase
    log.debug("logoutUser currentUser=", currentUser);

    if (currentUser?.auth) {
      log.debug("Logging out from Firebase");

      try {
        await currentUser?.auth?.signOut();
      } catch (error) {
        console.error(
          "Firebase auth signOut failed error code = ",
          error?.code || "",
          " message = ",
          error?.message || ""
        );
      }
    }

    if (currentUser?.app) {
      log.debug("Destroying Firebase app ");

      try {
        await currentUser?.app?.delete();
      } catch (error) {
        console.error(
          "Firebase app delete failed error code = ",
          error?.code || "",
          " message = ",
          error?.message || ""
        );
      }
    }

    window.localStorage.removeItem("auth_token");
    window.localStorage.removeItem("uid");
    window.localStorage.removeItem("ec_token");

    // window.location.reload();

    dispatch({ type: LOGOUT_USER });
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loginUser,
        logoutUser,
        loggedInUser,
        refreshContext,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
