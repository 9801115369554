import React from "react";
import styled from "styled-components";

const Footer = ({ color }) => {
  return (
    <Wrapper
      style={{
        backgroundColor: color,
        boxShadow: "0px -6px 14px -4px rgba(0,0,0,0.48)",
      }}>
      <p className="header-text">Click on the icon you wish to use and order</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 5rem;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .header-text {
    font-size: 1.7rem;
    font-family: "Roaster Original";
    text-shadow: 1px 1px white;

    @media screen and (max-width: 1200px) {
      font-size: 1.4rem;
    }

    @media screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

export default Footer;
