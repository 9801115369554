import log from "loglevel";
import { useAuth } from "../../ecwid/context/auth_context";
import React, { useRef, useState } from "react";
import RegisterForm from "../../components/auth/InputForm";
import axios from "axios";
import InputModal from "../../components/account/inputModal";
import { Schema, Alert } from "rsuite";
import { useHistory } from "react-router-dom";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import { checkout } from "../../helpers/helper";

const { StringType } = Schema.Types;

const initialForm = {
  email: "",
  password: "",
};

const registerModel = Schema.Model({
  email: StringType()
    .isEmail("Please enter the correct email")
    .isRequired("This field is required"),
  password: StringType()
    .minLength(6, "The field cannot be less than 6 characters")
    .maxLength(25, "The field cannot be greater than 25 characters")
    .isRequired("This field is required"),
});

const baseName = process.env.REACT_APP_BASENAME;

const Login = () => {
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(initialForm);
  const [showModal, setShowModal] = useState({ show: false, type: "" });
  const open = (type, changeOrAdd) => {
    setShowModal({ show: true, type: type, changeOrAdd: changeOrAdd });
  };

  //location to redirect after user logs in
  let history = useHistory();
  let redirectTo = "myvip";
  if (history.location.state) redirectTo = history.location.state;
  log.debug("redirectTo in login.js", redirectTo);

  log.debug("history.location:", history.location);

  const { loginUser, currentUser } = useAuth();

  //checks if user is already logged in to avoid navigating to this page from url
  log.debug(currentUser);
  if (currentUser?.uid && currentUser?.name && currentUser?.email) {
    if (redirectTo) {
      history.push(`${baseName}/${redirectTo}`, redirectTo);
    } else {
      history.push(`${baseName}/"vip`, "vip");
    }
  }

  const onFormSubmit = async () => {
    setIsLoading(true);
    if (!formRef.current.check()) {
      setIsLoading(false);
      Alert.error("Wrong details");
      return;
    }

    log.debug("login_user formValue:", formValue);

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/login_user_by_email_password`,
        formValue
      );

      log.debug("login_user resp object: ", resp);

      if (resp.status === 200) {
        const authToken = resp.data.token;

        // save token to local storage
        window.localStorage.setItem("auth_token", JSON.stringify(authToken));

        // alert(resp.data.is_admin);

        // save user and token to context
        await loginUser(
          resp?.data?.uid || "",
          resp?.data?.name || "",
          resp?.data?.email || "",
          resp?.data?.firebase_token || "",
          resp?.data?.ecwid_token || "",
          resp?.data?.shippingAdresses || "",
          resp?.data?.loyalty_points,
          resp?.data?.is_admin || false,
          resp?.data?.is_staff || false,
          resp?.data?.email_verified
        );

        //send analytic

        window.gtag("event", "succesfull_login", {
          event_category: "engagement",
          event_label: "method",
        });

        //user is allowed to place order with unverified email
        let redirectUrl = window.localStorage.getItem("redirectUrl");
        log.debug("redirectUrl in login user", redirectUrl);

        if (redirectUrl) {
          redirectUrl = JSON.parse(redirectUrl);
          history.push({
            pathname: redirectUrl,
          });
          window.localStorage.removeItem("redirectUrl");
          checkout();
        } else if (resp?.data?.email_verified === false) {
          history.push({
            pathname: `${baseName}/vip`,
            state: { showEmailVerLogin: true },
          });
        } else {
          //show success alert
          Alert.success(`Succesfully logged you in`);
        }
        //by default if no history push to myip
        // log.debug("redirectTo", redirectTo);
        // window.location.reload();
        // history.push(`${baseName}/${redirectTo ? redirectTo : "myip"}`);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      window.gtag("event", "failed_login", {
        event_category: "engagement",
        event_label: "method",
      });

      log.debug(error.response);
      log.debug(error);
      Alert.error("You don't have an account or something went wrong");
    }
  };

  const resetPassword = async () => {
    // if (currentUser?.app) {
    //   log.debug("Destroying Firebase app ");

    //   try {
    //     await currentUser?.app?.delete();
    //   } catch (error) {
    //     console.error(
    //       "Firebase app delete failed error code = ",
    //       error?.code || "",
    //       " message = ",
    //       error?.message || ""
    //     );
    //   }
    // }
    open("reset_password", "Change");
  };

  return (
    <>
      <Navigation />
      <InputModal showModal={showModal} setShowModal={setShowModal} />
      <RegisterForm
        formType="Login"
        onFormSubmit={onFormSubmit}
        registerModel={registerModel}
        formRef={formRef}
        isLoading={isLoading}
        isLoadingText="Logging you in..."
        formValue={formValue}
        setFormValue={setFormValue}
        initialForm={initialForm}
        fromState={redirectTo}
        history={history}
        resetPassword={resetPassword}
      />
      <Footer />
    </>
  );
};

export default Login;
