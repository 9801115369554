import log from "loglevel";
import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import Routes from "./routes";

import "rsuite/dist/styles/rsuite-default.css";
import { AuthProvider } from "./ecwid/context/auth_context";
import Loading from "./components/Loading";

// const homePage = process.env.REACT_APP_HOSTNAME;

const App = ({ isEcwidScriptAdded, loggedInUser }) => {
  const [isCookieChoiceMade, setIsCookieChoiceMade] = useState(false);
  const [isEcwidReady, setIsEcwidReady] = useState(false);

  useEffect(() => {
    (async () => {
      function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }

      while (
        !(
          typeof window.Ecwid == "object" &&
          typeof window.Ecwid.init == "function"
        )
      ) {
        log.debug("Waiting for Ecwid...");
        await sleep(100);
      }

      window.Ecwid.init();

      window.Ecwid.OnAPILoaded.add(() => {
        setIsEcwidReady(true);
      });

      // window.Ecwid.OnSetProfile.add(function (customer) {
      //   log.debug(customer);
      // });
    })();

    //set cookies
    switch (localStorage.getItem("ec-user-response")) {
      case "true":
        // Prevent cookie banner if user has already chosen
        // in Ecwid
        setIsCookieChoiceMade(true);
        break;
      case "false":
        // Prevent cookie banner if user has already chosen
        // in Ecwid
        setIsCookieChoiceMade(true);
        break;
      default:
        // Leave isCookieChoiceMade as False
        // User hasn't made a choice yet.  To avoid
        // banner showing up on main page AND in Ecwid
        // we set ec-user-response in localstorage to false.
        // This will be overwritten in onAccept below
        // if user accepts cookies.
        // (Ecwid uses ec-user-response in localstorage;
        // main page uses ec-user-response cookie)
        localStorage.setItem("ec-user-response", "false");
    }
  }, []);

  if (!isEcwidScriptAdded || !isEcwidReady) {
    return <Loading text="Brewing your coffee" />;
  } else {
    return (
      <>
        {!isCookieChoiceMade && (
          <CookieConsent
            location="bottom"
            buttonText="accept"
            declineButtonText="decline"
            flipButtons
            cookieName="ec-user-response"
            enableDeclineButton
            style={{
              background: "#ED1E79",
              textTransform: "lowercase",
              fontFamily: "Roaster Original",
            }}
            buttonStyle={{
              padding: ".6rem 1.2rem",
              backgroundColor: "white",
              borderRadius: "2rem",
            }}
            onAccept={() => {
              localStorage.setItem("ec-user-response", "true");
            }}
            onDecline={() => {
              localStorage.setItem("ec-user-response", "false");
            }}>
            We use cookies and similar technologies to remember your
            preferences, measure effectiveness of our campaigns, and analyse
            depersonalised data to improve performance of our site. By choosing
            "Accept", you consent to the use of cookies.
          </CookieConsent>
        )}

        <AuthProvider loggedInUser={loggedInUser}>
          <Routes />
        </AuthProvider>
      </>
    );
  }
};
export default App;
