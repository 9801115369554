import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import { colors } from "../constants/constants";
import DescrComp from "../components/DescrComp";
import HeaderComp from "../components/HeaderComp";
import FoodIngredients from "../../resources/img/sotonUni/food-ingredients.png";
import FoodCookies from "../../resources/img/sotonUni/food-cookies.png";
import FoodPastries from "../../resources/img/sotonUni/food-pastries.png";
import FoodSmallCake from "../../resources/img/sotonUni/food-small-cake.png";
import FoodFlapjack from "../../resources/img/sotonUni/food-flapjack.png";
import FoodCake from "../../resources/img/sotonUni/food-cake.png";
// import FoodBigCake from "../../resources/img/sotonUni/food-big-cake.png";
import FoodChooseYourBowl from "../../resources/img/sotonUni/food-choose-your-bowl.png";
import FoodSaladDisplay from "../../resources/img/sotonUni/food-salad-display.png";
import FoodLunchMenu from "../../resources/img/sotonUni/food-lunch-menu.png";
import FoodSalad1 from "../../resources/img/sotonUni/food-salad-1.png";
import FoodSalad2 from "../../resources/img/sotonUni/food-salad-2.png";
import FoodSalad3 from "../../resources/img/sotonUni/food-salad-3.png";
import FoodSalad4 from "../../resources/img/sotonUni/food-salad-4.png";
import FoodSalad5 from "../../resources/img/sotonUni/food-salad-5.png";
import FoodSalad6 from "../../resources/img/sotonUni/food-salad-6.png";

const Food = () => {
  return (
    <Wrapper style={{ backgroundColor: colors.secondaryColor }}>
      <HeaderComp backgColor={colors.primaryColor} text="" title="Food" />

      <DescrComp
        backgColor={colors.primaryColor}
        title="Pastries"
        text="Five years of friends, experience & advice has allowed us to trial many concepts, styles & products.
            We par-bake all of our pastries fresh every morning in each store & the junk free vegan cookies we supply in 
            partnership with a lovely drama teacher gone cookie rouge are the best on the planet 
            or should I say ‘the best for the planet’."
      />
      <div className="food-img-container">
        <div className="two-one-top">
          <ScrollAnimation animateIn="bounceInLeft" offset={300}>
            <img src={FoodIngredients} alt="ingredients" />
          </ScrollAnimation>

          <ScrollAnimation animateIn="bounceInRight" offset={300}>
            <img src={FoodCookies} alt="cookies" />
          </ScrollAnimation>
        </div>
        <div className="two-one-bottom">
          <ScrollAnimation animateIn="fadeInRightBig" offset={300}>
            <img src={FoodPastries} alt="pastries" />
          </ScrollAnimation>
        </div>
      </div>
      <DescrComp
        backgColor={colors.primaryColor}
        title="Cakes"
        text="We decided this area was one that we needed to tread carefully in. Whilst being strong supporters of veganism & gluten free offerings, 
        We decided we should not discriminate against the full flavour behaviour. 
        We have a healthy balance of ‘naughty but nice’ paired with ‘free but fun’ made in store by our loving creative department"
      />

      <div className="food-three-img-container">
        <ScrollAnimation animateIn="fadeInUpBig" offset={300}>
          <img src={FoodSmallCake} alt="small cake" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInDownBig" offset={300}>
          <img src={FoodFlapjack} alt="flapjack" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUpBig" offset={300}>
          <img src={FoodCake} alt="FoodCake" />
        </ScrollAnimation>
      </div>

      <DescrComp
        backgColor={colors.primaryColor}
        includeTitle={false}
        text="We spent years fighting with our inner demons on how to promote a healthy lifestyle without segregating a large 
        percentage of our customers. Our ‘Pick & Mix’ breakfast offering allows us to give a healthier choice whilst keeping the 
        house favorites on the menu. Every team in each store has been trained on upselling healthier options whislt not 
        making our customers feel like they are guilty to enjoy what they want. Its a great balance & one that sales show is 
        having a slow but steady effect in the promotion of better lifestyle choices.
        it's a fast & simple service allowing the customer a great choice paired with efficient service"
      />
      <DescrComp
        className="mg-top-2"
        backgColor={colors.primaryColor}
        title="Deli Lunch"
        text="Our first store was created under a uni residential housing block accomodating 250 students giving us amazing
        instant footfall. The downside was they wanted food at two times of the day & within a very short timeframe. 
        We learnt quickly to adapt our food for fast service whilst not affecting the quality.
        Our deli lunch menu allows for a fresh & fast service. You can pick up to 3 different main dishes followed by
        your protein. Allowing us to make 90% of our meals vegan, 100% Vegetarian & a large portion Gluten free.
        Acompanied by a great selection of ‘in store' made Paninins. 
        To support this rapid service we invested heavily in 'high power' speed ovens & presses to ensure a rapid service."
      />
      <div className="big-img">
        <img src={FoodChooseYourBowl} alt="choose your bowl" />
      </div>

      {/* gallery  */}

      <div className="gallery-img">
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={FoodSalad1} alt="salad-1" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={FoodSalad2} alt="salad-1" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={FoodSalad3} alt="salad-1" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={FoodSalad4} alt="salad-1" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={FoodSalad5} alt="salad-1" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={FoodSalad6} alt="salad-1" />
        </ScrollAnimation>
      </div>

      <div className="big-img">
        <ScrollAnimation animateIn="fadeInUpBig" offset={300}>
          <img src={FoodSaladDisplay} alt="salad display" />
        </ScrollAnimation>
      </div>
      <div className="big-img">
        <ScrollAnimation animateIn="bounceInLeft" offset={300}>
          <img src={FoodLunchMenu} alt="lunch menu" />
        </ScrollAnimation>
      </div>
      <DescrComp
        className="mg-top-2"
        backgColor={colors.primaryColor}
        title="Grab & Go"
        text="To compliment our Pastries & Cakes range we hand make a large variety of Sandwiches & Rolls for those of us on the go.
        We also make fresh juices, fresh yogurt pots and fresh fruit pots in house etc etc using local food suppliers like Roebrige Farm
        for our fresh fruit & veg with Harvest fine foods and other local suppliers in support."
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
  overflow: hidden;

  /* height: 100vh; */
  /* display: grid;
  grid-template-rows: 1fr 4fr; */

  .food-img-container {
    height: 100vw;
    margin: 2rem 0;
    width: 90vw;
    display: grid;
    grid-template-rows: 1fr 2fr;
    grid-row-gap: 1rem;

    @media screen and (max-width: 600px) {
      height: auto;
    }
  }

  .food-three-img-container {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    /* height: 20rem; */

    @media screen and (max-width: 600px) {
      grid-template-rows: repeat(3, 1fr);
      grid-row-gap: 1rem;
      grid-template-columns: none;
      height: 60rem;

      /* height: 60rem; */
    }

    img {
      width: 100%;
      height: 20rem;
    }
  }
  .mg-top-2 {
    margin-top: 2rem;
  }
`;

export default Food;
