import log from 'loglevel';
import React, { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import { useInstoreProductContext } from "../../../ecwid/context/in_store_product_context";
import SingleDrink from "./SingleDrink";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DrinksImg from "../../../resources/img/instore/drinks.png";

import { drinksColor } from "../menu/constants";

const Drinks = () => {
  const [drinks, setDrinks] = useState(null);
  const { products } = useInstoreProductContext();

  // log.debug(products);

  useEffect(() => {
    log.debug(products);
    if (Object.keys(products).length > 0) {
      setDrinks(products.drinks.items);
    }
  }, [products]);

  if (drinks) {
    return (
      <>
        <div className="instore-grid">
          <Header img={DrinksImg} color={drinksColor} />
          <div className="menu-grid">
            {drinks.map((drinkItem) => {
              return <SingleDrink key={drinkItem.id} drinkItem={drinkItem} />;
            })}
          </div>
          <Footer color={drinksColor} />
        </div>
      </>
    );
  }
  return <Loading />;
};

export default Drinks;
