import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import Header from "../components/Header";
// import CartoonVid from "../components/CartoonVideo";
import MainScreen from "../components/MainScreen";
import { useHistory } from "react-router";
import { useState } from "react";
import { useAuth } from "../ecwid/context/auth_context";

const baseName = process.env.REACT_APP_BASENAME;

function HomePage() {
  const [showBtn, setShowBtn] = useState(false);
  let history = useHistory();

  const { currentUser } = useAuth();
  useEffect(() => {
    if (currentUser?.uid) {
      setShowBtn(true);
      setTimeout(() => {
        setShowBtn(false);
      }, 10000);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Header />
      <Navigation />
      {showBtn && (
        <button
          className="go-to-discount-btn"
          onClick={() => {
            history.push(`${baseName}/mydiscounts`);
          }}>
          my discount
          <span className="go-to-discount-arrow">&#10095;</span>
        </button>
      )}
      {/* <CartoonVid /> */}
      <MainScreen />
      <Footer />
    </>
  );
}

export default HomePage;
