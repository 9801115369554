import log from "loglevel";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Alert } from "rsuite";
import firebase from "firebase/app";
import styled from "styled-components";
import Loading from "../../components/Loading";
import { useAuth } from "../../ecwid/context/auth_context";

const baseName = process.env.REACT_APP_BASENAME;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const FirebaseEmailCall = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { refreshContext } = useAuth();

  let history = useHistory();

  let query = useQuery();

  const mode = query.get("mode");
  const oobCode = query.get("oobCode");
  const continueUrl = query.get("continueUrl");
  const lang = query.get("lang");

  log.debug("mode:", mode);
  log.debug("oobCode:", oobCode);
  log.debug("continueUrl:", continueUrl);

  const handleFirebaseVerifyEmail = async () => {
    // initialize the firebase app
    let firebaseConfig = {
      apiKey: "AIzaSyAX1wj3tIa3EjNAxv7mSOnp46hFlGe1GXA", // Copy this key from the web initialization
      // snippet found in the Firebase console.
    };
    const appName = "app" + String(new Date().getTime());
    const app = firebase.initializeApp(firebaseConfig, appName);
    const auth = app.auth();

    //end of intiialize firebase app

    log.debug("inside handleFirebaseVerifyEmail function");

    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    auth
      .applyActionCode(oobCode)
      .then((resp) => {
        // Email address has been verified.
        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
        log.debug("verify email succeeded");
        log.debug(resp);
        refreshContext("emailVerified", true);
        setIsLoading(false);

        Alert.success("Sucessfully verified your email");

        //this is if the user has registered from cart page and should return to cart page
        if (window.localStorage.getItem("redirectUrl")) {
          window.localStorage.removeItem("redirectUrl");
          //delete the app before pushing
          app.delete();
          history.push(JSON.parse(window.localStorage.getItem("redirectUrl")));
        } else if (continueUrl) {
          //delete the app before pushing
          app.delete();
          window.location.href = continueUrl;
          // history.push(continueUrl);
        } else {
          //delete the app before pushing
          app.delete();
          history.push(`${baseName}/myvip`);
        }
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        app.delete();
        Alert.error("Something went wrong when trying to verify your email");
        log.debug(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    log.debug("mode:", mode);
    log.debug("oobCode:", oobCode);
    log.debug("continueUrl:", continueUrl);

    if (mode === "verifyEmail") {
      log.debug(
        "calling function handleFirebaseVerifyEmail with:",
        oobCode,
        continueUrl,
        lang
      );
      handleFirebaseVerifyEmail();
    } else if (mode === "resetPassword") {
      log.debug("verify password with:", oobCode, continueUrl, lang);
      setIsLoading(false);
      history.push({
        pathname: `${baseName}/fbrstoradddtls/resetpassword`,
        state: {
          oobCode: oobCode,
          continueUrl: continueUrl,
          lang: lang,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <Loading
        text={
          mode === "resetPassword"
            ? "Taking you to password reset page"
            : "verifying your email"
        }
      />
    );
  }

  return (
    <Wrapper>
      <p>
        Something went wrong when trying to verify your email, please try again
      </p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default FirebaseEmailCall;
