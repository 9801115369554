import React, { useState } from "react";
import { updateOrder } from "../../../helpers/helper";
import { Button } from "rsuite";

const UpdateBtn = ({ order, progressState, setProgressState }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Button
        className="admin-btn"
        appearance={progressState?.percent === 25 ? "primary" : "ghost"}
        loading={isLoading}
        onClick={() => {
          setProgressState((oldS) => ({ ...oldS, percent: 25 }));
          updateOrder(order.id, "AWAITING_PROCESSING", setIsLoading);
        }}>
        Awaiting processing
      </Button>
      <Button
        className="admin-btn"
        appearance={progressState?.percent === 50 ? "primary" : "ghost"}
        loading={isLoading}
        onClick={() => {
          setProgressState((oldS) => ({ ...oldS, percent: 50 }));
          updateOrder(order.id, "PROCESSING", setIsLoading);
        }}>
        Processing
      </Button>
      <Button
        className="admin-btn"
        appearance={progressState?.percent === 75 ? "primary" : "ghost"}
        loading={isLoading}
        onClick={() => {
          setProgressState((oldS) => ({ ...oldS, percent: 75 }));

          updateOrder(order.id, "OUT_FOR_DELIVERY", setIsLoading);
        }}>
        Delivering
      </Button>
      <Button
        className="admin-btn"
        appearance={progressState?.percent === 100 ? "primary" : "ghost"}
        loading={isLoading}
        onClick={() => {
          setProgressState((oldS) => ({ ...oldS, percent: 100 }));
          updateOrder(order.id, "DELIVERED", setIsLoading);
        }}>
        Finished
      </Button>
      <Button
        className="admin-btn"
        appearance={progressState?.percent === 0 ? "primary" : "ghost"}
        loading={isLoading}
        onClick={() => {
          setProgressState((oldS) => ({ ...oldS, percent: 0 }));
          updateOrder(order.id, "WILL_NOT_DELIVER", setIsLoading);
        }}>
        Cancel
      </Button>
      <Button
        className="admin-btn"
        appearance={progressState?.percent === 1 ? "primary" : "ghost"}
        loading={isLoading}
        onClick={() => {
          setProgressState((oldS) => ({ ...oldS, percent: 1 }));
          updateOrder(order.id, "RETURNED", setIsLoading);
        }}>
        Returned
      </Button>
    </>
  );
};

export default UpdateBtn;
