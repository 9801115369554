import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import MenuItem from "./menu/MenuItem";
import Header from "./components/Header";
import Footer from "./components/Footer";

import Drinks from "../../resources/img/instore/drinks.png";
import Treats from "../../resources/img/instore/treats.png";
import Food from "../../resources/img/instore/food.png";
import Points from "../../resources/img/instore/points.png";

import {
  drinksColor,
  treatsColor,
  foodColor,
  pointsColor,
} from "./menu/constants";

const baseName = process.env.REACT_APP_BASENAME;

const Menu = () => {
  let history = useHistory();

  const goToTreats = () => {
    history.push(`${baseName}/instore/menu/treats`);
  };

  const goToDrinks = () => {
    history.push(`${baseName}/instore/menu/drinks`);
  };

  const goToFoood = () => {
    history.push(`${baseName}/instore/menu/food`);
  };

  const goToPoints = () => {
    history.push(`${baseName}/instore/menu/points`);
  };

  return (
    <>
      <Wrapper>
        <Header color={pointsColor} />

        <div className="grid-middle">
          <MenuItem
            img={Drinks}
            name="drinks"
            height="13rem"
            textColor={drinksColor}
            onClick={goToDrinks}
          />
          <MenuItem
            img={Treats}
            name="Treats"
            height="11rem"
            textColor={treatsColor}
            onClick={goToTreats}
          />
          <MenuItem
            img={Food}
            name="Food"
            height="8rem"
            textColor={foodColor}
            onClick={goToFoood}
          />
          <MenuItem
            img={Points}
            name="Points"
            height="11rem"
            textColor={pointsColor}
            onClick={goToPoints}
          />
        </div>
        <Footer color="#00e4e5" />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  height: 100vh;

  .grid-middle {
    height: 75vh;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2rem;
    margin: 0 2rem;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: 2rem;
      margin: 0 2rem;
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
      grid-column-gap: 2rem;
      margin: 0 2rem;
    }
  }

  .menu-img {
    height: 20vw;
  }
`;

export default Menu;
