import log from "loglevel";
import React from "react";
// import { Link, useHistory } from "react-router-dom";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Checkbox,
  HelpBlock,
} from "rsuite";
import styled from "styled-components";

import Loading from "../Loading";

const InputForm = ({
  formType,
  onFormSubmit,
  registerModel,
  formRef,
  isLoading,
  isLoadingText,
  formValue,
  setFormValue,
  initialForm,
  fromState,
  history,
  resetPassword,
  setAcceptMailing,
  acceptMailing,
}) => {
  const onFormChange = (value) => {
    //rsuite returns all the form inputs as an object
    setFormValue(value);
  };

  const onFormReset = () => {
    setFormValue(initialForm);
  };

  if (isLoading) {
    return <Loading text={isLoadingText} />;
  }

  const baseName = process.env.REACT_APP_BASENAME;

  log.debug(acceptMailing);
  return (
    <Wrapper>
      <h1
        style={{
          marginBottom: "100px",
          fontFamily: "Roaster Original",
          textTransform: "lowercase",
        }}>
        {formType}
      </h1>
      <Form
        onChange={onFormChange}
        formValue={formValue}
        model={registerModel}
        ref={formRef}>
        {formType === "Register" && (
          <FormGroup>
            <ControlLabel className="ctrl-label">Name</ControlLabel>
            <FormControl name="name" autoComplete="nope" />
            {/* <HelpBlock>Required</HelpBlock> */}
          </FormGroup>
        )}
        <FormGroup>
          <ControlLabel className="ctrl-label">Email</ControlLabel>
          <FormControl
            name="email"
            type="email"
            placeholder="john@email.com"
            autoComplete="username"
          />
          <HelpBlock tooltip>
            If you have previously created an account with us, please register
            again (Your order history will be the same)
          </HelpBlock>
        </FormGroup>
        {formType === "Register" && (
          <FormGroup>
            <ControlLabel className="ctrl-label">Password</ControlLabel>
            <FormControl
              name="password"
              type="password"
              autoComplete="new-password"
            />
          </FormGroup>
        )}
        {formType === "Register" && (
          <div className="mailing-checkbox">
            Tick here to sign up to our freebies and our up to date newsletter
            <Checkbox
              onChange={() => setAcceptMailing(!acceptMailing)}
              value={true}
            />
          </div>
        )}
        {formType !== "Register" && (
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
            <ControlLabel className="ctrl-label">Password</ControlLabel>
            <FormControl
              name="password"
              type="password"
              autoComplete="current-password"
            />
          </FormGroup>
        )}

        <FormGroup className="center mt-top-5">
          {formType !== "Register" ? (
            <div className="button-container-login">
              <div className="submit-btn-container">
                <Button
                  className="submit-btn"
                  appearance="primary"
                  onClick={onFormSubmit}
                  type="submit">
                  Submit
                </Button>
                <Button
                  className="submit-btn"
                  appearance="default"
                  onClick={onFormReset}>
                  Cancel
                </Button>
              </div>
              <div className="forgot-password-container">
                <button
                  className="forgot-pass-btn"
                  type="button"
                  onClick={resetPassword}>
                  Forgot password?
                </button>
              </div>
            </div>
          ) : (
            <div className="submit-btn-register-container">
              <div className="submit-btn-register">
                <Button
                  className="submit-btn"
                  appearance="primary"
                  onClick={onFormSubmit}
                  type="submit">
                  Submit
                </Button>
                <Button
                  className="submit-btn"
                  appearance="default"
                  onClick={onFormReset}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </FormGroup>
      </Form>

      {formType === "Register" ? (
        <div className="change-signin-container">
          Already have an account? &nbsp;
          <button
            className="instead-btn"
            onClick={() => history.push(`${baseName}/login`, fromState)}>
            Login instead
          </button>
        </div>
      ) : (
        <div className="change-signin-container">
          Don't have an account? &nbsp;
          <button
            className="instead-btn"
            onClick={() => history.push(`${baseName}/register`, fromState)}>
            Register instead
          </button>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* font-family: "Roaster Original";
  text-transform: lowercase; */
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;

  .instead-btn {
    background-color: transparent;
    /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
    border-bottom: 1px solid black;
    margin-top: 2rem;
    font-family: "Roaster Original" !important;
    text-transform: lowercase;
  }

  .ctrl-label {
    font-family: "Roaster Original";
    text-transform: lowercase;
  }

  .change-signin-container {
    margin-bottom: 1rem;
    /* font-family: "Roaster Original"; */
    /* text-transform: lowercase; */
  }

  .button-container-login {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  .submit-btn-container {
    /* border: 1px solid blue; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 25% 0 25%;
  }

  .forgot-password-container {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      background-color: transparent;
    }
  }

  .submit-btn-register-container {
    width: 100%;
  }
  .submit-btn-register {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 25% 0 25%;
  }

  .mailing-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Roaster Original";
    text-transform: lowercase;
    width: 15rem;

    /* font-size: 0.4rem; */
  }

  .mt-top-5 {
    margin-top: 3rem;
  }
`;

export default InputForm;
