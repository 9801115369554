import React, { useEffect } from "react";
import styled from "styled-components";
import { colors } from "../constants/constants";
import UniLogo from "../../resources/img/sotonUni/southampton-uni-logo.png";
import NClogo from "../../resources/img/sotonUni/naked-coffee-logo-blue.png";
import StoreHub from "../../resources/img/sotonUni/store-hub.png";
import { Animated } from "react-animated-css";
import { useState } from "react";

const Landing = () => {
  const [isVisible1, setIsVisible1] = useState(true);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible1(false);
      setIsVisible2(true);
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible2(false);
      setIsVisible3(true);
    }, 3000);
  }, []);

  return (
    <Wrapper style={{ backgroundColor: colors.primaryColor }}>
      <Animated
        animationIn="bounceInLeft"
        animationOut="bounceOutRight"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={isVisible1}>
        <img src={UniLogo} alt="uni-logo" />
      </Animated>
      <Animated
        animationInDelay={500}
        animationInDuration={1000}
        animationOutDuration={1000}
        animationIn="bounceInLeft"
        animationOut="bounceOutRight"
        animateOnMount={false}
        isVisible={isVisible2}>
        <img src={NClogo} alt="naked-coffee-logo" />
      </Animated>
      <Animated
        animationInDelay={500}
        animationInDuration={1000}
        animationOutDuration={1000}
        animationIn="bounceInLeft"
        animationOut="bounceOutRight"
        animateOnMount={false}
        isVisible={isVisible3}>
        <img src={StoreHub} alt="store-hub" />
      </Animated>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;

  max-width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "BradyBunchRemastered";
  position: relative;

  img {
    height: 25vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 600px) {
      height: 50vw;
    }
  }
`;

export default Landing;
