import log from "loglevel";
import React, { useState } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Alert } from "rsuite";
import styled from "styled-components";
import Logo from "../../resources/img/logo.png";
import Button from "../../components/Button";
import { useHistory } from "react-router";
import firebase from "firebase/app";

import Loading from "../../components/Loading";

const baseName = process.env.REACT_APP_BASENAME;

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    newPassword1: "",
    newPassword2: "",
  });

  let history = useHistory();
  log.debug("history.location:", history.location);

  const { oobCode, continueUrl, lang } = history.location.state;

  log.debug("actionCode type:", oobCode);
  log.debug("continueUrl:", continueUrl);
  log.debug("lang:", lang);

  const handleResetPassword = async () => {
    setIsLoading(true);

    if (
      formValue?.newPassword1?.length === 0 ||
      formValue?.newPassword2?.length === 0
    ) {
      Alert.error("New password must not be empty");
      return;
    }

    if (formValue?.newPassword1 !== formValue?.newPassword2) {
      Alert.error("Passwords do not match");
      return;
    }
    let firebaseConfig = {
      apiKey: "AIzaSyAX1wj3tIa3EjNAxv7mSOnp46hFlGe1GXA", // Copy this key from the web initialization
      // snippet found in the Firebase console.
    };
    const appName = "app" + String(new Date().getTime());
    const app = firebase.initializeApp(firebaseConfig, appName);
    const auth = app.auth();

    auth
      .verifyPasswordResetCode(oobCode)
      .then((email) => {
        log.debug(email);
        const newPassword = formValue.newPassword1;
        log.debug("newPass:", newPassword);
        auth
          .confirmPasswordReset(oobCode, newPassword)
          .then((resp) => {
            // Password reset has been confirmed and new password updated.
            // TODO: Display a link back to the app, or sign-in the user directly
            setIsLoading(false);
            Alert.success("succesfully changed password");
            //delete app before pushing
            app.delete();
            history.push(`${baseName}/login`);
            // if the page belongs to the same domain as the app:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.

            // perform login funtction to obtain token and then send this to app via url parameter
            // try {
            //   const resp = await axios.post(
            //     `${process.env.REACT_APP_API_ADDRESS}/login_user_by_email_password`,
            //     formValue
            //   );

            //   log.debug("login_user resp object: ", resp);
            //     const authToken = resp.data.token;

            //     // save token to local storage
            //     window.localStorage.setItem("auth_token", JSON.stringify(authToken));

            //     // alert(resp.data.is_admin);

            //     // save user and token to context
            //     await loginUser(
            //       resp?.data?.uid || "",
            //       resp?.data?.name || "",
            //       resp?.data?.email || "",
            //       resp?.data?.firebase_token || "",
            //       resp?.data?.ecwid_token || "",
            //       resp?.data?.shippingAdresses || "",
            //       resp?.data?.loyalty_points,
            //       resp?.data?.is_admin || false,
            //       resp?.data?.is_staff || false,
            //       resp?.data?.email_verified
            //     );

            //   setIsLoading(false);
            // } catch (error) {
            //   setIsLoading(false);
            // }
          })
          .catch((error) => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            app.delete();
            setIsLoading(false);
            log.debug(error?.message);
            Alert.error(error?.message);
          });
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        app.delete();
        setIsLoading(false);
        log.debug("Something went wrong, Please try again");
      });
  };

  const handleChange = (value) => {
    setFormValue(value);
  };

  if (isLoading) {
    return <Loading text="Resetting your password" />;
  }

  return (
    <Wrapper>
      <img src={Logo} alt="logo" />
      <Form onChange={handleChange} formValue={formValue}>
        <FormGroup>
          <ControlLabel
            style={{
              fontFamily: "Roaster original",
              textTransform: "lowercase",
            }}>
            Enter Your New Password
          </ControlLabel>
          <FormControl
            name="newPassword1"
            type="password"
            autoComplete="new-password"
          />
          <ControlLabel
            style={{
              fontFamily: "Roaster original",
              textTransform: "lowercase",
              marginTop: "1rem",
            }}>
            Confirm Your New Password
          </ControlLabel>
          <FormControl
            name="newPassword2"
            type="password"
            autoComplete="new-password"
          />
        </FormGroup>
        <div>
          <Button
            style={{
              fontFamily: "Roaster Original",
              textTransform: "lowercase",
              width: "7rem",
            }}
            size="medium"
            appearance="secondary"
            onClick={() => handleResetPassword()}>
            Confirm
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 15vh 0;

  img {
    height: 10rem;
  }

  div {
    margin-top: 1rem;
    button {
      margin-top: 1rem;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export default ResetPassword;
