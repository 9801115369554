export const AllStoreData = [
  {
    lat: 50.7221576,
    lng: -1.8656385,
    label: "Naked coffee lansdowne",
    value: "store-1",
  },
  {
    lat: 50.7188825,
    lng: -1.9830516,
    label: "Naked coffee Beales",
    value: "store-2",
  },
  {
    lat: 50.7230799,
    lng: -1.8673402,
    label: "Naked coffee Deli Lansdowne",
    value: "store-3",
  },
  {
    lat: 50.7327408,
    lng: -1.9681917,
    label: "Marcos House",
    value: "store-4",
  },
  {
    lat: 50.7403121,
    lng: -1.8996604,
    label: "Naked coffee BU",
    value: "store-5",
  },
  {
    label: "Online Store",
    value: "online_store",
  },
];

export const storeLocationData = [
  {
    lat: 50.7221576,
    lng: -1.8656385,
    label: "Naked coffee lansdowne",
    value: "store-1",
  },
  {
    lat: 50.7188825,
    lng: -1.9830516,
    label: "Naked coffee Beales",
    value: "store-2",
  },
  {
    lat: 50.7230799,
    lng: -1.8673402,
    label: "Naked coffee Deli Lansdowne",
    value: "store-3",
  },
  {
    lat: 50.7327408,
    lng: -1.9681917,
    label: "Marcos House",
    value: "store-4",
  },
  {
    lat: 50.7403121,
    lng: -1.8996604,
    label: "Naked coffee BU",
    value: "store-5",
  },
];

// for admin side
export const AllStoreDataAsArray = [
  "store-1",
  "store-2",
  "store-3",
  "store-4",
  "store-5",
  "online_store",
];
