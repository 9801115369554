import log from "loglevel";
import React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { Button, CheckboxGroup, Checkbox, Alert } from "rsuite";
import { useState } from "react";
import axios from "axios";
import {
  AllStoreDataAsArray,
  storeLocationData,
} from "../../../data/store-location-data";
import Loading from "../../../components/Loading";
import GoBackBtn from "../../../components/GoBackBtn";
import { Bar } from "react-chartjs-2";

const baseName = process.env.REACT_APP_BASENAME;

const SalesReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState({
    orders: null,
    selectedStores: [],
    checkAll: false,
  });
  let history = useHistory();
  let { type } = useParams();

  const goBack = () => {
    history.push(`${baseName}/mystore/selectsales`);
  };

  const whichOrdersToFetch = async () => {
    if (orders.selectedStores.length === 0) {
      Alert.info("Please select a checkbox option");
    }
    //we only want certain store orders
    setIsLoading(true);
    for (let i = 0; i < orders.selectedStores.length; i++) {
      //   call backend with each store
      await fetchStoreOrders(orders.selectedStores[i]);
      // alert(orders.selectedStores[i]);
    }
    setOrders((oldS) => ({ ...oldS, hasMore: false }));
    setIsLoading(false);
  };

  const fetchStoreOrders = async (store) => {
    const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
    const resp = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/get_store_orders`,
      {
        token: authToken,
        store: store,
      }
    );
    log.debug(resp);
    if (resp.status === 200) {
      if (resp.data === {} || resp.data?.items?.length === 0) {
        setOrders((oldS) => ({ ...oldS, hasMore: false }));
      } else {
        setOrders((oldS) => ({
          ...oldS,
          orders: oldS.orders
            ? oldS.orders.concat(Object.values(resp.data))
            : Object.values(resp.data),
        }));
      }
    }
    log.debug(resp);
  };

  const handleCheckAll = (value, checked) => {
    const nextValue = checked ? AllStoreDataAsArray : [];
    log.debug(nextValue, "handleCheckAll");
    setOrders((oldS) => ({
      ...oldS,
      orders: null,
      offset: 20,
      hasMore: true,
      selectedStores: nextValue,
      checkAll: checked,
    }));
  };

  const handleChange = (value) => {
    log.debug(value, "handleChange");
    setOrders((oldS) => ({
      ...oldS,
      orders: null,
      offset: 20,
      hasMore: true,
      selectedStores: value,
      checkAll: value.length === AllStoreDataAsArray.length,
    }));
  };

  const calcTotalTax = () => {
    let totalTax = 0;
    for (let i = 0; i < orders.orders.length; i++) {
      let currOrder = orders.orders[i];
      totalTax = totalTax + currOrder.tax;
    }
    return totalTax.toFixed(2);
  };

  //graph stuff
  const options = {
    indexAxis: "y",
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: "Payment Methods",
      },
    },
  };
  const data = {
    labels: ["Pay by cash", "Credit cart or debit card"],
    datasets: [
      {
        label: "Items Sold",
        data: [12, 105],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  log.debug(orders);
  return (
    <>
      {isLoading && (
        <div className="centre-blur">
          <Loading />
        </div>
      )}
      <Wrapper>
        <GoBackBtn goBack={goBack} />
        <div className="wrapper">
          <div className="header-orders">
            <h1>Your {type} sales</h1>
            <Button onClick={whichOrdersToFetch} appearance="ghost">
              Fetch SalesReport
            </Button>
          </div>

          <div className="orders-data-container">
            <div className="orders-controls">
              <Checkbox checked={orders.checkAll} onChange={handleCheckAll}>
                Check all
              </Checkbox>
              <hr />
              <CheckboxGroup
                name="checkboxList"
                value={orders.selectedStores}
                onChange={handleChange}>
                {storeLocationData.map((store) => {
                  return <Checkbox value={store.value}>{store.label}</Checkbox>;
                })}
              </CheckboxGroup>
            </div>
            <div className="data-container">
              {orders.orders ? (
                <>
                  <p>total tax: £{calcTotalTax()}</p>
                  <p>Number of sales: {orders.orders.length}</p>
                  {/* payment graph here  */}
                  <div className="chart-container">
                    <Bar data={data} options={options} />
                  </div>
                </>
              ) : (
                <p>will display data here</p>
              )}
            </div>

            <div></div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  height: auto;
  position: relative;

  .go-back-btn {
    top: 2rem;
  }

  .header-orders {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 20vh;
    h1 {
      font-family: "Roaster Original";
      text-transform: lowercase;
    }
  }
  .wrapper {
    height: 100vh;
    display: grid;
    grid-template-rows: 1fr 3fr;
  }

  .chart-container {
    display: flex;
    align-items: center;
    width: 70%;
  }

  .orders-data-container {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }

  .data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .orders-controls {
    padding-left: 2rem;
  }

  .order-item {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5vw;
    width: 50vw;
    height: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.15);
  }
  .order-item-single-header {
    font-family: "Roaster original";
    text-transform: lowercase;
  }
`;

export default SalesReport;
