import React, { useState } from "react";
// import log from "loglevel";
import Styled from "styled-components";
import Popover from "@material-ui/core/Popover";
import { ImCross } from "react-icons/im";

import StoreDetails from "../resources/details/store-finder-details";
import AnimatedText from "./AnimatedText";
import Button from "../components/Button";

const StoreFinderGrid = () => {
  const [isPopupActive, setIsPopupActive] = useState(true);
  const [isPaused, setPaused] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  const handleOnHover = (index) => {
    setPaused((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  const handleOnLeave = (index) => {
    setPaused((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  // setTimeout(() => {
  //   removePopup();
  // }, 10000);

  const removePopup = () => setIsPopupActive(false);

  const openMaps = (link, shop) => {
    window.gtag("event", `open ${shop.store} map`, {
      event_category: "engagement",
      event_label: "search_term",
    });
    window.open(link);
  };

  return (
    <>
      <Wrapper className="store-finder" onClick={removePopup}>
        <Popover
          anchorReference="none"
          disableEnforceFocus={true}
          className="clickable-popup"
          open={isPopupActive}
          marginThreshold={0}>
          <ImCross className="pop-up-cross" onClick={removePopup} />
          <img
            src={StoreDetails[5].img}
            alt="store manager"
            className="pop-up-for-store-finder"
          />
          <p className="pop-up-text">
            Hi guys, hover over each speech bubble to activate one of the team,
            click on the button to find the store
          </p>
        </Popover>
        <div>
          <h1 className="title">Find a naked Hang out (store)</h1>
        </div>

        <div className="store-finder-container">
          {StoreDetails.map((shop, i) => {
            const { store, img, text, colour, link } = shop;
            return (
              <div key={i} className="single-store-container">
                <div className="left">
                  <div
                    className="centering store-title"
                    style={{
                      textAlign: "center",
                      fontFamily: "Porkys",
                      textTransform: "capitalize",
                      textShadow: `2px 2px ${colour}`,
                    }}>
                    {store}
                  </div>
                  <div className="centering">
                    <img
                      src={img}
                      alt="store manager"
                      onMouseOver={() => handleOnHover(i)}
                      onMouseLeave={() => handleOnLeave(i)}
                      // className={`${storeManager === "Rob" && `robImgleft`}`}
                    />
                  </div>
                </div>
                <div className="right">
                  <div></div>
                  {isPaused[i] ? (
                    <div
                      onMouseLeave={() => handleOnLeave(i)}
                      className="speech-bubble"
                      style={{
                        backgroundColor: colour,
                        textAlign: "center",
                        textTransform: "lowercase",
                        padding: "10px",
                      }}>
                      <AnimatedText text={text} link={link} />
                      <Button
                        appearance="primary"
                        size="small"
                        style={{
                          width: "8rem",
                          marginTop: "10px",
                        }}
                        onClick={() => openMaps(link, shop)}>
                        store
                      </Button>
                    </div>
                  ) : (
                    <div
                      onMouseOver={() => handleOnHover(i)}
                      className="speech-bubble centering"
                      style={{
                        backgroundColor: colour,
                        textAlign: "center",
                        textTransform: "lowercase",
                        fontFamily: "Roaster Original",
                        fontSize: "1.2rem",
                      }}>
                      Touch me!
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* <div className="store-finder-map centering">
          <img src={map} alt="map" />
        </div> */}
      </Wrapper>
    </>
  );
};

const Wrapper = Styled.section`
.title{
  text-align: center;
  text-transform: capitalize;
  font-family: "Porkys";
  text-shadow: 2px 2px #ff0000;
  font-size: 3rem;
}

 @media screen and (max-width: 800px) {
      .title{
        font-size: 2rem;
      }
 }

.left{
  display: grid;
  grid-template-rows: 1fr 2fr; 
    div{
        img{
          height: 9rem;
        }
    }
}


.right{
  display: grid;
  grid-template-rows: 1fr 2fr; 
    a{
      text-decoration: none;
      color: rgb(75,75,75);
      -webkit-text-shadow: .5px .5px #fff;
      text-shadow: .5px .5px #fff;
      padding: 5px;
      }
  }

  @media screen and (max-width: 600px) {
      .right{
      display: block;
      grid-template-rows: 1fr; 
      }
  }
  

  .store-title{
    font-size: 1.5rem;
    width: 200%;
  }

  @media screen and (max-width: 600px) {
    .store-title{
      width: 90vw; //as grid becomes 1 column
      /* margin: 0 auto; */
    }
 }

  @media screen and (max-width: 600px) {
    .robImgleft{
      height: 7rem !important;
      /* transform: translateX(-10px); */
    }
}

`;

export default StoreFinderGrid;
