import log from 'loglevel';
import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import { useParams, useHistory, Link } from "react-router-dom";
import { ImCross } from "react-icons/im";

import SingleBean from "./SingleBean";
import { useAppContext } from "../../ecwid/context/product_context";
import Loading from "../Loading";
import StoreDetails from "../../resources/details/store-finder-details";

const baseName = process.env.REACT_APP_BASENAME;

const SingleBeansContainer = () => {
  const [isPopupActive, setIsPopupActive] = useState(true);
  const { products, loading } = useAppContext();
  const history = useHistory();
  const prevProductState = history?.location?.productState;

  let { id } = useParams();
  id = +id;

  const removePopup = () => setIsPopupActive(false);

  // setTimeout(() => {
  //   removePopup();
  // }, 10000);

  const product = products?.beans?.items?.find((product) => product.id === id);
  log.debug("SingleMerchContainer product");
  log.debug(product);

  log.debug("history object:");
  log.debug(history);
  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (!product) {
    return (
      <>
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "15vh",
          }}>
          Product not found
        </h2>
        <Link className="btn btn-home" to={`${baseName}/beans`}>
          Back to beans page
        </Link>
      </>
    );
  } else {
    return (
      <section onClick={removePopup}>
        <Popover
          anchorReference="none"
          disableEnforceFocus={true}
          className="clickable-popup"
          open={isPopupActive}
          marginThreshold={0}>
          <ImCross className="pop-up-cross" onClick={removePopup} />
          <img
            src={StoreDetails[4].img}
            alt="store manager"
            style={{ transform: "translateX(-8%)" }}
            className="pop-up-avatar"
          />
          <p className="pop-up-text">
            Hi I'm Rob and im here to help you choose the best blend for you!
            Feel free to ask me any questions:{" "}
            <a href="mailto:info@nakedcoffee.co">Email me</a>
          </p>
        </Popover>
        <SingleBean
          key={id}
          product={product}
          prevProductState={prevProductState}
          goBack={history.goBack}
        />
      </section>
    );
  }
};

export default SingleBeansContainer;
