import log from "loglevel";
import React, { useState, useContext, useEffect } from "react";
// import { useCallback } from "react";

/* Following are for Marcos Hardy test store 
const storeId = "32429266";
const token = "public_pug5xGzLhCTTEWRf9ziAjGZ61Psjke1F";
const categoryId = "72312951";
*/

/* Naked Coffee production store */
const storeId = "33357088";
const token = "public_pug5xGzLhCTTEWRf9ziAjGZ61Psjke1F";
//const categoryId = "72636417";
const categories = { merch: 74911328, beans: 74915258, instore: 107695989 };

const productsUrl = `https://app.ecwid.com/api/v3/${storeId}/products?limit=100&token=${token}&withSubcategories=true&sortBy=DEFINED_BY_STORE_OWNER&enabled=true&category=`;
const profileUrl = `https://app.ecwid.com/api/v3/${storeId}/profile?token=${token}&showExtendedInfo=true`;

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    try {
      const products = {};

      for (const categoryName in categories) {
        const response = await fetch(productsUrl + categories[categoryName]);
        products[categoryName] = await response.json();
      }

      const profileResponse = await fetch(profileUrl);
      products.profile = await profileResponse.json();

      log.debug("products object from ecwid:");
      log.debug(products);
      setProducts(products);

      setLoading(false);
    } catch (error) {
      log.debug(error);
    }
  };

  // const getSingleImg = async (prodId) => {
  //   log.debug(prodId + "product id given to contetx");
  //   const data = await fetch(
  //     `https://app.ecwid.com/api/v3/${storeId}/products/${prodId}/`
  //   );
  //   const resp = await data.json();
  //   log.debug(resp);
  // };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <AppContext.Provider value={{ loading, products }}>
      {children}
    </AppContext.Provider>
  );
};

// make sure use
export const useAppContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
