import React, { useEffect, useRef, useState } from "react";
import RegisterForm from "./SotonUniInputForm";
// import axios from "axios";
import { Alert } from "rsuite";
import { useHistory } from "react-router-dom";

const initialForm = {
  password: "",
};

const baseName = process.env.REACT_APP_BASENAME;

const Login = () => {
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(initialForm);

  //location to redirect after user logs in
  let history = useHistory();

  let redirectTo = "sotonuni";

  useEffect(() => {
    if (window.localStorage.getItem("upid") === "jkadfbsjkdb") {
      history.push(`${baseName}/${redirectTo}/`);
    }
    // eslint-disable-next-line
  }, []);

  const onFormSubmit = async () => {
    setIsLoading(true);
    if (!formRef.current.check()) {
      setIsLoading(false);
      Alert.error("Wrong Login Details");
      return;
    }

    if (formValue.password === "1234567") {
      Alert.success("Succesfully Logged You in");
      window.localStorage.setItem("upid", "jkadfbsjkdb");
      setIsLoading(false);
      history.push(`${baseName}/${redirectTo}/`);
    } else {
      Alert.error("Invalid Password");
      setIsLoading(false);
      return;
    }

    // try {
    //   const resp = await axios.post(
    //     `${process.env.REACT_APP_API_ADDRESS}/login_user`,
    //     formValue
    //   );

    //   log.debug("login_user resp object: ", resp);

    //   if (resp.status === 200) {
    //     const authToken = resp.data.token;

    //     // save token to local storage
    //     window.localStorage.setItem("auth_token", JSON.stringify(authToken));

    //     // save user and token to context
    //     await loginUser(
    //       resp?.data?.uid || "",
    //       resp?.data?.name || "",
    //       resp?.data?.email || "",
    //       resp?.data?.firebase_token || "",
    //       resp?.data?.isAdmin || false
    //     );

    //     //show success alert
    //     Alert.success(`Succesfully logged you in`);
    //     //by default if no history push to myip
    //     log.debug("redirectTo", redirectTo);
    //     window.location.reload();
    //     // history.push(`${baseName}/${redirectTo ? redirectTo : "myip"}`);
    //   }
    //   setIsLoading(false);
    // } catch (error) {
    //   setIsLoading(false);
    //   log.debug(error.message);
    //   Alert.error(error.message);
    // }
  };

  // const trialFunc = async () => {
  //   try {
  //     const resp = await axios.get(
  //       `${process.env.REACT_APP_API_ADDRESS}/practice`
  //     );
  //     log.debug("this is the respone");
  //     log.debug(resp);
  //   } catch (error) {
  //     log.debug(error);
  //   }
  // };z

  return (
    <>
      {/* <Navigation /> */}
      <RegisterForm
        onFormSubmit={onFormSubmit}
        formRef={formRef}
        isLoading={isLoading}
        formValue={formValue}
        setFormValue={setFormValue}
        initialForm={initialForm}
      />
    </>
  );
};

export default Login;
