import log from 'loglevel';
import React from "react";
import { useHistory, useParams } from "react-router";

const FullSingleOrder = () => {
  const { id } = useParams();
  const history = useHistory();
  const order = history.location.state;
  log.debug(order);
  return (
    <>
      <div>{id}</div>
    </>
  );
};

export default FullSingleOrder;
