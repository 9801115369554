import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import Popover from "@material-ui/core/Popover";
import log from "loglevel";
import { useParams, useHistory } from "react-router-dom";
import SingleMerch from "./SingleMerch";
import { useAppContext } from "../../ecwid/context/product_context";

import StoreDetails from "../../resources/details/store-finder-details";

const SingleMerchContainer = () => {
  const [isPopupActive, setIsPopupActive] = useState(true);
  const { products, loading } = useAppContext();
  const history = useHistory();
  log.debug("histort:", history);
  const prevProductState = history?.location?.state?.productState;

  const removePopup = () => setIsPopupActive(false);

  let { id } = useParams();
  id = +id;

  const product = products?.merch?.items?.find((product) => product.id === id);

  setTimeout(() => {
    removePopup();
  }, 10000);

  if (loading) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  } else if (!product) {
    return (
      <>
        <p>Product not found</p>
      </>
    );
  } else {
    return (
      <section onClick={removePopup}>
        <Popover
          anchorReference="none"
          disableEnforceFocus={true}
          className="clickable-popup"
          open={isPopupActive}
          marginThreshold={0}>
          <ImCross className="pop-up-cross" onClick={removePopup} />
          <img
            src={StoreDetails[5].img}
            alt="store manager"
            className="pop-up-avatar"
          />
          <p className="pop-up-text">
            Hi I'm Sooney and im here to help you pick a naked style! Feel free
            to ask me any questions: {""}
            <a href="mailto:info@nakedcoffee.co">Email me</a>
          </p>
        </Popover>
        <SingleMerch
          key={id}
          productId={product.id}
          product={product}
          prevProductState={prevProductState}
          goBack={history.goBack}
        />
      </section>
    );
  }
};

export default SingleMerchContainer;
