import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";

import FoodImg from "../../resources/img/instore/food.png";

import { foodColor } from "./menu/constants";

const Food = () => {
  return (
    <div className="instore-grid">
      <Header img={FoodImg} color={foodColor} />
      <div></div>
      <Footer color={foodColor} />
    </div>
  );
};

export default Food;
