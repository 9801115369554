import React, { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import { useAuth } from "../../ecwid/context/auth_context";
import { getInitials } from "../../helpers/helper";
import log from "loglevel";
import Footer from "../../components/Footer";

const baseName = process.env.REACT_APP_BASENAME;

const MyVip = () => {
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const { currentUser, logoutUser } = useAuth();
  log.debug("marcos".split(" ").length);
  let history = useHistory();

  const logoutCurrentUser = async () => {
    setIsLogoutLoading(true);
    await logoutUser();
    setIsLogoutLoading(false);
  };

  if (isLogoutLoading) {
    return <Loading text="loggin you out" />;
  }

  return (
    <>
      <Wrapper>
        <Button
          onClick={() => history.push(`${baseName}/vip`)}
          shouldHover={false}
          className="go-back-single"
          appearance="secondary"
          size="medium"
          style={{ width: "6rem", backgroundColor: "#ed1e79" }}>
          <span className="go-to-discount-arrow-left">&#10094;</span>
          go back
        </Button>
        <div className="avatar-container">
          <div className="avatar">
            <span>{getInitials(currentUser)}</span>
          </div>
        </div>
        <div className="vip-centre">
          <h1>Super hero V.I.P</h1>
          <p>
            we can't promise you super powers but we can give you some super
            discounts & deals.
          </p>
        </div>
        <div className="vip-btn-container">
          {/* <Button
            className="btn-aft-10r"
            onClick={() => history.push(`${baseName}/mydiscounts`)}
            shouldHover={false}
            appearance="secondary"
            size="large"
            style={{
              width: "10rem",
              backgroundColor: "#94c43e",
              position: "relative",
              borderRadius: "100rem",
            }}>
            discounts
          </Button> */}
          <Button
            className="btn-aft-10r"
            onClick={() => history.push(`${baseName}/myorders`)}
            shouldHover={false}
            appearance="secondary"
            size="large"
            style={{
              width: "10rem",
              backgroundColor: "#f15a24",
              position: "relative",
              borderRadius: "100rem",
            }}>
            orders
          </Button>
          <Button
            className="btn-aft-10r"
            onClick={() => history.push(`${baseName}/mydetails`)}
            shouldHover={false}
            appearance="secondary"
            size="large"
            style={{
              width: "10rem",
              backgroundColor: "#f9c116",
              position: "relative",
              borderRadius: "100rem",
            }}>
            my details
          </Button>
          <Button
            className="btn-aft-10r"
            onClick={logoutCurrentUser}
            shouldHover={false}
            appearance="secondary"
            size="large"
            style={{
              width: "10rem",
              backgroundColor: "#29ABE2",
              position: "relative",
              borderRadius: "100rem",
            }}>
            logout
          </Button>
          {currentUser?.isAdmin && (
            <Button
              className="btn-aft-10r"
              onClick={() => history.push(`${baseName}/mystore`)}
              shouldHover={false}
              appearance="secondary"
              size="large"
              style={{
                width: "10rem",
                backgroundColor: "#f15a24",
                position: "relative",
                borderRadius: "100rem",
              }}>
              Admin
            </Button>
          )}
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};

const Wrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  min-height: 100vh;

  @media (max-width: 1000px) {
    grid-template-rows: 1fr 1fr 2fr;
  }

  button {
    font-family: "Roaster Original";
    text-transform: lowercase;
  }

  .go-back-single {
    position: absolute;
    top: 5vh;
    left: 5vw;
    font-family: "Roaster Original";
    border-radius: 10rem;
  }

  .go-back-single:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 1.7rem;
    width: 5.7rem; //as width is 6rem
    border: 2px solid white;
    border-radius: 10rem;
  }

  .vip-centre {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Roaster Original";
    text-transform: lowercase;

    h1 {
      -webkit-text-stroke: 1px white;
      text-shadow: 0px 4px 4px #ed1e79;
      font-size: 3.5rem;
      @media (max-width: 900px) {
        font-size: 3rem;
      }
      @media (max-width: 600px) {
        font-size: 2.3rem;
      }
      @media (max-width: 350px) {
        font-size: 1.7rem;
      }
    }

    p {
      margin-top: 2rem;
      font-size: 2rem;
      max-width: 80%;
      text-align: center;
      @media (max-width: 900px) {
        font-size: 1.5rem;
      }
    }
  }

  .vip-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10vw;

    @media (max-width: 1000px) {
      flex-direction: column;
      margin: 3rem 0;
    }
  }

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar {
    background-color: rgba(25, 25, 25, 0.9);
    color: white;
    height: 8rem;
    width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100rem;
    font-family: "Roaster Original";

    span {
      font-size: 3rem;
    }
  }
`;

export default MyVip;
