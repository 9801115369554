import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ImFacebook, ImTwitter, ImInstagram, ImGoogle } from "react-icons/im";

function Navigation({ adminNav = null }) {
  const [isBtnActive, setBtnActive] = useState(false);

  const toggleClass = () => {
    setBtnActive(!isBtnActive);
  };

  const baseName = process.env.REACT_APP_BASENAME;

  return (
    <>
      <div
        className={
          isBtnActive ? "navigation-btn active-btn change" : "navigation-btn"
        }
        onClick={toggleClass}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <div
        className={isBtnActive ? "navigation active" : "navigation"}
        onClick={toggleClass}>
        {/* buttons */}
        <li>
          <Link to={`${baseName}/`} className="hv-1">
            <i className="fas fa-store mg-right hv"></i> Home
          </Link>
        </li>
        <li>
          <Link
            to={`${baseName}/about`}
            className="hv-2"
            style={{ textTransform: "lowercase" }}>
            {/* <GiCoffeeCup className="mg-right hv" /> */}
            <i className="far fa-list-alt mg-right hv"></i> About
          </Link>
        </li>
        <li>
          <Link
            to={`${baseName}/beans`}
            className="hv-3"
            style={{ textTransform: "lowercase" }}>
            {/* <GiCoffeeBeans className="mg-right hv" /> */}
            <i className="fas fa-leaf  mg-right hv"></i> blends
          </Link>
        </li>
        <li>
          <Link
            to={`${baseName}/merch`}
            className="hv-4"
            style={{ textTransform: "lowercase" }}>
            <i className="fas fa-tshirt mg-right hv"></i> Merch
          </Link>
        </li>
        <li>
          <Link
            to={`${baseName}/cart`}
            className="hv-5"
            style={{ textTransform: "lowercase" }}>
            <i className="fas fa-shopping-cart mg-right hv"></i> Cart
          </Link>
        </li>
        <li>
          <Link
            to={`${baseName}/vip`}
            className="hv-6"
            style={{ textTransform: "lowercase" }}>
            <i className="far fa-user-circle mg-right hv"></i> My VIP
          </Link>
        </li>
        <li>
          <Link
            to={`${baseName}/storefinder`}
            className="hv-3"
            style={{ textTransform: "lowercase" }}>
            <i className="fas fa-map-marker-alt mg-right hv"></i> stores
          </Link>
        </li>

        <ul className="social-links">
          <li>
            <a href="https://www.facebook.com/Naked-Coffee-1829657077267211/">
              <ImFacebook className="social-links__icon-1" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/nakedcoffeeuk?lang=en">
              <ImTwitter className="social-links__icon-2" />
            </a>
          </li>
          <li>
            <a href="https://www.google.co.uk">
              <ImGoogle className="social-links__icon-3" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/nakedcoffeeuk/">
              <ImInstagram className="social-links__icon-4" />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navigation;
