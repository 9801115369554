import log from "loglevel";
import React, { useState } from "react";

import { Progress } from "rsuite";
import styled from "styled-components";
import moment from "moment";
import { useAuth } from "../../ecwid/context/auth_context";

const { Circle } = Progress;

const SingleOrder = ({ order, getCustomersOrders }) => {
  const [progressState, setProgressState] = useState({
    status:
      order.fulfillmentStatus === "WILL_NOT_DELIVER"
        ? "fail"
        : order.fulfillmentStatus === "DELIVERED" ||
          order.fulfillmentStatus === "READY_FOR_PICKUP"
        ? "success"
        : null,
    percent:
      order.fulfillmentStatus === "AWAITING_PROCESSING"
        ? 25
        : order.fulfillmentStatus === "PROCESSING"
        ? 50
        : order.fulfillmentStatus === "SHIPPED" ||
          order.fulfillmentStatus === "OUT_FOR_DELIVERY"
        ? 75
        : order.fulfillmentStatus === "DELIVERED" ||
          order.fulfillmentStatus === "READY_FOR_PICKUP" ||
          order.fulfillmentStatus === "RETURNED"
        ? 100
        : 0,
    color:
      order.fulfillmentStatus === "AWAITING_PROCESSING"
        ? "#F15A24"
        : order.fulfillmentStatus === "WILL_NOT_DELIVER"
        ? "blue"
        : order.fulfillmentStatus === "PROCESSING"
        ? "blue"
        : order.fulfillmentStatus === "SHIPPED" ||
          order.fulfillmentStatus === "OUT_FOR_DELIVERY"
        ? "rgb(243, 236, 14)"
        : order.fulfillmentStatus === "DELIVERED" ||
          order.fulfillmentStatus === "READY_FOR_PICKUP" ||
          order.fulfillmentStatus === "RETURNED"
        ? "green"
        : "blue",
  });

  const { currentUser } = useAuth();

  const database = currentUser?.database;

  database.ref("ecwid_callback/orders").on("child_changed", (snapshot) => {
    const data = snapshot.val();
    // alert("data chanegd");
    if (data.uid === currentUser.uid) {
      getCustomersOrders();
      setProgressState((oldS) => oldS);
    }
    log.debug(data.uid);
  });

  return (
    <>
      <Wrapper>
        <div className="header-container">
          {order.fulfillmentStatus === "AWAITING_PROCESSING" && (
            <h3 className="curr-status">order awaiting acknowledgment</h3>
          )}
          {order.fulfillmentStatus === "PROCESSING" && (
            <h3>Order being processed</h3>
          )}
          {order.fulfillmentStatus === "SHIPPED" && (
            <h3>Order is out for delivery</h3>
          )}
          {order.fulfillmentStatus === "DELIVERED" && (
            <h3>Order has been delivered</h3>
          )}
          {order.fulfillmentStatus === "WILL_NOT_DELIVER" && (
            <h3>Order will not be delivered</h3>
          )}
          {order.fulfillmentStatus === "RETURNED" && (
            <h3>Order has been returned</h3>
          )}
          {order.fulfillmentStatus === "READY_FOR_PICKUP" && (
            <h3>Order is ready for collection</h3>
          )}
          {order.fulfillmentStatus === "OUT_FOR_DELIVERY" && (
            <h3>Order is out for delivery</h3>
          )}
        </div>

        <div className="circle-progress">
          <Circle
            showInfo={true}
            strokeLinecap="round"
            percent={progressState.percent}
            strokeColor={progressState.color}
            status={progressState.status}
          />
          {/* <h2 className="center-it">Order</h2> */}
        </div>

        <div className="order-details">
          <p className="order-item">Order: #{order.id}</p>
          {/* <p>{order.order_data.fulfillmentStatus}</p> */}
          <p className="order-item">
            {moment.unix(order.createTimestamp).format("LLLL")}
          </p>
          <p className="order-item">
            Number of Items Ordered: {order?.items?.length}
          </p>
          {/* <Button
            onClick={() => {
              history.push(
                `${baseName}/mystore/instoreorders/${order.id.toLowerCase()}`,
                order
              );
            }}
            style={{
              fontFamily: "Roaster Original",
              textTransform: "lowercase",
              margin: "0.7rem 0",
            }}
            appearance="ghost">
            View Full Order Details
          </Button> */}
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 20vw;
  height: auto;
  background-color: #f7f7f7;
  border-radius: 1rem;
  /* border: 1px solid red; */
  box-shadow: 1px 4px 15px -1px rgba(0, 0, 0, 0.44);
  display: grid;
  grid-template-rows: 0.5fr 1fr 1fr;

  h3 {
    font-family: "Roaster Original" !important;
  }

  @media (max-width: 1000px) {
    width: 40vw;
  }

  @media (max-width: 700px) {
    width: 80vw;
  }

  /* div { */
  /* border: 1px solid red;
  } */

  .order-details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  /* .center-it {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */

  .order-item {
    font-family: "Roaster Original";
    text-transform: lowercase;
    margin: 0.5rem 0;
  }

  .header-container {
    text-align: center;
    font-family: "Roaster Original";
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    /* margin: 1rem 0; */
  }

  .circle-progress {
    /* position: relative; */
    width: 75%;
    margin: 0 auto;
  }
`;

export default SingleOrder;
