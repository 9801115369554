import log from "loglevel";
import React, { useEffect, useState } from "react";
import { Alert } from "rsuite";
import { useHistory } from "react-router-dom";
import { checkout } from "../helpers/helper";
import Loading from "../components/Loading";
import { TiPlus, TiMinus } from "react-icons/ti";
import styled from "styled-components";
import axios from "axios";
import Navigation from "../components/Navigation";
import Button from "../components/Button";
import Logo from "../resources/img/logo.png";
import Footer from "../components/Footer";
import { useAuth } from "../ecwid/context/auth_context";

const baseName = process.env.REACT_APP_BASENAME;
const Cart = () => {
  const [isInitialLoading, setisInitialLoading] = useState(true);
  const [noProducts, setNoProducts] = useState(false);
  const [cartItems, setCartItems] = useState(null);
  const [cartTotal, setCartTotal] = useState(null);

  const { currentUser } = useAuth();
  const userLoggedIn = currentUser?.uid ? true : false;
  log.debug("cartItems:", cartItems);
  log.debug("cartTotal:", cartTotal);
  let history = useHistory();
  const fetchProducts = () => {
    let cart = [];
    window.Ecwid.Cart.get(async (cartItems) => {
      log.debug("cartItems in function:", cartItems);
      if (cartItems.productsQuantity === 0) {
        setNoProducts(true);
        setisInitialLoading(false);
        return;
      }
      cart = cartItems.items;
      //to send to the backend to fetch the full product
      let productIds;
      for (let i = 0; i < cartItems.items.length; i++) {
        if (productIds) {
          // check if its already included in the array
          if (productIds.includes(String(cartItems.items[i].product.id))) {
            continue;
          }
          productIds = productIds.concat(String(cartItems.items[i].product.id));
        } else {
          productIds = [String(cartItems.items[i].product.id)];
        }
      }

      log.debug("productIds:", productIds);
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_API_ADDRESS}/get_products`,
          //prettier-ignore
          {"productIds": productIds}
        );
        // log.debug(resp);
        if (resp.status === 200) {
          log.debug("resp:", resp);
          // check if length of products is equal to length of item
          // because 1 product is the same as 4 variations etc so we need to check
          // loop through each cartItem and append the product for each one
          log.debug(productIds);
          for (let i = 0; i < productIds.length; i++) {
            let currProductId = productIds[i];
            for (let x = 0; x < cart.length; x++) {
              let currCartItem = cart[x];
              log.debug(currCartItem.product.id);
              if (String(currCartItem.product.id) === currProductId) {
                //since the first productId will be position 0 in the resp data
                //we can just append the resp.data.item at position i
                log.debug(cart[i].product);
                log.debug(resp.data.items[i]);
                cart[x].product = { ...resp.data.items[i] };
              }
            }
          }
          log.debug(cart);

          setCartItems(cart);
          setisInitialLoading(false);
          window.Ecwid.Cart.calculateTotal((cartTotal) => {
            // log.debug(cartTotal);
            setCartTotal(cartTotal);
          });
        }
      } catch (error) {
        log.debug(error);
        log.debug(error?.response?.message);
      }
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const initCart = async () => {
      window.Ecwid.Cart.calculateTotal((cartTotal) => {
        log.debug(cartTotal);
        setCartTotal(cartTotal);
      });
      fetchProducts();
    };
    initCart();
  }, []);
  window.Ecwid.OnOrderPlaced.add(function (order) {
    window.Ecwid.Cart.clear();
    window.sessionStorage.removeItem("instore_table");
    window.sessionStorage.removeItem("instore_store");
    // history.push(`${baseName}/instore/menu`);
  });

  const checkUserBeforeCheckout = () => {
    if (!userLoggedIn) {
      // Should take user to login / register page
      window.localStorage.setItem(
        "redirectUrl",
        JSON.stringify(`${baseName}/cart`)
      );
      history.push(`${baseName}/register`, "cart");
    } else {
      checkout();
    }
  };

  const remove = (i) => {
    window.Ecwid.Cart.removeProduct(i);
    fetchProducts();
    Alert.success("Succesfully removed product");
  };
  const addQuantity = async (id, options, qty) => {
    const product = {
      id: id,
      quantity: 1,
      options: options,
      callback: function (success, product, cart) {
        fetchProducts();
      },
    };
    await window.Ecwid.Cart.addProduct(product);
    //await fetchProducts();
  };
  const removeQuantity = async (index) => {
    log.debug("removeQuantity index=", index, " cartItems=", cartItems);
    if (cartItems[index].quantity === 1) {
      await window.Ecwid.Cart.removeProduct(index);
      fetchProducts();
    } else {
      for (let i = index; i < cartItems.length; i++) {
        log.debug("Removing product from cart i=", i);

        // It is correct to use index and not i when
        // calling removeProduct because the cart
        // gets smaller each time we delete an item
        await window.Ecwid.Cart.removeProduct(index);

        const product = {
          id: cartItems[i].product.id,
          quantity:
            i === index ? cartItems[i].quantity - 1 : cartItems[i].quantity,
          options: cartItems[i].options,
          callback: function (success, product, cart) {
            log.debug("Ecwid product add status=", success);
            if (i === cartItems.length - 1) {
              // Last item
              fetchProducts();
            }
          },
        };

        log.debug("Adding product to cart product=", product);
        await window.Ecwid.Cart.addProduct(product);
      }
      //fetchProducts();
    }
  };

  if (isInitialLoading || (!cartTotal && !noProducts)) {
    return <Loading />;
  }

  return (
    <>
      <Wrapper>
        <Navigation />
        <div className="items-card order-items-container">
          <div className="header-container-text">
            <h2>Your Order</h2>
            {noProducts ? (
              <h4>no items</h4>
            ) : (
              <h4>{cartTotal?.cart?.productsQuantity} items</h4>
            )}
            <hr style={{ width: "90%", margin: "0 auto" }} />
          </div>
          <div className="cart-descr-item">
            <div style={{ gridColumn: "1/3" }}>
              <h4>Product Detail:</h4>
            </div>
            <div>
              <h4>Quantity:</h4>
            </div>
            <div>
              <h4>Price:</h4>
            </div>
            <div>
              <h4>Total:</h4>
            </div>
          </div>
          {noProducts ? (
            <div className="no-products">
              <h2>No products</h2>
              <Button
                appearance="secondary"
                size="medium"
                className="start-add-btn"
                onClick={() => {
                  history.push(`${baseName}/beans`);
                }}>
                Start adding products
              </Button>
            </div>
          ) : (
            <div className="cart-item-scroll">
              {cartItems.map((item, i) => {
                let price = cartTotal?.cart?.items[i]?.product?.price;
                log.debug(item);
                return (
                  <div
                    key={item.product.options}
                    className="cart-row-container">
                    <div>
                      <img
                        src={item.product.hdThumbnailUrl}
                        alt={item.product.name}
                      />
                    </div>
                    <div className="single-item-descr-container">
                      <h4>{item.product.name}</h4>
                      {item.options?.Size && <span>{item.options?.Size}</span>}
                      {item.options?.Colour && (
                        <span>{item.options?.Colour}</span>
                      )}
                      {item.options?.Weight && (
                        <span>{item.options?.Weight}</span>
                      )}
                      {item.options?.["Grind Type"] && (
                        <span>{item.options?.["Grind Type"]}</span>
                      )}
                      <button className="remove-btn" onClick={() => remove(i)}>
                        remove
                      </button>
                    </div>
                    <div className="quantity-container">
                      <button
                        onClick={() =>
                          addQuantity(
                            item.product.id,
                            item.options,
                            item.quantity
                          )
                        }>
                        <TiPlus />
                      </button>
                      <span>{item.quantity}</span>
                      <button onClick={() => removeQuantity(i)}>
                        <TiMinus />
                      </button>
                    </div>
                    <div className="cart-price">£{price.toFixed(2)}</div>
                    <div className="total-price">
                      £{(price * cartItems[i]?.quantity).toFixed(2)}
                    </div>

                    <div className="phone-view-items">
                      <div>
                        <div className="phone-total-price">
                          £{(price * cartItems[i]?.quantity).toFixed(2)}
                        </div>
                      </div>
                      <div className="phone-quantity-container">
                        <button
                          onClick={() =>
                            addQuantity(
                              item.product.id,
                              item.options,
                              item.quantity
                            )
                          }>
                          <TiPlus />
                        </button>
                        <span>{item.quantity}</span>
                        <button onClick={() => removeQuantity(i)}>
                          <TiMinus />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="order-summary-container">
          {/* top right block */}
          <div className="cart-card logo-cart-container">
            <img src={Logo} alt="logo" className="logo" />
          </div>
          {/* bottom right block */}
          <div className="cart-card order-total-container">
            <div>
              <h3>Order Details</h3>
              <hr />
            </div>
            <div className="order-details-container">
              <div className="detail-container">
                <p>Subtotal (excluding VAT):</p>
                <span>
                  £ {noProducts ? "-" : cartTotal.subtotalWithoutTax.toFixed(2)}
                </span>
              </div>

              <div className="detail-container">
                <p>VAT</p>{" "}
                <span>£ {noProducts ? "-" : cartTotal.tax.toFixed(2)}</span>
              </div>
              <div className="detail-container">
                <p>Order Total:</p>{" "}
                <span>£ {noProducts ? "-" : cartTotal.total.toFixed(2)}</span>
              </div>
              <div className="detail-container">
                <p style={{ fontSize: "0.6rem", color: "rgba(25,25,25, 0.5)" }}>
                  Shipping is calculated at checkout
                </p>
              </div>
            </div>
            <div className="basket-btn-container">
              <Button
                style={{
                  fontFamily: "Roaster Original",
                  textTransform: "lowercase",
                }}
                onClick={() => checkUserBeforeCheckout()}
                size="medium"
                className="basket-btn checkout-btn"
                appearance="secondary">
                Checkout
              </Button>
              <Button
                style={{
                  fontFamily: "Roaster Original",
                  textTransform: "lowercase",
                }}
                onClick={() => {
                  if (history.location.state === "instore") {
                    history.push(`${baseName}/instore/menu`);
                  } else {
                    history.push(`${baseName}/beans`);
                  }
                }}
                size="medium"
                className="basket-btn"
                appearance="secondary">
                Continue Shopping
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};
const Wrapper = styled.div`
  padding: 2.5% 0;
  height: 100vh;
  display: grid;
  grid-template-columns: 3fr 1fr;

  @media (max-width: 1300px) {
    padding: 2.5vh 0;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-family: "Roaster Original";
    text-transform: lowercase;
  }
  div {
    /* border: 1px solid red; */
  }
  .cart-card {
    width: 90%;
    height: 100%;
    /* margin: 5% 0; */
    border-radius: 0.5rem;
    box-shadow: 2px 2px 15px -2px rgba(0, 0, 0, 0.19);

    @media (max-width: 1300px) {
      height: 50%;
    }
  }
  .items-card {
    width: 90%;
    height: 100%;
    /* margin: 2.5% 0 2.5% 0; */
    border-radius: 0.5rem;
    box-shadow: 2px 2px 15px -2px rgba(0, 0, 0, 0.19);
  }

  //left main side container
  .order-items-container {
    height: 90vh;
    overflow: hidden;
    width: 90%;
    margin: 0 5%;
    display: grid;
    grid-template-rows: 1fr 1fr 5fr;

    @media (max-width: 1300px) {
      height: 60vh;
    }

    @media (max-width: 600px) {
      grid-template-rows: 1fr 5fr;
    }
  }

  /* responsive spanning for the items section */

  .quantity-container {
    @media (max-width: 600px) {
      display: none !important;
    }
  }
  .cart-price {
    @media (max-width: 600px) {
      display: none !important;
    }
  }
  .total-price {
    @media (max-width: 600px) {
      display: none !important;
    }
  }
  .phone-view-items {
    display: none !important;

    @media (max-width: 600px) {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .phone-quantity-container {
    button {
      background-color: transparent;
      font-size: 1rem;
    }
    display: flex;
    flex-direction: column;
  }
  /* end of responsive spanning for the items section */

  .header-container-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    font-family: "Roaster original";
    text-transform: lowercase;
    margin: 0 10%;
    h2 {
      text-shadow: 1.5px 1.5px #f9c116;
    }
    h4 {
      font-family: "Roaster original";
      text-transform: lowercase;
    }
  }

  .cart-descr-item {
    height: 100%;
    /* border: 1px solid green; */
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    div {
      /* border: 1px solid blue; */
      display: flex;
      align-items: center;
      justify-content: center;

      h4 {
        font-family: "Roaster original";
        text-transform: lowercase;
      }
    }
    @media (max-width: 600px) {
      display: none;
    }
  }

  .cart-row-container {
    height: 8rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* border: 1px solid blue; */
    div {
      /* border: 1px solid red; */
      font-size: 1rem;
      font-family: "Roaster original";
      text-transform: lowercase;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 80%;

        @media (max-width: 1300px) {
          height: 50%;
        }
        @media (max-width: 600px) {
          height: 50%;
        }
      }
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .no-products {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      font-family: "Roaster original";
      text-transform: lowercase;
      margin-bottom: 1rem;
    }
  }
  .single-item-descr-container {
    display: flex;
    flex-direction: column;
    /* align-items: center;
justify-content: center; */
    /* border: 1px solid blue; */

    h4 {
      text-align: center;
      font-family: "Roaster original";
      text-transform: lowercase;
    }

    span {
      font-size: 0.7rem;
    }
  }
  .remove-btn {
    color: red;
    background-color: transparent;
  }

  .cart-item-scroll {
    overflow-y: scroll;
  }

  .quantity-container {
    button {
      background-color: transparent;
      font-size: 1rem;
      margin: 0 2rem;
    }
  }
  //end of left main side
  // container of right side
  .order-summary-container {
    display: grid;
    grid-template-rows: 1fr 2fr;
    grid-row-gap: 5%;
    height: 90vh;

    @media (max-width: 1300px) {
      grid-template-rows: none;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 5%;
      height: 100%;
      padding: 2.5% 5%;
      /* border: 1px solid blue; */
      overflow-y: scroll;
    }
    @media (max-width: 600px) {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  //individual container of right side
  .logo-cart-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      height: 10vw;
    }

    @media (max-width: 1300px) {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 600px) {
      margin: 5% 0;
      padding: 2.5%;
      img {
        height: 10rem;
      }
    }
  }
  //individual container of right side
  .order-total-container {
    height: auto;
    overflow-y: hidden;
    display: grid;
    grid-template-rows: 1fr 2fr 2fr;

    @media (max-width: 1300px) {
      overflow-y: visible;

      width: 100%;
      grid-template-rows: 1fr 2fr 1.5fr;
    }

    h3 {
      text-shadow: 1px 1px #86d3fd;
      margin-top: 5%;

      @media (max-width: 1300px) {
        margin-top: 2.5%;
        margin: 0 auto;
      }
    }

    hr {
      margin: 5% 5%;
      @media (max-width: 1300px) {
        display: none;
      }
    }
  }
  .basket-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 1300px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .basket-btn {
    width: 80%;
    margin: 0.5rem 0;
    @media (max-width: 1300px) {
      width: 40%;
      margin: 0 10%;
    }

    @media (max-width: 600px) {
      width: 45%;
      margin: 0 2.5%;
    }
  }

  .checkout-btn {
    color: white;
    background-color: rgb(78, 150, 247);

    &:hover {
      transform: none;
    }
  }

  .start-add-btn {
    width: 10rem;
    font-family: "Roaster original";
  }

  .order-details-container {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
  }
  .detail-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10%;
    font-family: "Roaster Original";
    text-transform: lowercase;
    p {
      font-size: 0.8rem;
    }
    span {
      font-size: 1rem;
    }
  }
  // end of right side
`;
export default Cart;
