import styled from "styled-components";

const HeaderComp = ({ title, text, backgColor }) => {
  return (
    <Wrapper className="header-about">
      <div className="header-about">
        <h1
          style={{
            fontFamily: "BradyBunchRemastered",
            fontWeight: "bold",
            color: backgColor,
            WebkitTextStrokeColor: "white",
            WebkitTextStrokeWidth: "1px",
          }}>
          {title}
        </h1>
        <p className="descr">{text}</p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 2rem;

  h1 {
    font-size: 4rem;
    margin-bottom: 1rem;

    @media screen and (max-width: 600px) {
      font-size: 3rem;
    }
  }

  .descr {
    font-size: 1.4rem;
    font-family: "BradyBunchRemastered";
    /* text-transform: lowercase; */

    @media screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }

  .header-about {
    text-align: center;
  }
`;

export default HeaderComp;
