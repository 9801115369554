import log from "loglevel";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Alert, Button, CheckboxGroup, Checkbox } from "rsuite";
import InfiniteScroll from "react-infinite-scroll-component";
import { useState } from "react";
import axios from "axios";
import {
  AllStoreDataAsArray,
  AllStoreData,
} from "../../data/store-location-data";
import Loading from "../../components/Loading";

const baseName = process.env.REACT_APP_BASENAME;

const Orders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState({
    orders: null,
    nextId: 20,
    hasMore: true,
    selectedStores: [],
    checkAll: false,
  });
  let history = useHistory();

  const goBack = () => {
    history.push(`${baseName}/mystore`);
  };

  const whichOrdersToFetch = async () => {
    if (orders.selectedStores.length === 0) {
      Alert.info("Please select a checkbox option");
    }
    if (orders.checkAll) {
      //   we want to fetch all the orders with paging
      fetchOrders();
    } else {
      //we only want certain store orders
      setIsLoading(true);
      for (let i = 0; i < orders.selectedStores.length; i++) {
        //   call backend with each store
        await fetchStoreOrders(orders.selectedStores[i]);
        // alert(orders.selectedStores[i]);
      }
      setOrders((oldS) => ({ ...oldS, hasMore: false }));
      setIsLoading(false);
    }
  };

  const fetchStoreOrders = async (store) => {
    const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
    const resp = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/get_store_orders`,
      {
        token: authToken,
        store: store,
      }
    );
    log.debug(resp);
    if (resp.status === 200) {
      if (resp.data === {} || resp.data?.items?.length === 0) {
        setOrders((oldS) => ({ ...oldS, hasMore: false }));
      } else {
        setOrders((oldS) => ({
          ...oldS,
          orders: oldS.orders
            ? oldS.orders.concat(Object.values(resp.data))
            : Object.values(resp.data),
        }));
      }
    }
    log.debug(resp);
  };

  const fetchOrders = async () => {
    try {
      const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
      let resp;
      if (orders.orders) {
        log.debug("nextId:", orders.nextId);
        resp = await axios.post(
          `${process.env.REACT_APP_API_ADDRESS}/get_all_orders`,
          {
            token: authToken,
            last_value: orders.nextId,
          }
        );
      } else {
        resp = await axios.post(
          `${process.env.REACT_APP_API_ADDRESS}/get_all_orders`,
          {
            token: authToken,
            last_value: "",
          }
        );
      }
      log.debug(resp);

      if (resp.status === 200) {
        if (resp.data === {}) {
          setOrders((oldS) => ({ ...oldS, hasMore: false }));
        }

        let newOrders = Array.from(Object.values(resp.data));
        let nextId = newOrders[newOrders.length - 1].id;

        //also have to check if its the last item which is when the array is of length 1
        log.debug("newOrders:", nextId);

        if (newOrders.length === 1) {
          setOrders((oldS) => ({
            ...oldS,
            orders: oldS.orders ? oldS.orders.concat(newOrders) : newOrders,
            nextId: nextId,
            hasMore: false,
          }));
        } else {
          newOrders.splice(-1, 1);

          setOrders((oldS) => ({
            ...oldS,
            orders: oldS.orders ? oldS.orders.concat(newOrders) : newOrders,
            nextId: nextId,
          }));
        }
      }
    } catch (error) {
      log.debug(error);
      Alert.error("Something went wrong when trying to fetch your orders");
    }

    // a fake async api call like which sends
    // 20 more records in 1.5 secs
  };

  const handleCheckAll = (value, checked) => {
    const nextValue = checked ? AllStoreDataAsArray : [];
    log.debug(nextValue, "handleCheckAll");
    setOrders((oldS) => ({
      ...oldS,
      orders: null,
      hasMore: true,
      selectedStores: nextValue,
      checkAll: checked,
    }));
  };

  const handleChange = (value) => {
    log.debug(value, "handleChange");
    setOrders((oldS) => ({
      ...oldS,
      orders: null,
      hasMore: true,
      selectedStores: value,
      checkAll: value.length === AllStoreDataAsArray.length,
    }));
  };
  log.debug(orders);
  return (
    <>
      {isLoading && (
        <div className="centre-blur">
          <Loading />
        </div>
      )}
      <Wrapper>
        <Button
          className="go-back-btn"
          onClick={goBack}
          style={{ textTransform: "lowercase" }}>
          &larr; Go Back
        </Button>
        <div className="wrapper">
          <div className="header-orders">
            <h1>Your Orders</h1>
            <Button
              style={{
                fontFamily: "Roaster original",
                textTransform: "lowercase",
              }}
              onClick={whichOrdersToFetch}
              appearance="ghost">
              Fetch Orders
            </Button>
            {orders.orders && (
              <p>
                Total orders: <span>{orders.orders.length}</span>
              </p>
            )}
          </div>

          <div className="orders-scroll-container">
            <div className="orders-controls">
              <Checkbox checked={orders.checkAll} onChange={handleCheckAll}>
                Check all
              </Checkbox>
              <hr />
              <CheckboxGroup
                name="checkboxList"
                value={orders.selectedStores}
                onChange={handleChange}>
                {AllStoreData.map((store) => {
                  return <Checkbox value={store.value}>{store.label}</Checkbox>;
                })}
              </CheckboxGroup>
            </div>
            <div className="scroll-container">
              {orders.orders && (
                <InfiniteScroll
                  dataLength={orders.orders.length} //This is important field to render the next data
                  next={whichOrdersToFetch}
                  hasMore={orders.hasMore}
                  loader={<h4>Loading...</h4>}
                  endMessage={
                    <p style={{ textAlign: "center", margin: "2rem 0 " }}>
                      <b>
                        Youve reached the end! There are no more orders to see
                      </b>
                    </p>
                  }>
                  {orders.orders.map((orderItem, index) => (
                    <div className="order-item" key={index}>
                      <div>
                        <span className="order-item-single-header">
                          Order Id:
                        </span>{" "}
                        {orderItem.id}
                      </div>
                      <div>
                        <span className="order-item-single-header">Store:</span>{" "}
                        {orderItem?.extraFields?.select_store}
                        {orderItem?.extraFields?.online_store && "online"}
                      </div>
                      <div>
                        <span className="order-item-single-header">
                          Subtotal:
                        </span>{" "}
                        £{orderItem?.subtotal}
                      </div>
                      <div>
                        <span className="order-item-single-header">Tax:</span> £
                        {orderItem?.tax}
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              )}
            </div>

            <div></div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  height: auto;
  position: relative;

  .go-back-btn {
    top: 2rem;
  }

  .header-orders {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 20vh;
    h1 {
      font-family: "Roaster Original";
      text-transform: lowercase;
    }
  }
  .wrapper {
    height: 100vh;
    display: grid;
    grid-template-rows: 1fr 3fr;
  }

  .orders-scroll-container {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }

  .scroll-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .orders-controls {
    padding-left: 2rem;
  }

  .order-item {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5vw;
    width: 50vw;
    height: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.15);
  }
  .order-item-single-header {
    font-family: "Roaster original";
    text-transform: lowercase;
  }
`;

export default Orders;
