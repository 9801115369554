import React, { useState } from "react";
import { useProductState } from "../../../ecwid/reducers/useProductState";
import { addToCart, itemQuantity } from "../../../helpers/helper";
import CardOption from "../../../components/Merch/MerchCardOption";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Button, Panel } from "rsuite";

const baseName = process.env.REACT_APP_BASENAME;

const SingleDrinks = ({ drinkItem }) => {
  const [quantity, setQuantity] = useState(1);
  const [productState, dispatchProductState] = useProductState(drinkItem);

  let history = useHistory();

  const { increaseQty, decreaseQty } = itemQuantity(productState, setQuantity);

  return (
    <>
      <Wrapper>
        <Panel shaded bordered bodyFill className="drinks-card">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src={drinkItem.hdThumbnailUrl}
              alt="product"
              style={{ height: "10rem" }}
            />
          </div>

          <Panel header={drinkItem.name} className="center">
            <div className="drink-btn-container">
              <p style={{ fontSize: "1.5rem" }}>£{drinkItem.price}</p>
            </div>
            <div className="drink-btn-container">
              <Button onClick={increaseQty}>+ 1</Button>
              <p>qty: {quantity}</p>
              <Button onClick={decreaseQty}>- 1</Button>
            </div>

            <div className="drink-btn-container">
              <Button
                style={{ textTransform: "lowercase" }}
                appearance="ghost"
                onClick={() =>
                  addToCart(
                    drinkItem.id,
                    1,
                    productState.options,
                    "instore",
                    history
                  )
                }>
                Add to order
              </Button>
              <Button
                style={{ marginLeft: "1rem", textTransform: "lowercase" }}
                appearance="ghost"
                onClick={() =>
                  history.push(`${baseName}/instore/cart`, "instore")
                }>
                Go To Basket
              </Button>
            </div>
          </Panel>
        </Panel>
        <CardOption
          classParam="single-merch__text--options"
          key={drinkItem.id}
          productState={productState}
          dispatchProductState={dispatchProductState}
          setQuantity={setQuantity}
          // returnsVStock={() => log.debug("hello world")}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  margin: 0 1rem;
  .drink-btn-container {
    //make same width as container - 5vw
    width: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
  }
  .center {
    font-family: "Roaster Original";
    text-transform: lowercase;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .drinks-card {
    margin: 1rem 0;
    display: flex;
    width: 30vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 600px) {
      width: 90vw;
    }
  }
`;

export default SingleDrinks;
