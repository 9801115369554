import log from "loglevel";
import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useAuth } from "../ecwid/context/auth_context";
import { getInitials } from "../helpers/helper";
import { Avatar } from "rsuite";

const baseName = process.env.REACT_APP_BASENAME;

const AdminNav = ({ style }) => {
  const [isBtnActive, setBtnActive] = useState(false);

  const toggleClass = () => {
    setBtnActive(!isBtnActive);
  };

  const { currentUser } = useAuth();

  log.debug(currentUser.isAdmin);

  return (
    <Wrapper>
      <div
        style={style}
        className={
          isBtnActive ? "navigation-btn active-btn change" : "navigation-btn"
        }
        onClick={toggleClass}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <div
        style={{ backgroundColor: "rgb(14, 114, 185)" }}
        className={isBtnActive ? "navigation active" : "navigation"}
        onClick={toggleClass}>
        {/* buttons */}
        <li>
          <div className="user-nav">
            <Avatar size="lg" circle>
              {getInitials(currentUser)}
            </Avatar>
            <div className="small-user-modal">
              <p>{currentUser.name}</p>
              <p>{currentUser.uid}</p>
            </div>
          </div>
        </li>
        <li>
          <Link to={`${baseName}/myvip`} className="hv-1">
            <i className="fas fa-store mg-right hv"></i>
            Back Home
          </Link>
        </li>
        {currentUser.isAdmin && (
          <>
            <li>
              <Link to={`${baseName}/mystore`} className="hv-3">
                {/* <GiCoffeeCup className="mg-right hv" /> */}
                <i className="far fa-user-circle mg-right hv"></i> Admin Home
              </Link>
            </li>
            <li>
              <Link to={`${baseName}/mystore/userpermissions`} className="hv-3">
                {/* <GiCoffeeCup className="mg-right hv" /> */}
                <i className="far fa-user-circle mg-right hv"></i> User
                Permissions
              </Link>
            </li>

            <li>
              <Link to={`${baseName}/mystore/managediscounts`} className="hv-1">
                {/* <GiCoffeeCup className="mg-right hv" /> */}
                <i className="fas fa-tags mg-right hv"></i> Discounts
              </Link>
            </li>
          </>
        )}
        <li>
          <Link to={`${baseName}/mystore/instoreorders`} className="hv-2">
            <i className="far fa-list-alt mg-right hv"></i> Instore Orders
          </Link>
        </li>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-transform: lowercase;
  .user-nav {
    font-family: "Roaster Original";
    display: flex;
    align-items: center;
    justify-content: start;

    width: 100%;
  }

  .small-user-modal {
    margin-left: 10px;
    text-transform: lowercase;
    font-family: "Roaster Original";
    font-size: 0.6rem;
    color: white;
  }
`;

export default AdminNav;
