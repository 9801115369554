import React, { useState, useEffect } from "react";
import { useProductState } from "../../ecwid/reducers/useProductState";
import { useHistory } from "react-router-dom";
import BeanGrid from "./BeanGrid";
import CardOption from "../Merch/MerchCardOption";
import Nav from "../Navigation";
import Beans from "../../resources/details/beans-details";
import { addToCart } from "../../helpers/helper";
import Button from "../Button";

const baseName = process.env.REACT_APP_BASENAME;

const SingleBean = ({ product, prevProductState }) => {
  const [quantity, setQuantity] = useState(1);

  const { id, description, hdThumbnailUrl: img, name } = product;

  const [productState, dispatchProductState] = useProductState(
    product,
    prevProductState
  );

  let history = useHistory();

  const { finalStock: stock, finalPrice: price } = productState;

  const increaseQty = () => {
    setQuantity((oldQty) => {
      let tempQty = oldQty + 1;
      return tempQty;
    });
  };

  const decreaseQty = () => {
    setQuantity((oldQty) => {
      let tempQty = oldQty - 1;
      if (tempQty < 1) {
        tempQty = 1;
      }
      return tempQty;
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Nav />
      <section className="single-beans">
        <div className="single-beans__product">
          <img
            src={img}
            alt={name}
            className="single-beans__product--imgbean"
          />
          <img
            src={Beans.filter((bean) => bean.name === product.name).map(
              (bean) => bean.fuelMeter
            )}
            alt="coffe fuel meter"
            className="single-beans__product--imgfuel"
          />
        </div>

        <div className="single-beans__text">
          <div className="single-beans__text-1">
            <h2>{product.name} Blend</h2>
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <BeanGrid product={product} />
          <div className="single-beans__text-3">
            <CardOption
              key={id}
              productState={productState}
              classParam={"single-beans__text-3--options"}
              dispatchProductState={dispatchProductState}></CardOption>
            <div className="single-beans__text-3--quantity">
              <h3>QTY:</h3>
              <div className="button-flex">
                <button
                  className="single-beans__text-3--quantity--1"
                  onClick={increaseQty}>
                  +
                </button>
                <span>{quantity}</span>
                <button
                  className="single-beans__text-3--quantity--2"
                  onClick={decreaseQty}>
                  -
                </button>
              </div>
            </div>
            <div className="single-beans__text-3--btn-container">
              <Button
                appearance="primary"
                size="medium"
                style={{ width: "7rem" }}
                onClick={() => history.push(`${baseName}/beans`)}>
                &larr; Previous
              </Button>

              {stock ? (
                <>
                  <h2 className="price">
                    <span className="pound-sign">£</span> {price}
                  </h2>
                  <Button
                    style={{ width: "7rem" }}
                    appearance="primary"
                    size="medium"
                    onClick={() =>
                      addToCart(
                        product.id,
                        quantity,
                        productState.options,
                        "onlinestore",
                        history
                      )
                    }>
                    Buy now
                  </Button>
                </>
              ) : (
                <>
                  <h2 className="price">
                    <span className="pound-sign">£</span> {price}
                  </h2>
                  <Button
                    disabled={true}
                    shouldHover={false}
                    appearance="primary"
                    size="medium"
                    style={{
                      backgroundColor: "rgba(25,25,25,0.3)",
                      border: "none",
                    }}
                    className=" single-beans__text-3--btn-container--btn">
                    Out of stock
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleBean;
