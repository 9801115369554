import styled from "styled-components";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { colors } from "../constants/constants";
import HeaderComp from "../components/HeaderComp";
import StoreImg6 from "../../resources/img/sotonUni/store-img-6.png";
import RobAvatar from "../../resources/img/Managers/rob_avatar.png";
import LukeAvatar from "../../resources/img/Managers/luke_avatar.png";
import EmilyAvatar from "../../resources/img/Managers/emily_avatar.png";
import SoonyAvatar from "../../resources/img/Managers/soony_avatar.png";
import DabossAvatar from "../../resources/img/Managers/daboss_avatar.png";
import CoffeeHolicAvatar from "../../resources/img/Managers/coffee-holic.png";
import Roaster1 from "../../resources/img/sotonUni/roaster-1.png";
import Roaster2 from "../../resources/img/sotonUni/roaster-2.png";
import Mobilisation1 from "../../resources/img/sotonUni/mobilisation-1.png";
import Mobilisation2 from "../../resources/img/sotonUni/mobilisation-2.png";
import CoffeeVans from "../../resources/img/sotonUni/coffee-vans.png";
import StoreFuture2 from "../../resources/img/sotonUni/store-future-2.png";
import VanessaAvatar from "../../resources/img/sotonUni/vanessa_avatar.png";
import SpeechComp from "../components/SpeechComp";
import DescrComp from "../components/DescrComp";

const MobilisationPlan = () => {
  return (
    <Wrapper style={{ backgroundColor: colors.sixthColor }}>
      <HeaderComp
        title="Mobilisation Plan"
        text=""
        backgColor={colors.primaryColor}
      />
      <DescrComp
        title="1st July"
        text="Set up basic service layout as per drawing bellow after design aproval."
        backgColor={colors.primaryColor}
      />
      <div className="medium-img">
        <img src={StoreImg6} alt="southampton store" />
      </div>
      <SpeechComp
        avatar={RobAvatar}
        descrComponent={
          <DescrComp
            backgColor={colors.primaryColor}
            title="3rd Aug"
            text="Install Self sufficient store Eco micro roaster giving the store full coffee sustainability and a real talking point."
          />
        }
      />

      <div className="two-row-container-roaster mg-top-2">
        <ScrollAnimation animateIn="bounceInLeft" offset={300}>
          <img src={Roaster1} alt="Roaster1" />
        </ScrollAnimation>

        <ScrollAnimation animateIn="bounceInRight" offset={300}>
          <img src={Roaster2} alt="Roaster2" />
        </ScrollAnimation>
      </div>

      <SpeechComp
        avatar={LukeAvatar}
        descrComponent={
          <DescrComp
            backgColor={colors.primaryColor}
            title="8th Aug"
            text="Staff training, auditing and EHO inspection"
          />
        }
      />

      <div className="two-row-container mg-top-2">
        <ScrollAnimation animateIn="bounceInLeft" offset={300}>
          <img src={Mobilisation1} alt="Mobilisation1" />
        </ScrollAnimation>

        <ScrollAnimation animateIn="bounceInRight" offset={300}>
          <img src={Mobilisation2} alt="Mobilisation2" />
        </ScrollAnimation>
      </div>

      <SpeechComp
        avatar={EmilyAvatar}
        descrComponent={
          <DescrComp
            backgColor={colors.primaryColor}
            title="16th Aug"
            text="Staff, familly, friends, local business & local radio invite to pre launch drinks and live band to showcase new venue 
            & partnership."
          />
        }
      />
      <SpeechComp
        avatar={SoonyAvatar}
        descrComponent={
          <DescrComp
            backgColor={colors.primaryColor}
            title="23rd Aug"
            text="Official Launch."
          />
        }
      />
      <div className="medium-img">
        <img src={StoreFuture2} alt="StoreFuture2" />
      </div>

      <SpeechComp
        avatar={CoffeeHolicAvatar}
        descrComponent={
          <DescrComp
            backgColor={colors.primaryColor}
            title="14th Sept"
            text="Arrival of Bespoke Pod seating for dining area."
          />
        }
      />

      <div className="medium-img">
        <img src={CoffeeVans} alt="CoffeeVans" />
      </div>

      <SpeechComp
        avatar={VanessaAvatar}
        descrComponent={
          <DescrComp
            backgColor={colors.primaryColor}
            title="28th Sept"
            text="Arrival of agreed kiosk/vending equipment"
          />
        }
      />
      <SpeechComp
        avatar={DabossAvatar}
        descrComponent={
          <DescrComp
            backgColor={colors.primaryColor}
            title=""
            text="We know there is much more to discuss like student afordability, 
            long term goals & contractual commitment to include our
            projections and proffit sharing etc but feel this would all be better 
            suited in face to face discussions.
            We really hope you like us, what we do & we would love to come chat
            further.
            In the meantime please feel free to email (ian@nakedcoffee.co) with any 
            questions & we will help in any way we can.
            Big love
            The Naked Crew.xxx  "
          />
        }
      />
      <DescrComp
        title="www.nakedcoffee.co"
        backgColor={colors.primaryColor}
        link={true}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
  overflow: hidden;

  .two-row-container-roaster {
    width: calc(100vw - 4rem);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;

    @media screen and (max-width: 600px) {
      grid-template-columns: none;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 1rem;
    }

    img {
      height: 35rem;
      @media screen and (max-width: 600px) {
        height: 20rem;
        width: 100%;
      }
      width: 100%;
    }
  }
`;

export default MobilisationPlan;
