import React from "react";
import { InputPicker } from "rsuite";
import styled from "styled-components";

import { useHistory } from "react-router";
import { storeLocationData } from "../../../data/store-location-data";
import AdminNav from "../../../components/AdminNav";
import GoBackBtn from "../../../components/GoBackBtn";

const baseName = process.env.REACT_APP_BASENAME;

const AdminOrders = () => {
  let history = useHistory();

  const onSelectStore = (store) => {
    history.push(`${baseName}/mystore/instoreorders/select`, store);
  };

  const goBack = () => {
    history.push(`${baseName}/mystore`);
  };

  // log.debug(currStoreOrders);
  // const getDistance = (lat1, lon1, lat2, lon2) => {
  //   var p = 0.017453292519943295; // Math.PI / 180
  //   var c = Math.cos;
  //   var a =
  //     0.5 -
  //     c((lat2 - lat1) * p) / 2 +
  //     (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
  //   const dist = 12742 * Math.asin(Math.sqrt(a));
  //   return dist; //
  // };

  // // const getStoreFromLocation = async () => {
  //   if ("geolocation" in window.navigator) {
  //     window.navigator.geolocation.getCurrentPosition(function (position) {
  //       log.debug("Latitude is :", position.coords.latitude);
  //       log.debug("Longitude is :", position.coords.longitude);
  //       const currPosition = {
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       };

  //       for (let i = 0; i < storeLocationData.length; i++) {
  //         let currStore = storeLocationData[i];
  //         let distFromCurrStore = getDistance(
  //           currPosition.lat,
  //           currPosition.lng,
  //           currStore.lat,
  //           currStore.lng
  //         );

  //         if (distFromCurrStore < 0.05) {
  //           let newDistState = {
  //             storeName: currStore.label,
  //             msg: `distance from ${
  //               currStore.label
  //             } is ${distFromCurrStore.toFixed(4)}km `,
  //             value: currStore.value,
  //           };

  //           setStore((oldS) => ({
  //             ...oldS,
  //             ...newDistState,
  //           }));

  //           window.sessionStorage.setItem(
  //             "distState",
  //             JSON.stringify(newDistState)
  //           );

  //           setIsLoading(false);

  //           return;
  //         }
  //       }
  //       setIsLoading(false);
  //     });
  //   } else {
  //     setIsLoading(false);

  //     Alert.info("Can't get store based on Location");
  //   }
  // };

  // useEffect(() => {
  // if (window.sessionStorage.getItem("distState")) {
  //   const distState = JSON.parse(window.sessionStorage.getItem("distState"));
  //   log.debug("getting from session storage", distState);
  //   setIsLoading(false);
  //   setStore((oldS) => ({ ...distState }));
  // } else {
  //   getStoreFromLocation();
  // }
  // eslint-disable-next-line
  // }, []);

  return (
    <>
      <GoBackBtn goBack={goBack} />
      <Wrapper>
        <div>
          <h1>Select Your Store:</h1>
        </div>
        <div>
          <InputPicker
            className="search-item"
            data={storeLocationData}
            placeholder="select store"
            onChange={(item) => {
              onSelectStore(item);
            }}
          />
        </div>
      </Wrapper>
      <AdminNav style={{ top: "90vh" }} />
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 2fr;
  width: 100vw;
  height: 100vh;
  font-family: "Roaster original";
  text-transform: lowercase;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-family: "Roaster original";
    text-transform: lowercase;
    margin-bottom: 2rem;
    text-shadow: 2px 2px #f9c116;
    font-size: 2.7rem;
    @media (max-width: 900px) {
      font-size: 2.3rem;
    }
  }

  .store-dist {
    font-size: 1rem;
  }
`;

export default AdminOrders;
