import React from "react";
import { FiX } from "react-icons/fi";
import { Button, DatePicker, Input, Radio } from "rsuite";

const OnlineCouponModal = ({
  setIsModalOpen,
  setModalCoupon,
  modalCoupon,
  updateCoupon,
  createNewCoupon,
  isModalOpen,
}) => {
  return (
    <div className="coupon-modal">
      <div className="modal-top">
        <div className="centre-item">
          <h4>{modalCoupon.name}</h4>
        </div>
        <div className="centre-item">
          <button
            onClick={() => {
              setIsModalOpen({ open: false, type: "edit" });
              setModalCoupon({});
            }}>
            <FiX fontSize="1.3rem" />
          </button>
        </div>
      </div>
      <div className="modal-content">
        <div className="modal-content-editor">
          <p>Name:</p>
          <Input
            placeholder="Discount name"
            value={modalCoupon.name}
            onChange={(val) => {
              setModalCoupon((oldS) => ({ ...oldS, name: val }));
            }}
          />
        </div>
        <div className="modal-content-editor">
          <p>code:</p>
          <Input
            placeholder="Code"
            value={modalCoupon.code}
            onChange={(val) => {
              setModalCoupon((oldS) => ({ ...oldS, code: val }));
            }}
          />
        </div>
        <div className="modal-content-editor">
          <p>Discount Type:</p>
          <div>
            <Radio
              value="ABS"
              checked={modalCoupon.discountType === "ABS"}
              onChange={(val) => {
                setModalCoupon((oldS) => ({
                  ...oldS,
                  discountType: val,
                }));
              }}>
              GBP discount
            </Radio>
            <Radio
              value="PERCENT"
              checked={modalCoupon.discountType === "PERCENT"}
              onChange={(val) => {
                setModalCoupon((oldS) => ({
                  ...oldS,
                  discountType: val,
                }));
              }}>
              Percentage discount
            </Radio>
            <Radio
              value="SHIPPING"
              checked={modalCoupon.discountType === "SHIPPING"}
              onChange={(val) => {
                setModalCoupon((oldS) => ({
                  ...oldS,
                  discountType: val,
                }));
              }}>
              Free shipping discount
            </Radio>
            <Radio
              value="ABS_AND_SHIPPING"
              checked={modalCoupon.discountType === "ABS_AND_SHIPPING"}
              onChange={(val) => {
                setModalCoupon((oldS) => ({
                  ...oldS,
                  discountType: val,
                }));
              }}>
              Free shipping + GBP discount
            </Radio>
            <Radio
              value="PERCENT_AND_SHIPPING"
              checked={modalCoupon.discountType === "PERCENT_AND_SHIPPING"}
              onChange={(val) => {
                setModalCoupon((oldS) => ({
                  ...oldS,
                  discountType: val,
                }));
              }}>
              Free shipping + Percentage discount
            </Radio>
          </div>
        </div>
        <div className="modal-content-editor">
          <p>Status:</p>
          <div>
            <Radio
              value="ACTIVE"
              checked={modalCoupon.status === "ACTIVE"}
              onChange={(val) => {
                setModalCoupon((oldS) => ({
                  ...oldS,
                  status: val,
                }));
              }}>
              Active
            </Radio>
            <Radio
              value="PAUSED"
              checked={modalCoupon.status === "PAUSED"}
              onChange={(val) => {
                setModalCoupon((oldS) => ({
                  ...oldS,
                  status: val,
                }));
              }}>
              Paused
            </Radio>
          </div>
        </div>
        <div className="modal-content-editor">
          <p>Discount amount:</p>
          <div className="disc-amount-container">
            <div>
              <Input
                placeholder="% or absolute"
                disabled={modalCoupon.discountType === "SHIPPING"}
                value={`${
                  modalCoupon.discountType === "SHIPPING"
                    ? "Shipping only"
                    : !modalCoupon?.discount
                    ? ""
                    : modalCoupon.discount
                }`}
                onChange={(val) =>
                  setModalCoupon((oldS) => ({
                    ...oldS,
                    discount: Number(val),
                  }))
                }
              />
            </div>
            <div>
              <span>
                {modalCoupon.discountType === "PERCENT"
                  ? "%"
                  : modalCoupon.discountType === "ABS"
                  ? "GBP"
                  : modalCoupon.discountType === "ABS_AND_SHIPPING"
                  ? "GBP + Shipping"
                  : modalCoupon.discountType === "PERCENT_AND_SHIPPING"
                  ? "% + Shipping"
                  : ""}
              </span>
            </div>
          </div>
        </div>
        <div className="modal-content-editor" style={{ position: "relative" }}>
          <p>Expiration date:</p>
          <DatePicker
            ranges={[
              {
                label: "Now",
                value: new Date(),
              },
            ]}
            value={
              modalCoupon?.expirationDate ? modalCoupon?.expirationDate : null
            }
            placeholder={
              !modalCoupon?.expirationDate
                ? "Until deactivated"
                : modalCoupon?.expirationDate
            }
            onChange={(val) => {
              setModalCoupon((oldS) => ({
                ...oldS,
                expirationDate: val,
              }));
            }}
            style={{ width: 280 }}
          />
        </div>
        <div className="modal-content-editor">
          <p>Minimum order subtotal:</p>
          <Input
            value={modalCoupon?.totalLimit ? modalCoupon.totalLimit : ""}
            placeholder={
              !modalCoupon?.totalLimit ? "No min order subtotal" : ""
            }
            onChange={(val) =>
              setModalCoupon((oldS) => ({
                ...oldS,
                totalLimit: Number(val),
              }))
            }
          />
        </div>
        <div className="modal-content-editor">
          <p>Usage limit:</p>
          <div>
            <Radio
              value="UNLIMITED"
              checked={modalCoupon?.usesLimit === "UNLIMITED"}
              onChange={(val) =>
                setModalCoupon((oldS) => ({ ...oldS, usesLimit: val }))
              }>
              Unlimited
            </Radio>
            <Radio
              value="ONCEPERCUSTOMER"
              checked={modalCoupon?.usesLimit === "ONCEPERCUSTOMER"}
              onChange={(val) =>
                setModalCoupon((oldS) => ({ ...oldS, usesLimit: val }))
              }>
              Once per customer
            </Radio>
            <Radio
              value="SINGLE"
              checked={modalCoupon?.usesLimit === "SINGLE"}
              onChange={(val) =>
                setModalCoupon((oldS) => ({ ...oldS, usesLimit: val }))
              }>
              Single
            </Radio>
          </div>
        </div>
        <div className="modal-content-editor">
          <p>Application limit:</p>
          <div>
            <Radio
              value="UNLIMITED"
              checked={modalCoupon?.applicationLimit === "UNLIMITED"}
              onChange={(val) =>
                setModalCoupon((oldS) => ({
                  ...oldS,
                  applicationLimit: val,
                }))
              }>
              Unlimited
            </Radio>
            <Radio
              value="NEW_CUSTOMER_ONLY"
              checked={modalCoupon?.applicationLimit === "NEW_CUSTOMER_ONLY"}
              onChange={(val) =>
                setModalCoupon((oldS) => ({
                  ...oldS,
                  applicationLimit: val,
                }))
              }>
              New customer only
            </Radio>
            <Radio
              value="REPEAT_CUSTOMER_ONLY"
              checked={modalCoupon?.applicationLimit === "REPEAT_CUSTOMER_ONLY"}
              onChange={(val) =>
                setModalCoupon((oldS) => ({
                  ...oldS,
                  applicationLimit: val,
                }))
              }>
              Repeat customer only
            </Radio>
          </div>
        </div>
        <div className="modal-content-editor">
          <p>Number of customer uses:</p>
          <p>{modalCoupon?.orderCount}</p>
        </div>
        <div className="btn-modal-container">
          <Button
            appearance="primary"
            onClick={() => {
              if (isModalOpen.type === "new") {
                setIsModalOpen({ open: false, type: "edit" });
                createNewCoupon();
              } else {
                setIsModalOpen({ open: false, type: "edit" });
                updateCoupon();
              }
            }}>
            {isModalOpen.type === "new" ? "Create Coupon" : "Update Coupon"}
          </Button>
          <Button
            appearance="ghost"
            onClick={() => {
              setIsModalOpen({ open: false, type: "edit" });
              setModalCoupon({});
            }}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OnlineCouponModal;
