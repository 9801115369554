//coffee beans large


//coffee beans small
import Honduran from "../img/Beans/beans-small/honduran-small.png";
import RwandanInzovu from "../img/Beans/beans-small/rwanda-inzovu-small.png";
import ColombianPinkBourbon from "../img/Beans/beans-small/colombian-pink-bourbon-small.png";
import BrazilianCaturi from "../img/Beans/beans-small/brazilian-caturi-small.png";
import Nicaragua from "../img/Beans/beans-small/Nicaragua-small.png";
import BrazilDecaf from "../img/Beans/beans-small/brazilian-santos-decaf-small.png";
import Ethiopian from "../img/Beans/beans-small/ethiopian-sidamo-small.png";
import VietnameseBuonMeThuot from "../img/Beans/beans-small/vietnamese-small.png";
import DorsetNaggaChilli from "../img/Beans/beans-small/dorset-nagga-chilli-small.png";
import Brazilia from "../img/Beans/beans-small/brazilia-small.png";

//Coffee cups
import MilkCoffee from "../img/Beans/coffee-cup/milk-coffee.png";
import Espresso from "../img/Beans/coffee-cup/espresso.png";
import Americano from "../img/Beans/coffee-cup/americano.png";
import DarkWhite from "../img/Beans/coffee-cup/dark-white.png";

//fuel meter
import FMVirgin from "../img/Beans/fuel-meters/virgin-coffee-fuel-meter.png";
import FMHalfCaff from "../img/Beans/fuel-meters/half-caff-coffee-fuel-meter.png";
import FMColombianPinkBourbon from "../img/Beans/fuel-meters/colombian-pink-bourbon-fuel-meter.png";
import FMBrazilianSantosDecaf from "../img/Beans/fuel-meters/brazilian-santos-decaf-fuel-meter.png";
import FMRwandanInzovu from "../img/Beans/fuel-meters/rwandan-inzovu-fuel-meter.png";
import FMMorningGlory from "../img/Beans/fuel-meters/morning-glory-fuel-meter.png";
import FMChilliCoffee from "../img/Beans/fuel-meters/chilli-coffee-fuel-meter.png";

//IMPORTANT NOTES
//1) if you have 2 items place them in descending order e.g : c1 c2  not c3
//2) if you have 1 item place it at c1

const Beans = [
  {
    name: "Virgin",
    descr1: {
      title: "Lovingly hand blended using:",
      numCol: "3",
      c1: ["Honduran: Nutty & crispy", Honduran],
      c2: ["Ethiopian Sidamo: Velvet Chocolatey", Ethiopian],
      c3: ["Brazilian Caturi: Cherry Blossom", BrazilianCaturi],
    },
    descr2: {
      title: "This epic roast compliments:",
      numCol: "3",
      c1: ["Espresso", Espresso],
      c2: ["Americano / black Coffee", Americano],
      c3: ["Milk Based / White Coffee", MilkCoffee],
    },
    descr3: {
      title: "Roast Masters Recommendation:",
      c1:
        "This is undoubtably my fave blend & Go to choice 9 times out of 10. Its creamy finsih lends to ALL.xx",
    },
    fuelMeter: FMVirgin,
  },
  {
    name: "Brazillia",
    descr1: {
      title: "Lovingly hand blended using:",
      numCol: "3",
      c1: ["Brazilian Caturi: Cherry Blossom", BrazilianCaturi],
      c2: ["Honduran: Nutty & crispy", Honduran],
      c3: ["Nicaraguan: Ian can work his magic here", Nicaragua],
    },
    descr2: {
      title: "This epic roast compliments:",
      numCol: "3",
      c1: ["Espresso", Espresso],
      c2: ["Americano / black Coffee", Americano],
      c3: ["Milk Based / White Coffee", MilkCoffee],
    },
    descr3: {
      title: "Roast Masters Recommendation:",
      c1:
        "This high octane bend is banging as a shot of espresso and the sweetness of a south American blend compliments any milky brew.",
    },
    fuelMeter: FMVirgin, //DEFAULT
  },
  {
    name: "Smooth Operator",
    descr1: {
      title: "Lovingly hand blended using:",
      numCol: "3",
      c1: ["Brazilian Caturi: Cherry Blossom", BrazilianCaturi],
      c2: ["Honduran: Nutty & crispy", Honduran],
      c3: ["Nicaraguan: Ian can work his magic here", Nicaragua],
    },
    descr2: {
      title: "This epic roast compliments:",
      numCol: "3",
      c1: ["Espresso", Espresso],
      c2: ["Americano / black Coffee", Americano],
      c3: ["Milk Based / White Coffee", MilkCoffee],
    },
    descr3: {
      title: "Roast Masters Recommendation:",
      c1:
        "If you're more of a 'today I'm gonna mix it up' type of person this blend is a must for you. Its All round performance allows you to switch from an espresso to a milky white and still have an epic brew.",
    },
    fuelMeter: FMVirgin, //DEFAULT
  },
  {
    name: "Half-Caff",
    descr1: {
      title: "Lovingly hand blended using:",
      numCol: "2",
      c1: ["Water process Brazilian Decaf", BrazilDecaf],
      c2: ["Honduran: Nutty & crispy", Honduran],
      c3: undefined,
    },
    descr2: {
      title: "This epic roast compliments:",
      numCol: "2",
      c1: ["Americano / black Coffee", Americano],
      c2: ["Milk Based / White Coffee", MilkCoffee],
      c3: undefined,
    },
    descr3: {
      title: "Roast Masters Recommendation:",
      c1:
        "Great for those who love coffee but have to put the break on the caffine",
    },
    fuelMeter: FMHalfCaff,
  },
  {
    name: "Colombia Pink Bourbon",
    descr1: {
      title: "Lovingly hand blended using:",
      numCol: "1",
      c1: ["Colombia Pink Bourbon", ColombianPinkBourbon],
      c2: undefined,
      c3: undefined,
    },
    descr2: {
      title: "This Single Estate compliments:",
      numCol: "3",
      c1: ["Espresso/ Piccolo/ Macchiato", Espresso],
      c2: ["Americano / Dark/ White", DarkWhite],
      c3: ["Milk Based / White Coffee", MilkCoffee],
    },
    descr3: {
      title: "Roast Masters Recommendation:",
      c1: "A popular Single Estate. Try as espresso to pull the sweet notes",
    },
    fuelMeter: FMColombianPinkBourbon,
  },
  {
    name: "Brazilian Santos Decaf",
    descr1: {
      title: "This ethical Single origin is:",
      numCol: "1",
      c1: ["Brazilian Santos Decaf", BrazilDecaf],
      c2: undefined,
      c3: undefined,
    },
    descr2: {
      title: "This Clean Bean compliments:",
      numCol: "3",
      c1: ["Piccolo/ Macchiato", Espresso],
      c2: ["Americano / Dark/ White", DarkWhite],
      c3: ["Milk Based / White Coffee", MilkCoffee],
    },
    descr3: {
      title: "Roast Masters Recommendation:",
      c1:
        "I hate to go against everything I stand for but I really like this decaf. It’s a real game changer. A must try!",
    },
    fuelMeter: FMBrazilianSantosDecaf,
  },
  {
    name: "Rwandan Inzovu",
    descr1: {
      title: "This ethical Single origin is:",
      numCol: "1",
      c1: ["Rwandan Inzovu Chocolate/ Cranberry", RwandanInzovu],
      c2: undefined,
      c3: undefined,
    },
    descr2: {
      title: "This Epic Bean compliments:",
      numCol: "3",
      c1: ["Espresso Piccolo", Espresso],
      c2: ["Americano", DarkWhite],
      c3: ["Milk Based / White Coffee", MilkCoffee],
    },
    descr3: {
      title: "Roast Masters Recommendation:",
      c1:
        "Cupped several times, just because I loved thecomplexity of this bean.If you own an Aeropress, this blend will be your new best friend.xx ",
    },
    fuelMeter: FMRwandanInzovu,
  },
  {
    name: "Morning Glory",
    descr1: {
      title: "Lovingly hand blended using:",
      numCol: "3",
      c1: ["Nicaragua Segovias", Nicaragua],
      c2: ["Colombian Pink Bourbon", ColombianPinkBourbon],
      c3: ["Vietnamese Buōn Mé Thuot", VietnameseBuonMeThuot],
    },
    descr2: {
      title: "This Single Estate compliments:",
      numCol: "3",
      c1: ["Espresso", Espresso],
      c2: ["Americano / Dark White", DarkWhite],
      c3: ["Milk Based / Flat White", MilkCoffee],
    },
    descr3: {
      title: "Roast Masters Recommendation:",
      c1:
        "For those who just can't get up in the morning! This hard hitter uses a Hardcore Vietnam Robusta to pump those caffeine levels through the roof. Please do not drink if you have a weak heart or just can’t handle lots of caffeine ",
    },
    fuelMeter: FMMorningGlory,
  },
  {
    name: "Chilli Coffee",
    descr1: {
      title: "Lovingly hand blended using:",
      numCol: "2",
      c1: ["Dorset Naga Chilli", DorsetNaggaChilli],
      c2: ["Brazilia Blend", Brazilia],
      c3: undefined,
    },
    descr2: {
      title: "This Badass blend works well with:",
      numCol: "2",
      c1: ["Americano Dark / White", DarkWhite],
      c2: ["Milk Based / Flat White", MilkCoffee],
      c3: undefined,
    },
    descr3: {
      title: "Roast Masters Recommendation:",
      c1: "Surprisingly Addictive. You can manage how hot by how much you add.",
    },
    fuelMeter: FMChilliCoffee,
  },
];

export default Beans;
