import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";

import PointsImg from "../../resources/img/instore/points.png";

import { pointsColor } from "./menu/constants";

const Points = () => {
  return (
    <div className="instore-grid">
      <Header img={PointsImg} color={pointsColor} />
      <div></div>
      <Footer color={pointsColor} />
    </div>
  );
};

export default Points;
