import log from "loglevel";
import React from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../ecwid/context/product_context";
import { useProductState } from "../../ecwid/reducers/useProductState";
import CardOption from "./MerchCardOption";
import Button from "../Button";

/*image sizing: 
x: 300px, y: 485px
w: 600px, h: 967,6161px
*/

const baseName = process.env.REACT_APP_BASENAME;

const Card = ({ cardClass, productId, productIndex }) => {
  const { products } = useAppContext();

  let history = useHistory();

  const product = products.merch.items[productIndex];

  const [productState, dispatchProductState] = useProductState(product);

  const coloursForBackground = {
    colourArray: ["backgroundColorGreen", "backgroundColorYellow"],
    colour: function (counter) {
      return this.colourArray[counter % this.colourArray.length];
    },
  };

  const numOptions = Object.keys(productState.options).length;

  log.debug("Card for product " + productId + "(" + product.name + ")");

  return (
    <>
      <div className={cardClass}>
        <div className="card__product">
          <div className="card__product--img">
            <span className="buyNow none">Buy Now!</span>
            <img src={product.imageUrl} alt="product-img" />
          </div>
          <div className="card__product--dscr">
            <ul>
              <CardOption
                key={productId}
                productState={productState}
                dispatchProductState={dispatchProductState}
                coloursForBackground={coloursForBackground}></CardOption>

              <li
                style={{
                  fontFamily: "Roaster original",
                  textTransform: "lowercase",
                }}
                key={productId + "stock"}
                className={coloursForBackground.colour(numOptions)}>
                <span>In Stock:</span>
                <span>{productState.finalStock ? "Yes" : "No"}</span>
              </li>
              <li
                style={{
                  fontFamily: "Roaster original",
                  textTransform: "lowercase",
                }}
                key={productId + "price"}
                className={coloursForBackground.colour(numOptions + 1)}>
                <span>Cost:</span>
                <span>{productState.finalPrice}</span>
              </li>
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "1rem 0 ",
            }}>
            {/* <Button
              size="small"
              style={{
                borderRadius: "2rem",
                fontFamily: "Roaster Original",
                width: "80%",
              }}
              onClick={(e) => {
                log.debug(e.target.children[0]);
                e?.target?.children[0]?.click();
              }}> */}
            <Button
              appearance="secondary"
              size="small"
              style={{ width: "7rem", borderRadius: "10rem" }}
              // className="remove-link-styles"
              onClick={() =>
                history.push(`${baseName}/merch/${productId}`, {
                  productState: productState,
                })
              }>
              Check me out <span className="arr">&rarr;</span>
            </Button>
            {/* </Button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
