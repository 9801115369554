import log from "loglevel";
import React, { useState } from "react";
import { Alert } from "rsuite";
import { useAuth } from "../../ecwid/context/auth_context";
// import { setNewEcwidSsoProfile } from "../helpers/helper";
import styled from "styled-components";
import { useHistory } from "react-router";
import InputModal from "../../components/account/inputModal";
import axios from "axios";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import GoBackBtn from "../../components/GoBackBtn";
// import axios from "axios";
// import { useHistory } from "react-router-dom";

const baseName = process.env.REACT_APP_BASENAME;

const MyAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);
  const [showModal, setShowModal] = useState({ show: false, type: "" });

  // const [isLoading, setIsLoading] = useState(false);
  const { currentUser, refreshContext } = useAuth();
  const history = useHistory();

  // useEffect(() => {
  //   if (!currentUser.emailVerified) {
  //     history.push(`${baseName}/vip`);
  //   }
  //   // eslint-disable-next-line
  // }, [currentUser]);

  const open = (type, changeOrAdd) => {
    setShowModal({ show: true, type: type, changeOrAdd: changeOrAdd });
  };

  const deleteAdress = async (index) => {
    // HARD CODE INDEX VALUE AS THEY CAN ONLY HAVE 1 SHIPPING ADDRESS
    const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/delete_user_shipping_address`,
        //prettier-ignore
        { "token": authToken ,"index": 0 }
      );
      log.debug(resp);

      await refreshContext("shippingAddresses", "");
      if (resp.status === 200) {
        Alert.success(`Successfully updated your ${showModal.type}`);
      }
      setIsLoading(false);
    } catch (error) {
      Alert.error(`Something went wrong when updating your ${showModal.type}`);
      setIsLoading(false);
    }
  };

  const AccountDetail = ({
    name,
    accountDetail,
    changeOption = null,
    addOption = null,
    textShadowColor,
  }) => {
    if (name === "address") {
      log.debug("addOption for address", addOption);
    }

    return (
      <>
        <div
          className={
            name === "address"
              ? "account-detail-container-address"
              : "account-detail-container"
          }>
          {/* EMAIL DETAILS  */}
          {/* 0 because 0 is falsy and loyalty points could be 0  */}
          {accountDetail ? (
            <div className="detail-container">
              <h3
                className="user-detail-h3"
                style={{
                  alignSelf: "flex-start",
                  textShadow: textShadowColor
                    ? `1px 1px ${textShadowColor}`
                    : null,
                }}>
                {name === "address" ? "Shipping Address" : name}:
              </h3>
              {name === "address" ? (
                <>
                  {accountDetail?.map((address, i) => {
                    return (
                      <>
                        <div className="shipping-address-details">
                          <h5>{address.name}</h5>
                          <h5>{address.countryCode}</h5>
                          <h5>{address.city}</h5>
                          <h5>{address.postalCode}</h5>
                          <h5>{address.street}</h5>
                          <h5>{address.phone}</h5>
                        </div>

                        {changeOption && (
                          <div className="btn-container">
                            <Button
                              style={{ width: "7rem" }}
                              appearance="secondary"
                              size="small"
                              onClick={() => open(name, "Change")}>
                              Change {name}
                            </Button>
                            <Button
                              style={{ width: "7rem" }}
                              appearance="secondary"
                              className="delete-btn"
                              onClick={() => deleteAdress(i)}
                              size="small">
                              Remove {name}
                            </Button>
                          </div>
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  <h4 className="user-detail-h4">
                    {name === "password" ? "***************" : accountDetail}
                    {name === "Email Verified" &&
                      `${accountDetail ? "Verified" : "Not Verified"}`}
                  </h4>
                  {changeOption && (
                    <Button
                      className="change-add-btn"
                      appearance="secondary"
                      onClick={() => open(name, "Change")}
                      size="small">
                      Change {name}
                    </Button>
                  )}
                </>
              )}
            </div>
          ) : name === "Loyalty Points" ? (
            <div className="detail-container">
              <h3
                className="user-detail-h3"
                style={{
                  alignSelf: "flex-start",
                  textShadow: textShadowColor
                    ? `1px 1px ${textShadowColor}`
                    : null,
                }}>
                {name}:
              </h3>
              <h4 className="user-detail-h4">&nbsp;</h4>
              <h4 style={{ fontSize: "1.6rem" }} className="user-detail-h4">
                {accountDetail}
              </h4>
            </div>
          ) : (
            <div className="detail-container">
              <h3
                className="user-detail-h3"
                style={{
                  alignSelf: "flex-start",
                  textShadow: textShadowColor
                    ? `1px 1px ${textShadowColor}`
                    : null,
                }}>
                {name === "address" ? "Shipping Address" : name}:
              </h3>
              {addOption && (
                <>
                  <h4 className="user-detail-h4">&nbsp;</h4>
                  <Button
                    className="change-add-btn"
                    appearance="secondary"
                    onClick={() => open(name, "Add")}
                    size="small">
                    Add {name}
                  </Button>
                </>
              )}
              {/* email verification is different  */}
              {name === "Email Verified" && !accountDetail && (
                <>
                  <h4 className="user-detail-h4">&nbsp;</h4>

                  <Button
                    className="change-add-btn"
                    appearance="secondary"
                    onClick={() => open(name, "Add")}
                    size="small">
                    Verify Email
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
        <hr style={{ backgroundColor: "black", width: "100%" }} />
      </>
    );
  };

  const deleteAccount = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete your account, this action can't be undone"
      )
    ) {
      setDeleteAccountLoading(true);
      //call database and delete user
      const authToken = JSON.parse(window.localStorage.getItem("auth_token"));
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_API_ADDRESS}/delete_user`,
          //prettier-ignore
          { "token": authToken }
        );
        log.debug(resp);
        if (resp.status === 200) {
          Alert.success(`Successfully deleted your account`);
          window.location.reload();
          setDeleteAccountLoading(false);
        }
      } catch (error) {
        Alert.error(`Something went wrong when deleting your account`);
        setDeleteAccountLoading(false);
      }
    } else {
      return;
    }
  };

  log.debug("myip currentUser:", currentUser);
  log.debug("isLoading:", isLoading);

  if (deleteAccountLoading) {
    return <Loading text="deleting your account" />;
  }

  return (
    <>
      <GoBackBtn goBack={() => history.push(`${baseName}/myvip`)} />

      <InputModal showModal={showModal} setShowModal={setShowModal} />
      <HeaderWrapper>
        <h1
          style={{
            fontFamily: "Roaster Original",
            textTransform: "lowercase",
          }}>
          My Details
        </h1>
      </HeaderWrapper>
      <Wrapper>
        <div className="account-container">
          <AccountDetail
            name="name"
            accountDetail={currentUser?.name}
            changeOption={true}
            addOption={true}
            textShadowColor="#f9c116"
          />

          <AccountDetail
            name="email"
            accountDetail={currentUser?.email}
            changeOption={true}
            addOption={true}
            textShadowColor="#94c43e"
          />

          <AccountDetail
            name="Email Verified"
            accountDetail={currentUser.emailVerified}
            textShadowColor="#f9c116"
          />

          <AccountDetail
            name="password"
            accountDetail={currentUser?.email}
            changeOption={true}
            addOption={true}
            textShadowColor="#f15a24"
          />

          <AccountDetail
            name="address"
            accountDetail={currentUser?.shippingAddresses}
            changeOption={true}
            addOption={currentUser.shippingAddresses.length === 0}
            textShadowColor="#ED1E79"
          />

          <AccountDetail
            name="Loyalty Points"
            accountDetail={currentUser.loyalty_points}
            textShadowColor="#f9c116"
          />
          <div className="detail-container">
            <Button
              size="medium"
              appearance="secondary"
              className="delete-btn"
              onClick={deleteAccount}>
              Delete Account!
            </Button>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  height: auto;
  margin: 0 25vw;

  @media (max-width: 900px) {
    margin: 0 5vw;
  }

  position: relative;

  .account-container {
    max-height: 80vh;
    margin-top: 5vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @media (max-width: 900px) {
      max-height: auto;
      overflow-y: visible;
    }
    /* div {
      border: 1px solid red;
    } */
  }

  .centre-blur {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  //Account detail section
  .account-detail-container {
    /* border: 1px solid red; */
    width: 80%;
    min-height: 7rem;
    height: 20vh;

    @media (max-width: 900px) {
      min-height: 10rem;
    }
  }

  .shipping-address-details {
    /* border: 1px solid green; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5vh;
  }

  .account-detail-container-address {
    width: 80%;
    height: fit-content;
  }

  .detail-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-family: "Roaster Original";
    text-transform: lowercase;
  }

  .btn-container {
    width: 35vw;
    margin-top: 1vh;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .orders-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roaster Original";
    text-transform: lowercase;
  }

  .user-detail-h4 {
    font-family: "Roaster Original";
    text-transform: lowercase;
    font-size: 1rem;
    margin-bottom: 1rem;
    @media (max-width: 900px) {
      margin-top: 2vh;
      font-size: 1rem;
    }
  }

  .user-detail-h3 {
    font-size: 1.4rem;
    @media (max-width: 900px) {
      font-size: 1.2rem;
    }
  }

  .welcome-header-h1 {
    font-size: 2.2rem;
    text-transform: lowercase;
    @media (max-width: 900px) {
      font-size: 1.2rem;
    }
  }

  .account-btn {
    &:not(:last-child) {
      margin-right: 5px;
    }
    width: 14vw;
    @media (max-width: 900px) {
      width: 60vw;
    }
  }

  //Left side styling
  .avatar-container {
    margin: 5vh 0;
  }

  .delete-btn {
    margin: 2rem 0;
    text-transform: lowercase;
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    border: none;

    &:hover {
      transform: none !important;
      background-color: rgba(255, 0, 0, 0.8);
      border: none;
    }
  }

  .change-add-btn {
    width: 35% !important;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15vh;
  /* border: 1px solid red; */

  @media (max-width: 600px) {
    display: none;
  }

  @media (max-width: 900px) {
    text-align: center;
    margin-top: 4vh;
  }

  h1 {
    text-shadow: 2px 2px #f9c116;
    font-size: 2.7rem;
    @media (max-width: 900px) {
      font-size: 2.3rem;
    }
  }
`;

export default MyAccount;
