import React, { useEffect } from "react";
import Nav from "../components/Navigation";
import Footer from "../components/Footer";
import BeansContainer from "../components/Beans/BeansContainer";

const Beans = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Nav />
      <BeansContainer />
      <Footer
        disclaimer={
          "Eating Rocks may lead to broken teeth, drinking our coffee often leads to Rocking to music. If after consuming any of our Coffee products you find yourself eating rocks we strongly advise to seek immediate dental advice but if you feel a sudden urge to pump up the music and fire up the air guitar, buy some more coffee, it's going to be a rocking Day."
        }
      />
    </>
  );
};

export default Beans;
