import React, { useEffect } from "react";
import Nav from "../components/Navigation";
import Footer from "../components/Footer";
import StoreFinderGrid from "../components/StoreFinderGrid";

const StoreFinder = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Nav />
      <StoreFinderGrid />
      <Footer />
    </>
  );
};

export default StoreFinder;
