import React from "react";
import styled from "styled-components";
import DescrComp from "../components/DescrComp";
import HeaderComp from "../components/HeaderComp";
import { colors } from "../constants/constants";
import WhysUsImg from "../../resources/img/sotonUni/whyus.png";
import WorkExperienceImg from "../../resources/img/sotonUni/work-experience-certificate.png";
import ApprentishipImg from "../../resources/img/sotonUni/apprentiship.png";
import StoreImg1 from "../../resources/img/sotonUni/store-img-1.png";
import StoreImg2 from "../../resources/img/sotonUni/store-img-2.png";
import StoreImg3 from "../../resources/img/sotonUni/store-img-3.png";
import StoreImg4 from "../../resources/img/sotonUni/store-img-4.png";
import StoreImg5 from "../../resources/img/sotonUni/store-img-5.png";
import StoreImg6 from "../../resources/img/sotonUni/store-img-6.png";
import StoreFuture1 from "../../resources/img/sotonUni/store-future-1.png";
import StoreFuture2 from "../../resources/img/sotonUni/store-future-2.png";
import CoffeeTowerRange from "../../resources/img/sotonUni/coffee-tower-range.png";
import CoffeeTowerRange2 from "../../resources/img/sotonUni/coffee-tower-range2.png";
import Events1Img from "../../resources/img/sotonUni/events-1.png";
import Events2Img from "../../resources/img/sotonUni/events-2.png";
import Events3Img from "../../resources/img/sotonUni/events-3.png";
import Events4Img from "../../resources/img/sotonUni/events-4.png";
import Van1 from "../../resources/img/sotonUni/van-1.png";
import Van2 from "../../resources/img/sotonUni/van-2.png";
import Van3 from "../../resources/img/sotonUni/van-3.png";
import ScrollAnimation from "react-animate-on-scroll";

const WhyUs = () => {
  return (
    <Wrapper style={{ backgroundColor: colors.fifthColor }}>
      <HeaderComp text="" title="Why Us?" backgColor={colors.primaryColor} />
      <DescrComp
        backgColor={colors.primaryColor}
        title="Training, Health and safety"
        text="With our stores already housed within Universities and Schools we have achieved more than most in regard to 
        understanding student needs, speed of service, full DBS check compliant & HACCP aproved standards.
        This was a lengthy process to achive but one that was required for us to step onto BU campus.
        This paired with our own risk assesment team, maintenance team & training arm gives us a real edge on our 
        competitiors."
      />
      <div className="big-img">
        <img src={WhysUsImg} alt="why us" />
      </div>
      <DescrComp
        className="mg-top-2"
        backgColor={colors.primaryColor}
        title="Aprentiships & Work experience"
        text="We work directly with Bournemouth College, Bournemouth Uni, Loco-Motivation,
        The Bishop of Winchester Academy, The Quay School and many more to offer training, work experience, 
        apprentiships etc."
      />
      <div className="medium-img">
        <img src={WorkExperienceImg} alt="work experience" />
      </div>
      <div className="medium-img">
        <img src={ApprentishipImg} alt="work experience" />
      </div>
      <DescrComp
        className="mg-top-2"
        backgColor={colors.primaryColor}
        title=""
        text="We value education and part of the reason we opened was to offer a chance to those who had not achieved as well
        as perhaps wished or still were not clear on thier future goals. Whist understanding our offerings may not be everyones 
        cup of coffee, offering structure in the workplace, ethics and enjoyment gives the opportunity for people to open up
        and notice their true potential. Oh...... and make a dam fine cup of coffee along the way.
        We would love the opportunity to work along side the College, extending our ability to suport and give oppurtunity 
        to a much wider audience."
      />
      <DescrComp
        className="mg-top-2"
        backgColor={colors.primaryColor}
        title="Abiltiy to grow & adapt"
        text="From dream to reality within three months. Due ot our founders design capabilities and tireless working we can now 
        create whole stores from a simple design, have fitted, fully staffed and ready to open within three months."
      />
      {/* store images  */}
      <div className="gallery-img">
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={StoreImg1} alt="store-img-1" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={StoreImg2} alt="store-img-2" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={StoreImg3} alt="store-img-3" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={StoreImg4} alt="store-img-4" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={StoreImg5} alt="store-img-5" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="zoomIn" offset={300}>
          <img src={StoreImg6} alt="store-img-6" />
        </ScrollAnimation>
      </div>
      <DescrComp
        className="mg-top-2"
        backgColor={colors.primaryColor}
        title=""
        text="We already have all of the equipment needed to set up at the college with our chairs, machines etc that we already 
        have in our warehouses. After the initial set up we would look to brining some really cool seating options into the cafe"
      />
      <div className="medium-img">
        <img src={StoreFuture1} alt="store future 1" />
      </div>
      <div className="medium-img">
        <img src={StoreFuture2} alt="store future 2" />
      </div>
      <DescrComp
        className="mg-top-2"
        backgColor={colors.primaryColor}
        title=""
        text="Giving students a real feeling of comfort and home whilst also supporting any future restrictions on seating due to the
        current climate."
      />
      <DescrComp
        className="mg-top-2"
        backgColor={colors.primaryColor}
        title="Vending"
        text="We have already been looking at vending options to grow our business model and so were very excited to hear your
        opportunity came with this option in mind. We are looking at many different styles of vending.
        Already being partnered with a vending company we can offer short term and long term options moving forward
        to ensure a high standard of service throught."
      />
      <div className="two-row-container mg-top-2">
        <ScrollAnimation animateIn="bounceInLeft" offset={300}>
          <img src={CoffeeTowerRange} alt="ingredients" />
        </ScrollAnimation>

        <ScrollAnimation animateIn="bounceInRight" offset={300}>
          <img src={CoffeeTowerRange2} alt="cookies" />
        </ScrollAnimation>
      </div>
      <DescrComp
        className="mg-top-2"
        backgColor={colors.primaryColor}
        title="Events & advertising"
        text="We already have a string of opening/Threshers promo events under our belts, partner with A-list and have massive 
        merch give aways to help promote our new stores. "
      />
      <div className="four-row-container mg-top-2">
        <img src={Events1Img} alt="events-1" />
        <img src={Events2Img} alt="events-2" />
        <img src={Events3Img} alt="events-3" />
        <img src={Events4Img} alt="events-4" />
      </div>

      <DescrComp
        className="mg-top-2"
        backgColor={colors.primaryColor}
        includeTitle={false}
        text="We would love to explore future oportunities like Vegan doughnut vans or street food vans. We have ability to ship 
        these in to the UK within a three month period."
      />

      <div className="food-img-container mg-top-2">
        <div className="two-one-top">
          <ScrollAnimation animateIn="bounceInLeft" offset={300}>
            <img src={Van1} alt="van 1" />
          </ScrollAnimation>

          <ScrollAnimation animateIn="bounceInRight" offset={300}>
            <img src={Van2} alt="van 2" />
          </ScrollAnimation>
        </div>
        <div className="two-one-bottom">
          <ScrollAnimation animateIn="fadeInRightBig" offset={300}>
            <img src={Van3} alt="van 3" />
          </ScrollAnimation>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
  overflow: hidden;

  .mg-top-2 {
    margin-top: 2rem;
  }

  .gallery-img {
    img {
      height: 20rem;
    }
  }
`;

export default WhyUs;
