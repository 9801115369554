import log from "loglevel";
import React, { useState } from "react";
import Loading from "../Loading";
import Beans from "./Bean";
import Popover from "@material-ui/core/Popover";
import { ImCross } from "react-icons/im";
import StoreDetails from "../../resources/details/store-finder-details";
import { useAppContext } from "../../ecwid/context/product_context";
import { useHistory } from "react-router";

const baseName = process.env.REACT_APP_BASENAME;

const BeansContainer = () => {
  const { products, loading } = useAppContext();

  let history = useHistory();
  log.debug("history:", history);

  const [isPopupActive, setIsPopupActive] = useState(true);

  const removePopup = () => setIsPopupActive(false);

  // setTimeout(() => {
  //   removePopup();
  // }, 10000);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <section className="beans" onClick={removePopup}>
        <Popover
          anchorReference="none"
          disableEnforceFocus={true}
          className="clickable-popup"
          open={isPopupActive}
          marginThreshold={0}>
          <ImCross className="pop-up-cross" onClick={removePopup} />
          <img
            src={StoreDetails[4].img}
            style={{ transform: "translateX(-10%)" }}
            alt="store manager"
            className="pop-up-avatar"
          />
          <p className="pop-up-text">
            Hi I'm Rob and im here to help you choose the best blend for you!
            Feel free to ask me any questions:{" "}
            <a href="mailto:info@nakedcoffee.co">Email me</a>
          </p>
        </Popover>
        {products.beans.items.map((product, i) => {
          return (
            <Beans key={product.id} index={i} product={product} {...product} />
          );
        })}
      </section>
      {history.location.state === "myvip" && (
        <div className="go-back-container">
          <button onClick={() => history.push(`${baseName}/myvip`)}>
            &larr; Back to my account
          </button>
        </div>
      )}
    </>
  );
};

export default BeansContainer;
