import React, { useState } from "react";
import { useEffect } from "react";
import EnterDiscount from "../../components/discounts/EnterDiscount";
import MyDiscount from "../../components/discounts/MyDiscount";
import passwordHash from "password-hash";
import Footer from "../../components/Footer";

import log from "loglevel";

const DiscountPage = () => {
  const [enteredCode, SetEnteredCode] = useState("");

  useEffect(() => {
    log.debug(JSON.parse(window.sessionStorage.getItem("disc_p")));
    log.debug(
      passwordHash.verify(
        "1234567",
        JSON.parse(window.sessionStorage.getItem("disc_p"))
      )
    );
    if (
      window.sessionStorage.getItem("disc_p") &&
      passwordHash.verify(
        "1234567",
        JSON.parse(window.sessionStorage.getItem("disc_p"))
      )
    ) {
      SetEnteredCode("1234567");
    }
  }, []);

  if (enteredCode === "1234567") {
    return <MyDiscount />;
  }
  return (
    <>
      <EnterDiscount SetEnteredCode={SetEnteredCode} />
      <Footer />
    </>
  );
};

export default DiscountPage;
