import React from "react";
import { Route, Redirect } from "react-router-dom";

const baseName = process.env.REACT_APP_BASENAME;

const InstorePrivateRoute = ({ component: Component, ...rest }) => {
  if (
    window.sessionStorage.getItem("instore_table") &&
    window.sessionStorage.getItem("instore_store")
  ) {
    return (
      <Route {...rest} render={(props) => <Component {...props} />}></Route>
    );
  } else {
    return <Redirect to={`${baseName}/instore/selecttable`} />;
  }
};

export default InstorePrivateRoute;
